import { ComponentClass } from 'react';

const modals: { [key: string]: any } = {};

export function registerModal(instance: any) {
  if (instance === null) {
    // Modal was unmounted
    return;
  }
  modals[instance.constructor.name] = instance;
}

export function showModal(modalClass: ComponentClass, ...args: any) {
  return _getModal(modalClass).show(...args);
}

export function hideAllModals() {
  for (const key of Object.keys(modals)) {
    const modal = modals[key];
    modal.hide && modal.hide();
  }
}

function _getModal(modalClass: ComponentClass) {
  const m = modals[modalClass.name];
  if (!m) {
    throw new Error('Modal was not registered with the app');
  }

  return m;
}
