import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_JOBS_START,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  FETCH_JOB_TYPES_START,
  FETCH_JOB_TYPES_SUCCESS,
  FETCH_JOB_TYPES_ERROR,
  CREATE_JOB_START,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,
} from '../constants/job.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';
import { ReportTypes } from './reports.reducer';

export interface Job {
  job_id: number;
  user_id: number;
  // type: keyof typeof jobTypes;
  type: string;
  state: string;
  opts: Record<string, any>;
  data: any;
  stacktrace: Record<string, any>;
  delay: number;
  processed_on: number;
  failed_reason: any;
  created_at: Date;
  updated_at: Date;
}

interface JobType {
  type: string;
  schema: JobTypeSchema;
}

interface JobTypeSchema {
  [K: string]: JobTypeAttr;
}

type JobTypeAttr =
  | {}
  | { type: 'number' }
  | { type: 'string'; enum?: string[]; pattern?: string; format?: 'date-time' }
  | {
    type: 'object';
    properties: Record<string, JobTypeAttr>;
    additionalProperties: boolean;
    patterns: any[];
    required: string[];
  }
  | { anyOf: JobTypeAttr[] };


export const providers = ['directmailers', 'manual'] as const;
export type ProvidersType = typeof providers[number];

export interface JobTypeData {
  create_user_tokens: { userId: number; tokens: UserToken };
  experian_credit_report: { userId: number };
  experian_process_disputes: { userId: number; roundId: number };
  experian_generate_letters: { userId: number; round: number; isDryRun: boolean; provider: ProvidersType; };
  chargify_refresh_subscriptions: {};
  chargify_sync_user: { userId: number };
  experian_report: { types: ReportTypes[] };
  sync_experian_credit_reports: { userId: number };
  salesforce_sync_user: { userId: number };
}

/* UserTokens */
export const tokenTypes = [
  'dispute_round',
  'credit_report',
  'credit_camp_course',
] as const;
export type TokenType = typeof tokenTypes[number];

export const tokenSources = [
  'account_activation',
  'upgrade_to_premium',
  'monthly_payment',
  'admin_granted',
  'gift',
] as const;
export type TokenSource = typeof tokenSources[number];

interface UserToken {
  type: TokenType;
  source: TokenSource;
}

export interface JobsState extends CallAPIAsyncState<Job> {
  jobTypes: {
    byKey: Record<string, JobType>;
    allKeys: string[];
  };
  total: number;
}

const initialState: JobsState = {
  ...INITIAL_ASYNC_STATE,
  jobTypes: { byKey: {}, allKeys: [] },
  total: 0,
};

const jobsReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_JOBS_SUCCESS:
        draft.items = action.payload.jobs;
        draft.total = action.payload.meta.count;
        draft.isFetched = true;
        return;
      case FETCH_JOB_TYPES_SUCCESS:
        draft.jobTypes.byKey = action.payload.job_types;
        draft.jobTypes.allKeys = Object.keys(
          action.payload.job_types,
        ).sort((a, b) => a.localeCompare(b));
        return;
    }
  },
  { ...initialState },
);

export default withLoading<JobsState>({
  isLoadingActions: [FETCH_JOBS_START, FETCH_JOB_TYPES_START, CREATE_JOB_START],
  successActions: [
    FETCH_JOBS_SUCCESS,
    FETCH_JOB_TYPES_SUCCESS,
    CREATE_JOB_SUCCESS,
  ],
  errorActions: [FETCH_JOBS_ERROR, FETCH_JOB_TYPES_ERROR, CREATE_JOB_ERROR],
})(jobsReducer);
