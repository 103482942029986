import { default as userConstants } from '../constants/user.constants';
import { SearchQuery } from '../middleware/api';
import { UserUpdateOptions, User } from '../reducers/user.reducer';
import get from 'lodash/get';

export const fetchUser = (organization: string, userId: string) => {
  return {
    types: [
      userConstants.FETCH_USER_START,
      userConstants.FETCH_USER_SUCCESS,
      userConstants.FETCH_USER_ERROR,
    ],
    method: 'get',
    path: `admin/organizations/${organization}/users/${userId}`,
  };
};

export const fetchUsers = (organization: string, search?: SearchQuery) => {
  return {
    types: [
      userConstants.FETCH_USERS_START,
      userConstants.FETCH_USERS_SUCCESS,
      userConstants.FETCH_USERS_ERROR,
    ],
    method: 'get',
    path: `admin/organizations/${organization}/users`,
    search,
  };
};

export interface CreateUserResp {
  user: User;
  errors: string[];
}
export const createUser = (organization: string, values: any) => {
  return {
    types: [
      userConstants.CREATE_USER_START,
      userConstants.CREATE_USER_SUCCESS,
      userConstants.CREATE_USER_ERROR,
    ],
    method: 'post',
    path: `admin/organizations/${organization}/users`,
    payload: {
      ...Object.entries(values).reduce(
        (acc: { [key: string]: any }, [key, val]) => {
          if (val) {
            acc[key] = val;
          }
          return acc;
        },
        {},
      ),
      phone: get(values, 'phone').replace(/\D/g, ''),
      send_enrollment: true,
    },
  };
};

export const updateUser = (
  organization: string,
  userId: string,
  values: UserUpdateOptions,
) => {
  return {
    types: [
      userConstants.UPDATE_USER_START,
      userConstants.UPDATE_USER_SUCCESS,
      userConstants.UPDATE_USER_ERROR,
    ],
    method: 'put',
    path: `admin/organizations/${organization}/users/${userId}`,
    payload: {
      ...values,
      ...(values.phone
        ? { phone: (values.phone || '').replace(/\D/g, '') }
        : {}),
    },
  };
};

export const deleteUser = (organization: string, userId: string) => {
  return {
    types: [
      userConstants.DELETE_USER_START,
      userConstants.DELETE_USER_SUCCESS,
      userConstants.DELETE_USER_ERROR,
    ],
    method: 'delete',
    path: `admin/organizations/${organization}/users/${userId}`,
  };
};

export const loginAsUser = (organization: string, userId: string) => {
  return {
    types: [
      userConstants.LOGIN_AS_USER_START,
      userConstants.LOGIN_AS_USER_SUCCESS,
      userConstants.LOGIN_AS_USER_ERROR,
    ],
    method: 'post',
    path: `admin/organizations/${organization}/users/${userId}/sign_in_url`,
  };
};

export const setUserPassword = (
  userId: string,
  values: {
    password: string;
    password_confirmation: string;
  },
) => {
  return {
    types: [
      userConstants.SET_USER_PASSWORD_START,
      userConstants.SET_USER_PASSWORD_SUCCESS,
      userConstants.SET_USER_PASSWORD_ERROR,
    ],
    method: 'put',
    path: `admin/super/users/${userId}/password`,
    payload: values,
  };
};

export const checkEmail = (email: string) => ({
  types: [
    userConstants.CHECK_EMAIL_START,
    userConstants.CHECK_EMAIL_SUCCESS,
    userConstants.CHECK_EMAIL_ERROR,
  ],
  method: 'get',
  path: 'users/email',
  query: { email },
});

export const updateExperianProductType = (
  organization: string,
  userId: string,
  productType: number,
  pricingRoutine?: number,
) => ({
  types: [
    userConstants.UPDATE_EXPERIAN_PRODUCT_TYPE_START,
    userConstants.UPDATE_EXPERIAN_PRODUCT_TYPE_SUCCESS,
    userConstants.UPDATE_EXPERIAN_PRODUCT_TYPE_ERROR,
  ],
  method: 'put',
  path: `admin/organizations/${organization}/users/${userId}/experian/update_product`,
  payload: {
    product_type: productType,
    pricing_routine: pricingRoutine,
  },
});

export const deactivateExperianUser = (
  organization: string,
  userId: string,
) => ({
  types: [
    userConstants.DEACTIVATE_EXPERIAN_USER_START,
    userConstants.DEACTIVATE_EXPERIAN_USER_SUCCESS,
    userConstants.DEACTIVATE_EXPERIAN_USER_ERROR,
  ],
  method: 'delete',
  path: `admin/organizations/${organization}/users/${userId}/experian/deactivate`,
});

export const reactivateExperianUser = (
  organization: string,
  userId: string,
) => ({
  types: [
    userConstants.REACTIVATE_EXPERIAN_USER_START,
    userConstants.REACTIVATE_EXPERIAN_USER_SUCCESS,
    userConstants.REACTIVATE_EXPERIAN_USER_ERROR,
  ],
  method: 'post',
  path: `admin/organizations/${organization}/users/${userId}/experian/reactivate`,
});

export const reprocessDisputesAll = (preview?: boolean) => ({
  types: [
    userConstants.REPROCESS_DISPUTES_ALL_START,
    userConstants.REPROCESS_DISPUTES_ALL_SUCCESS,
    userConstants.REPROCESS_DISPUTES_ALL_ERROR,
  ],
  method: 'put',
  path: 'admin/super/disputed_items/reprocess_all',
  payload: { preview },
});

export default {
  createUser,
  updateUser,
  deleteUser,
  fetchUsers,
  loginAsUser,
  setUserPassword,
  checkEmail,
  updateExperianProductType,
  deactivateExperianUser,
  reactivateExperianUser,
  reprocessDisputesAll,
};
