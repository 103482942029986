export function validatePhone(value: string) {
  let error;
  if (!value) {
    return 'Please enter a phone number';
  }

  value = value.replace(/\D/g, '');

  if (!/^\d{10}$/.test(value)) {
    error = 'Please enter a valid phone number';
  }
  return error;
}

export default validatePhone;
