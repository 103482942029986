import * as yup from 'yup';
import {
  phoneRegExp,
  invalidEmail,
  validPassword,
  invalidLogin,
} from './common';

export const standardEmailValidation = yup
  .string()
  .email(invalidEmail)
  .required();

export const standardPhoneValidation = yup
  .string()
  .matches(phoneRegExp, 'Phone number is not valid');

export const AdminPermissions = ['users:set_password', ''];
export const adminUserSchema: any = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  email: standardEmailValidation,
  role: yup
    .string()
    .oneOf(['agent', 'manager', 'account-manager', 'admin', 'owner'])
    .required(),
  permissions: yup
    .string()
    .oneOf(AdminPermissions)
    .min(0)
    .nullable()
    .notRequired(),
});

export const validUserSchema = yup.object().shape({
  username: standardEmailValidation,
  password: validPassword,
});

export const loginSchema = yup.object().shape({
  username: yup
    .string()
    .min(3, invalidLogin)
    .max(255, invalidLogin)
    .email(invalidLogin)
    .required(),
  password: yup
    .string()
    .min(3, invalidLogin)
    .max(255, invalidLogin)
    .required(),
});

export const searchSchema = yup.object().shape({
  query: yup.string(),
});

export const minPasswordSchema = yup.object({
  password: validPassword,
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Passwords must match'),
});

export const changePasswordSchema: any = yup.object({
  password: validPassword,
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Passwords must match'),
  current_password: validPassword,
});

export const changeEmailSchema: any = yup.object({
  new_email: standardEmailValidation,
  email_confirmation: yup
    .string()
    .oneOf([yup.ref('new_email')], 'Emails must match')
    .required('Emails must match'),
});

export const changePhoneSchema: any = yup.object({
  new_phone: standardPhoneValidation,
  phone_confirmation: yup
    .string()
    .oneOf([yup.ref('new_phone')], 'Phone numbers must match')
    .required('Phone numbers must match'),
});

export const adminUpdateSchema: any = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required(),
  email: standardEmailValidation,
  role: yup
    .string()
    .oneOf(['agent', 'manager', 'account-manager', 'admin', 'owner'])
    .required(),
});
