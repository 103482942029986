import { useLocation } from 'react-router-dom';

// export function useParams<Params extends { [K in keyof Params]?: string } = {}>(): { [p in keyof Params]: string };
// taken from @types/react-router-dom@5.1.3

const useQueryParams = <Query extends { [K in keyof Query]?: string }>(): {
  [q in keyof Query]: string;
} => {
  const searchParams = new URLSearchParams(useLocation().search);

  const query = {} as any;

  searchParams.forEach((value, key) => (query[key] = value));

  return query;
};

export default useQueryParams;
