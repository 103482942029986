import { successReducer, errorReducer, isLoadingReducer } from '.';
import { Reducer, AnyAction } from 'redux';
import { createSelector } from 'reselect';
import type { RootState } from '../..';

interface ActionTypes {
  isLoadingActions: string[];
  successActions: string[];
  errorActions: string[];
}

// Initial state of the async flags - can be imported into the base reducer
export const INITIAL_ASYNC_STATE = {
  item: {} as any,
  items: [],
  isLoading: false,
  isFetched: false,
  errors: {},
  errorMessage: '',
  successMessage: '',
  meta: {},
};

// No operation reducer (default case) to take and return state
const noopReducer: Reducer = state => state;

const getReducerForType = (actionTypes: ActionTypes, action: AnyAction) => {
  if (actionTypes.isLoadingActions.includes(action.type)) {
    return isLoadingReducer;
  }
  if (actionTypes.successActions.includes(action.type)) {
    return successReducer;
  }
  if (actionTypes.errorActions.includes(action.type)) {
    return errorReducer;
  }
  return false;
};

// Returns a higher order reducer that takes a baseReducer
type WithLoading = <T = any>(
  actionTypes: ActionTypes,
) => <R extends Reducer>(baseReducer: R) => Reducer<T>;

export const withLoading: WithLoading = actionTypes => baseReducer =>
  // Returns a new reducer
  (state, action) => {
    // Is the action type a loadable action specified above?
    // if yes, set the action reducer, else set the noopReducer
    const reducerFunction =
      getReducerForType(actionTypes, action) || noopReducer;
    // compute new state with the specificed reducer set in reducerAction
    const newState = reducerFunction(state, action);
    // return the result of the newState and action passed into the baseReducer
    return baseReducer(newState, action);
  };

interface LoadingState {
  isLoading: boolean;
  errors: any;
  errorMessage: string;
}

export const getLoadingSelectors = <
  T extends (root: RootState) => LoadingState
>(
  getSlice: T,
) =>
  [
    createSelector(getSlice, state => state.isLoading),
    createSelector(getSlice, state => state.errors),
    createSelector(getSlice, state => state.errorMessage),
  ] as const;
