import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_MARKETING_RESOURCES_START,
  FETCH_MARKETING_RESOURCES_SUCCESS,
  FETCH_MARKETING_RESOURCES_ERROR,
  FETCH_SUPER_MARKETING_RESOURCES_START,
  FETCH_SUPER_MARKETING_RESOURCES_SUCCESS,
  FETCH_SUPER_MARKETING_RESOURCES_ERROR,
  CREATE_MARKETING_RESOURCE_START,
  CREATE_MARKETING_RESOURCE_SUCCESS,
  CREATE_MARKETING_RESOURCE_ERROR,
  DELETE_MARKETING_RESOURCE_START,
  DELETE_MARKETING_RESOURCE_SUCCESS,
  DELETE_MARKETING_RESOURCE_ERROR,
  UPDATE_MARKETING_RESOURCE_START,
  UPDATE_MARKETING_RESOURCE_SUCCESS,
  UPDATE_MARKETING_RESOURCE_ERROR,
  FETCH_DOWNLOAD_URL_START,
  FETCH_DOWNLOAD_URL_SUCCESS,
  FETCH_DOWNLOAD_URL_ERROR,
  REGENERATE_MARKETING_RESOURCE_THUMBNAIL_SUCCESS,
} from '../constants/marketing.constants';
import { CallAPIAsyncState, APIResponseMeta } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';
import { OrganizationType } from './organizationSlice';

export const marketingResourceCategories = [
  'email-template',
  'ad',
  'landing-page',
] as const;
export type MarketingResourceCategory = typeof marketingResourceCategories[number];

const categories: {
  [key in MarketingResourceCategory]: {
    plural: string;
    'label-singular': string;
    'label-plural': string;
  };
} = {
  'email-template': {
    plural: 'email-templates',
    'label-singular': 'Email Template',
    'label-plural': 'Email Templates',
  },
  ad: {
    plural: 'ads',
    'label-singular': 'Advertisement',
    'label-plural': 'Advertiesments',
  },
  'landing-page': {
    plural: 'landing-pages',
    'label-singular': 'Landing Page',
    'label-plural': 'Landing Pages',
  },
};

type LabelTypes = 'singular' | 'plural' | 'label-singular' | 'label-plural';
export const deriveCategoryAssociations = (
  key: LabelTypes,
  value: LabelTypes,
) => {
  if (key === value) {
    throw new Error('object keys and values are the same');
  }
  const response: { [index: string]: string } = {};
  if (key === 'singular') {
    for (const category in categories) {
      response[category] =
        categories[category as MarketingResourceCategory][
          value as Exclude<LabelTypes, 'singular'>
        ];
    }
  } else {
    if (value === 'singular') {
      for (const category in categories) {
        const derivedKey =
          categories[category as MarketingResourceCategory][
            key as Exclude<LabelTypes, 'singular'>
          ];
        response[derivedKey] = category;
      }
    } else {
      for (const category in categories) {
        const derivedKey =
          categories[category as MarketingResourceCategory][key];
        response[derivedKey] =
          categories[category as MarketingResourceCategory][value];
      }
    }
  }
  return response;
};

export interface MarketingResource {
  id: string;
  organization_id?: number | null;
  organization_type?: OrganizationType | null;
  category?: MarketingResourceCategory | null;
  s3_key: string;
  available?: boolean | null;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date | null;
  organizationId?: number | null;
  thumbnail_url?: string | null;
}

export interface SuperMarketingResource extends MarketingResource {
  s3_url: string;
}

export interface EditableMarketingResource
  extends Pick<
    SuperMarketingResource,
    | 'id'
    | 'organization_id'
    | 'organization_type'
    | 'available'
    | 'category'
    | 'thumbnail_url'
    | 's3_url'
  > {
  filename: string;
  scope?: number | OrganizationType | null;
}

export interface MarketingState extends CallAPIAsyncState<MarketingResource> {
  isFetched: boolean;
  downloadUrl: string;
  meta: Pick<APIResponseMeta, 'count'>;
}

const initialState: MarketingState = {
  ...INITIAL_ASYNC_STATE,
  isFetched: false,
  downloadUrl: '',
  meta: {},
};

const marketingReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_MARKETING_RESOURCES_SUCCESS:
        draft.items = action.payload.marketing_assets;
        draft.meta = action.payload.meta;
        draft.isFetched = true;
        return;
      case FETCH_SUPER_MARKETING_RESOURCES_SUCCESS:
        draft.items = action.payload.marketing_assets;
        draft.meta = action.payload.meta;
        draft.isFetched = true;
        return;
      case CREATE_MARKETING_RESOURCE_SUCCESS:
        draft.item = action.payload.marketing_asset;
        draft.items.unshift(action.payload.marketing_asset);
        return;
      case DELETE_MARKETING_RESOURCE_SUCCESS:
        draft.items.splice(
          draft.items.findIndex(element => element.id === action.id),
          1,
        );
        return;
      case REGENERATE_MARKETING_RESOURCE_THUMBNAIL_SUCCESS:
      case UPDATE_MARKETING_RESOURCE_SUCCESS:
        draft.items[
          draft.items.findIndex(
            item => item.id === action.payload.marketing_asset.id,
          )
        ] = action.payload.marketing_asset;
        return;
      case FETCH_DOWNLOAD_URL_SUCCESS:
        draft.downloadUrl = action.payload.download_url;
        return;
      case FETCH_DOWNLOAD_URL_ERROR:
        draft.downloadUrl = '';
        return;
    }
  },
  { ...initialState },
);

export default withLoading<MarketingState>({
  isLoadingActions: [
    FETCH_MARKETING_RESOURCES_START,
    FETCH_SUPER_MARKETING_RESOURCES_START,
    CREATE_MARKETING_RESOURCE_START,
    DELETE_MARKETING_RESOURCE_START,
    UPDATE_MARKETING_RESOURCE_START,
    FETCH_DOWNLOAD_URL_START,
  ],
  successActions: [
    FETCH_MARKETING_RESOURCES_SUCCESS,
    FETCH_SUPER_MARKETING_RESOURCES_SUCCESS,
    CREATE_MARKETING_RESOURCE_SUCCESS,
    DELETE_MARKETING_RESOURCE_SUCCESS,
    UPDATE_MARKETING_RESOURCE_SUCCESS,
    FETCH_DOWNLOAD_URL_SUCCESS,
  ],
  errorActions: [
    FETCH_MARKETING_RESOURCES_ERROR,
    FETCH_SUPER_MARKETING_RESOURCES_ERROR,
    CREATE_MARKETING_RESOURCE_ERROR,
    DELETE_MARKETING_RESOURCE_ERROR,
    UPDATE_MARKETING_RESOURCE_ERROR,
    FETCH_DOWNLOAD_URL_ERROR,
  ],
})(marketingReducer);
