import { createSelector } from 'reselect';
import { RootState } from '..';

export const getNotificationTemplatesByType = (state: RootState) => state.notificationTemplates.notificationTemplatesByType;
export const getAllNotificationCategories = (state: RootState) => state.notificationTemplates.allNotificationCategories;
export const getNotificationTypesByCategory = (state: RootState) => state.notificationTemplates.notificationTypesByCategory;
export const getNotificationTemplateById = (state: RootState) => state.notificationTemplates.notificationTemplateById;
export const getSendgridTemplates = (state: RootState) => state.notificationTemplates.sendgridTemplates;

export const selectNotificationTemplatesByType = createSelector(
  getNotificationTemplatesByType,
  notificationTemplates => notificationTemplates,
);

export const selectAllNotificationCategories = createSelector(
  getAllNotificationCategories,
  notificationCategories => notificationCategories,
);

export const selectNotificationTypesByCategory = createSelector(
  getNotificationTypesByCategory,
  notificationTypes => notificationTypes,
);

export const selectNotificationTemplatesById = createSelector(
  getNotificationTemplateById,
  notificationTemplate => notificationTemplate,
);

export const selectSendgridTemplates = createSelector(
  getSendgridTemplates,
  sendgridTemplates => sendgridTemplates,
);

export default {
  selectNotificationTemplatesByType,
  selectNotificationTypesByCategory,
  selectNotificationTemplatesById,
  selectAllNotificationCategories,
  selectSendgridTemplates,
};
