export const FETCH_API_KEYS_START = 'FETCH_API_KEYS_START';
export const FETCH_API_KEYS_SUCCESS = 'FETCH_API_KEYS_SUCCESS';
export const FETCH_API_KEYS_ERROR = 'FETCH_API_KEYS_ERROR';

export const FETCH_DELETED_API_KEYS_START = 'FETCH_DELETED_API_KEYS_START';
export const FETCH_DELETED_API_KEYS_SUCCESS = 'FETCH_DELETED_API_KEYS_SUCCESS';
export const FETCH_DELETED_API_KEYS_ERROR = 'FETCH_DELETED_API_KEYS_ERROR';

export const FETCH_API_KEY_START = 'FETCH_API_KEY_START';
export const FETCH_API_KEY_SUCCESS = 'FETCH_API_KEY_SUCCESS';
export const FETCH_API_KEY_ERROR = 'FETCH_API_KEY_ERROR';

export const UPDATE_API_KEY_START = 'UPDATE_API_KEY_START';
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS';
export const UPDATE_API_KEY_ERROR = 'UPDATE_API_KEY_ERROR';

export const CREATE_API_KEY_START = 'CREATE_API_KEY_START';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_ERROR = 'CREATE_API_KEY_ERROR';

export const DELETE_API_KEY_START = 'DELETE_API_KEY_START';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_ERROR = 'DELETE_API_KEY_ERROR';

export default {
  FETCH_API_KEY_START,
  FETCH_API_KEY_SUCCESS,
  FETCH_API_KEY_ERROR,
  FETCH_API_KEYS_START,
  FETCH_API_KEYS_SUCCESS,
  FETCH_API_KEYS_ERROR,
  FETCH_DELETED_API_KEYS_START,
  FETCH_DELETED_API_KEYS_SUCCESS,
  FETCH_DELETED_API_KEYS_ERROR,
  UPDATE_API_KEY_START,
  UPDATE_API_KEY_SUCCESS,
  UPDATE_API_KEY_ERROR,
  CREATE_API_KEY_START,
  CREATE_API_KEY_SUCCESS,
  CREATE_API_KEY_ERROR,
  DELETE_API_KEY_START,
  DELETE_API_KEY_SUCCESS,
  DELETE_API_KEY_ERROR,
};
