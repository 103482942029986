import {
  apiRequest,
  isPendingAction,
  isFulfilledAction,
  isRejectedAction,
} from '../utils';
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { omitBy, pick, isNil } from 'lodash';
import { organizationUpdateSchema } from '../../schemas/organization.schema';
import { AxiosError } from 'axios';
import { selectIsSuper } from '../selectors';
import { RootState } from '..';

export interface Organization {
  id: number;
  subdomain: string;
  name: string;
  sfid: string;
  sf_synced_at?: Date;
  status: string;
  default_user_target_score: number;
  default_monthly_product_handle: string;
  default_prepay_6_months_product_handle: string;
  default_prepay_12_months_product_handle: string;
  default_promo_code: string;
  allowed_product_handles: string[];
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  role: AdminRole;
  type: OrganizationType;
}

export const createOrganizationFields = [
  'name',
  'subdomain',
  'sfid',
  'default_user_target_score',
  'default_monthly_product_handle',
  'default_prepay_6_months_product_handle',
  'default_prepay_12_months_product_handle',
  'default_promo_code',
  'allowed_product_handles',
] as const;
export type CreateOrganizationFields = typeof createOrganizationFields[number];

export const organizationTypes = ['automotive', 'mortgage'] as const;
export type OrganizationType = typeof organizationTypes[number];
export const labelByOrganizationType: { [key in OrganizationType]: string } = {
  automotive: 'Automotive',
  mortgage: 'Mortgage',
};

export type AdminRole =
  | 'agent'
  | 'manager'
  | 'account-manager'
  | 'admin'
  | 'owner';

const SLICE_NAME = 'organizations';

// global organization for super admins 
export const globalOrganization = {
  id: 0,
  name: "Global Organization",
  subdomain: "global",
};

export const createOrganization = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (values: Partial<Organization>) => {
    const {
      data: { organization },
    } = await apiRequest.post<{ organization: Organization }>(
      `admin/super/organizations`,
      { payload: values },
    );
    return organization;
  },
);
export const updateOrganization = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async (
    {
      subdomain,
      values,
    }: {
      subdomain: string;
      values: Partial<Organization>;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const isSuper = selectIsSuper(state);
    const {
      data: { organization },
    } = await apiRequest.put<{ organization: Organization }>(
      `admin/organizations/${subdomain}`,
      {
        payload: {
          ...omitBy(
            pick(values, Object.keys(organizationUpdateSchema.fields)),
            isNil,
          ),
          ...pick(values, [
            'default_monthly_product_handle',
            'default_promo_code',
          ]),
          sfid: isSuper ? values.sfid : undefined,
        },
      },
    );
    return organization;
  },
);

export const fetchOrganizations = createAsyncThunk(
  `${SLICE_NAME}/fetchAll`,
  async (arg?: { isSuper?: boolean }) => {
    const {
      data: { organizations },
    } = await apiRequest.get<{ organizations: Partial<Organization>[] }>(
      `admin/${arg?.isSuper ? 'super/' : ''}organizations`,
      {
        query: {
          order: ['created_at'],
        },
      },
    );

    if (arg?.isSuper) {
      await organizations.unshift(globalOrganization);
    }

    return organizations;
  },
);
export const updateAllowedProducts = createAsyncThunk(
  `${SLICE_NAME}/updateAllowedProducts`,
  async ({
    subdomain,
    products,
  }: {
    subdomain: string;
    products: string[];
  }) => {
    const {
      data: { organization },
    } = await apiRequest.put<{ organization: Organization }>(
      `admin/organizations/${subdomain}`,
      {
        payload: { allowed_product_handles: products },
      },
    );

    return organization;
  },
);

interface GenericAPIError {
  statusCode: number;
  message: string;
  error: string;
}

export const createSalesforceAffiliate = createAsyncThunk<
  Organization,
  string,
  { rejectValue: GenericAPIError }
>(`${SLICE_NAME}/createAffiliate`, async (subdomain, { rejectWithValue }) => {
  try {
    const {
      data: { organization },
    } = await apiRequest.post(
      `admin/organizations/${subdomain}/salesforce/link`,
      {
        payload: {
          create: true,
        },
      },
    );
    return organization;
  } catch (err) {
    const error: AxiosError = err;
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const syncSalesforceAffiliate = createAsyncThunk<
  void,
  string,
  { rejectValue: GenericAPIError }
>(`${SLICE_NAME}/syncAffiliate`, async (subdomain, { rejectWithValue }) => {
  try {
    await apiRequest.put(
      `admin/organizations/${subdomain}/salesforce/link`,
      {},
    );
  } catch (err) {
    const error: AxiosError = err;
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

const adapter = createEntityAdapter<Organization>({
  selectId: organization => organization.subdomain,
});
const slice = createSlice({
  name: 'organizations',
  initialState: adapter.getInitialState({ active: '', loading: false }),
  reducers: {
    setActiveOrganization: (state, { payload }: PayloadAction<string>) => {
      state.active = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateOrganization.fulfilled, (state, action) => {
        adapter.updateOne(state, {
          id: action.payload.subdomain,
          changes: action.payload,
        });
      })
      .addCase(updateAllowedProducts.fulfilled, (state, action) => {
        adapter.updateOne(state, {
          id: action.payload.subdomain,
          changes: action.payload,
        });
      })
      .addCase(fetchOrganizations.fulfilled, adapter.setAll)
      .addCase(createOrganization.fulfilled, adapter.addOne)
      .addCase(createSalesforceAffiliate.fulfilled, adapter.upsertOne)
      .addMatcher(isPendingAction(SLICE_NAME), state => {
        state.loading = true;
      })
      .addMatcher(isFulfilledAction(SLICE_NAME), state => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(SLICE_NAME), state => {
        state.loading = false;
      });
  },
});

export const { setActiveOrganization } = slice.actions;
export default slice.reducer;

export const { selectAll: selectAllOrganizations } = adapter.getSelectors(
  (state: RootState) => state.organizations,
);
export const selectOrganizationsLoading = (state: RootState) =>
  state.organizations.loading;
const getCurrentOrganization = (state: RootState) =>
  state.organizations.entities[state.organizations.active] ||
  ({} as Organization);
export const selectCurrentAdminRoleForOrg = createSelector(
  getCurrentOrganization,
  organization => organization?.role,
);
export const selectIsAdmin = createSelector(
  getCurrentOrganization,
  organization => organization?.role === 'admin',
);
export const selectIsAccountManager = createSelector(
  getCurrentOrganization,
  organization => organization?.role === 'account-manager',
);
export const selectIsManager = createSelector(
  getCurrentOrganization,
  organization => organization?.role === 'manager',
);
export const selectIsAgent = createSelector(
  getCurrentOrganization,
  organization => organization?.role === 'agent',
);
export const selectIsOwner = createSelector(
  getCurrentOrganization,
  organization => organization?.role === 'owner',
);

export const selectCurrentOrganization = createSelector(
  getCurrentOrganization,
  current => current,
);
