import { get, isEmpty, isUndefined } from 'lodash';
import { apiRequest, ApiRequestMethod } from '../utils/apiRequest';
import { Dispatch, Action, Middleware, AnyAction, MiddlewareAPI } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '..';
import { Admin } from '../reducers/admin.reducer';
import { User } from '../reducers/user.reducer';
import { Organization } from '../reducers/organizationSlice';

export const defaultError = 'There was an error, please try again';

type CallAPI<T> = T extends (...args: infer U) => infer R
  ? R extends CallAPIAction<infer RT>
    ? (...args: U) => Promise<CallAPIResponse<RT>>
    : (...args: U) => Promise<CallAPIResponse>
  : never;

type CallAPIProps<T> = {
  [P in keyof T]: CallAPI<T[P]>;
};

export const convertDispatchProps = function<T>(props: T) {
  return (props as any) as CallAPIProps<T>;
};
export interface CallAPIAsyncState<T = any> {
  readonly successMessage: string;
  readonly errorMessage: string;
  readonly isLoading: boolean;
  readonly isFetched: boolean;
  readonly errors: Record<string, any>;
  readonly items: T[];
  readonly item: T;
}
export interface CallAPIAction<T = {}> extends Omit<Action, 'type'> {
  types: [string, string, string];
  method: 'get' | 'post' | 'put' | 'delete';
  path: string;
  successMessage?: string;
  reducerKey?: string;
  search?: SearchQuery;
  query?: any;
  payload?: T;
  auth?: any;
  shouldCallAPI?: (state: RootState) => boolean;
}

export interface CallAPIResponse<T = any> {
  type: string;
  errorMessage?: string;
  successMessage?: string;
  reducerKey?: string;
  payload: T & GenericPayload;
}

interface GenericPayload {
  meta: APIResponseMeta;
  admins?: Admin[];
  admin?: Admin;
  users?: User[];
  user?: User;
  organizations?: Organization[];
  organization?: Organization;
  links?: LinkTypes;
  token?: string;
}
interface LinkTypes {
  sign_in?: string;
  agreement?: string;
  report?: string;
  credit_report?: string;
}
export type SigninUrlResponse = Pick<GenericPayload, 'links'>;
export interface APIResponseMeta {
  count?: number;
  needs_password: boolean;
  role: string;
}

export interface SearchQuery extends Pagination {
  q?: any;
  i?: any;
}

export interface Pagination {
  page?: number;
  page_size?: number;
  order?: string[];
}

const callAPIMiddleware: Middleware<Dispatch> = ({
  dispatch,
  getState,
}: MiddlewareAPI) => (next: any) => (action: AnyAction | CallAPIAction) => {
  const {
    types,
    path,
    search,
    query,
    auth,
    shouldCallAPI = () => true,
    payload = {},
    successMessage,
    reducerKey,
  } = action;

  const method = action.method as ApiRequestMethod;

  if (!types) {
    // Normal action: pass it on
    return next(action);
  }

  if (!path) {
    throw new Error('You must pass in a path');
  }

  if (!method) {
    throw new Error('You must pass in a method');
  }

  if (
    !Array.isArray(types) ||
    types.length !== 3 ||
    !types.every(type => typeof type === 'string')
  ) {
    throw new Error('Expected an array of three string types.');
  }

  if (!shouldCallAPI(getState())) {
    // eslint-disable-next-line consistent-return
    return;
  }

  const [requestType, successType, failureType] = types;

  dispatch({
    ...payload,
    ...{
      type: requestType,
      reducerKey,
    },
  });

  const request = {
    auth: isUndefined(auth) ? true : auth,
    query,
    search,
    payload,
  };

  return (apiRequest as any)
    [method](path, request)
    .then(({ data }: { data: AxiosResponse['data'] }) =>
      Promise.resolve(
        dispatch({
          ...payload,
          ...{
            payload: data,
            type: successType,
            successMessage,
            reducerKey,
          },
        }),
      ),
    )
    .catch((error: AxiosError) => {
      console.log('Error in api middleware', error);
      // this can get cleaned up once we standardize our API responses
      const errors = get(error, 'response.data.errors');
      const errorMsg =
        get(error, 'response.data.message') ||
        get(error, 'response.data.error') ||
        defaultError;
      return Promise.reject(
        dispatch({
          ...payload,
          payload: get(error, 'response.data'),
          ...{
            error: !isEmpty(errors) ? errors.join(', ') : errorMsg,
            errorMessage: !isEmpty(errors) ? errors.join(', ') : errorMsg,
            type: failureType,
            reducerKey,
          },
        }),
      );
    });
};

export default callAPIMiddleware;
