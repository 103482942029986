/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  useLocation,
} from 'react-router-dom';
import { push } from 'connected-react-router';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Menu, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import logo from '../assets/logo.png';
import RightMenu from './HeaderNav/RightMenu';

import { selectConfigContextVariables } from '../store/selectors';
import {
  selectIsSuper,
  selectIsLoggedInAsAdmin,
} from '../store/selectors/auth.selectors';
import {
  Organization,
  AdminRole,
  selectAllOrganizations,
  selectIsOwner,
  selectIsAdmin,
  selectIsAccountManager,
  selectIsManager,
  selectIsAgent,
  selectCurrentOrganization,
  selectCurrentAdminRoleForOrg,
  fetchOrganizations,
  setActiveOrganization,
} from '../store/reducers/organizationSlice';
import NunjucksModal from '../utils/codemirror/modals/NunjucksVariableEditor';
import { registerModal } from '../utils/codemirror/modals';
import TemplateEditor from '../modules/templates/TemplateEditor';
import NotificationTemplateEditor from '../modules/notification_templates/NotificationTemplateEditor';
import { fetchProducts } from '../modules/products/productsSlice';
import ScoreshuttleLogo from '../icons/Logo';

const SuperDashboard = lazy(() =>
  import('../modules/dashboard/SuperDashboard'),
);
const AgentDashboard = lazy(() =>
  import('../modules/dashboard/AgentDashboard'),
);
const UsersPage = lazy(() => import('../modules/users/Users'));
const AdminsPage = lazy(() => import('../modules/admins/Admins'));
const OrganizationManager = lazy(() =>
  import('../modules/organizations/OrganizationManager'),
);
const OrganizationsPage = lazy(() =>
  import('../modules/organizations/Organization'),
);
const Settings = lazy(() => import('../modules/settings/Settings'));
const OrganizationSettings = lazy(() =>
  import('../modules/organizations/OrganizationSettings'),
);
const UserProfile = lazy(() => import('../modules/users/UserProfile'));
const AdminProfile = lazy(() => import('../modules/admins/AdminProfile'));
const JobsPage = lazy(() => import('../modules/jobs/Jobs'));
const FeatureFlagsPage = lazy(() =>
  import('../modules/feature-flags/FeatureFlags'),
);

const ProcessesPage = lazy(() => import('../modules/processes/Processes'));
const DevOpsPage = lazy(() => import('../modules/devops/DevOps'));
const GoalKeeper = lazy(() => import('../modules/goalkeeper/GoalKeeper'));

const TemplatesPage = lazy(() => import('../modules/templates/Templates'));
const MarketingPage = lazy(() => import('../modules/marketing/Marketing'));

const NotificationTemplatesPage = lazy(() =>
  import('../modules/notification_templates/NotificationTemplates'),
);

const SuperMarketingResources = lazy(() =>
  import('../modules/marketing/SuperMarketingResources'),
);
const AdminMarketingResources = lazy(() =>
  import('../modules/marketing/AdminMarketingResources'),
);

const { Header, Sider, Content, Footer } = Layout;
const SubMenu = Menu.SubMenu;
const headerStyles = css`
  .header-right {
    float: right;
    height: 100%;
    overflow: hidden;
    .action {
      cursor: pointer;
      padding: 0 12px;
      display: inline-block;
      transition: all 0.3s;
      height: 100%;
      > i {
        vertical-align: middle;
        color: @text-color;
      }
      &:hover {
        background: @pro-header-hover-bg;
      }
      &:global(.opened) {
        background: @pro-header-hover-bg;
      }
    }
    .search {
      padding: 0 12px;
      &:hover {
        background: transparent;
      }
    }
    .account {
      .avatar {
        margin: ~'calc((@{layout-header-height} - 24px) / 2)' 0;
        margin-right: 8px;
        color: @primary-color;
        background: rgba(255, 255, 255, 0.85);
        vertical-align: middle;
      }
    }
  }
`;

type AdminRoleWithSuper = AdminRole | 'super';
const roleToDashboard: {
  [key in AdminRoleWithSuper | 'default']: any;
} = {
  super: SuperDashboard,
  owner: AgentDashboard,
  admin: AgentDashboard,
  'account-manager': AgentDashboard,
  manager: AgentDashboard,
  agent: AgentDashboard,
  default: AgentDashboard,
};

const AppContainer: React.FC = () => {
  const organizations = useSelector(selectAllOrganizations);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const currentAdminRole = useSelector(selectCurrentAdminRoleForOrg);
  const isSuper = useSelector(selectIsSuper);
  const isOwner = useSelector(selectIsOwner);
  const isAdmin = useSelector(selectIsAdmin);
  const isAccountManager = useSelector(selectIsAccountManager);
  const isManager = useSelector(selectIsManager);
  const isAgent = useSelector(selectIsAgent);
  const isLoggedInAsAdmin = useSelector(selectIsLoggedInAsAdmin);

  const configContextVariables = useSelector(selectConfigContextVariables);

  const dispatch = useDispatch();

  const [activeNavKeys, setActiveNavKeys] = useState<{
    selectedKey: string;
    openSubmenuKeys: string[];
  }>({ selectedKey: '', openSubmenuKeys: [] });

  const location = useLocation();

  useEffect(() => {
    dispatch(fetchOrganizations({ isSuper }));
  }, [dispatch, isSuper]);

  useEffect(() => {
    // if there isn't an active organization set, try to find and set it from the url
    if (Object.keys(currentOrganization).length === 0) {
      const possibleOrg = location.pathname.split('/')[1];
      const found = organizations.filter(org => org.subdomain === possibleOrg);

      if (found.length) {
        dispatch(setActiveOrganization(possibleOrg));
        return;
      } else if (!possibleOrg || possibleOrg === 'undefined') {
        dispatch(push('/organizations'));
      }
    }
  }, [organizations, currentOrganization, location, dispatch]);

  useEffect(() => {
    let key = location.pathname
      .substring(1)
      .replace(`${currentOrganization?.subdomain}/`, '');

    const submenuKey = key.split('/').shift() || '';

    // keep MySettings menu item highlighted when the menu within the settings component changes the url.
    if (key.indexOf('settings/') !== -1) {
      key = 'settings/base';
    }

    if (key !== activeNavKeys.selectedKey) {
      setActiveNavKeys(prevNav => ({
        selectedKey: key,
        openSubmenuKeys: Array.from(
          new Set([...prevNav.openSubmenuKeys, submenuKey]),
        ),
      }));
    }
  }, [location, currentOrganization, activeNavKeys]);

  useEffect(() => {
    // initialize data
    if (currentOrganization?.subdomain) {
      dispatch(fetchProducts(currentOrganization.subdomain));
    }
  }, [currentOrganization?.subdomain]);

  type AdminRoleWithSuper = AdminRole | 'super';

  interface Menu {
    title: string;
    icon: string;
    link?: string;
    roles?: AdminRoleWithSuper[];
    submenu?: boolean;
    children?: Menu[];
  }

  const buildMenuItems = ({
    currentOrganization,
    isSuper = false,
    isOwner = false,
    isAdmin = false,
    isAccountManager = false,
    isManager = false,
    isAgent = true,
  }: {
    currentOrganization: Organization;
    isSuper?: boolean;
    isOwner?: boolean;
    isAdmin?: boolean;
    isAccountManager?: boolean;
    isManager?: boolean;
    isAgent?: boolean;
  }) => {
    const menu: Menu[] = [
      // {
      //   title: 'Dashboard',
      //   link: `/${currentOrganization.subdomain}/dashboard`,
      //   icon: 'dashboard',
      // },
      // {
      //   title: 'Reports',
      //   link: `/${currentOrganization.subdomain}/reports`,
      //   icon: 'stock',
      //   roles: ['super'],
      // },
      {
        title: 'Subscribers',
        link: `/${currentOrganization.subdomain}/subscribers`,
        icon: 'team',
      },
      ...(currentOrganization?.id
        ? ([
          {
            title: 'Manage Admins',
            icon: 'team',
            link: `/${currentOrganization.subdomain}/admins`,
            roles: ['owner', 'super'],
          },
        ] as Menu[])
        : []),
      ...(isAgent
        ? ([
          {
            title: 'My Settings',
            icon: 'setting',
            link: `/settings/base`,
          },
        ] as Menu[])
        : ([
          {
            title: 'Settings',
            icon: 'setting',
            submenu: true,
            children: [
              {
                title: 'My Settings',
                icon: 'user',
                link: `/settings/base`,
              },
              {
                title: 'Organization',
                icon: 'shop',
                link: `/${currentOrganization.subdomain}/settings`,
                roles: ['owner'],
              },
            ],
          },
        ] as Menu[])),
      isSuper
        ? {
          title: 'Marketing',
          icon: 'mail',
          submenu: true,
          children: [
            {
              title: 'Manage / Schedule',
              icon: 'schedule',
              link: `/marketing/campaigns`,
              roles: ['super'],
            },
            {
              title: 'Metrics',
              icon: 'dot-chart',
              link: `/marketing/campaigns/metrics`,
              roles: ['super'],
            },
            {
              title: 'Manage Global Resources',
              icon: 'copy',
              link: `/marketing/resources`,
              roles: ['super'],
            },
          ],
        }
        : {
          title: 'Marketing Resources',
          icon: 'copy',
          link: `/${currentOrganization.subdomain}/marketing/resources`,
          roles: ['super'],
        },
      {
        title: 'System Jobs',
        link: `/jobs`,
        icon: 'schedule',
        roles: ['super'],
      },
      {
        title: 'Feature Flags',
        link: `/feature-flags`,
        icon: 'flag',
        roles: ['super'],
      },
      {
        title: 'Dev Ops',
        link: `/devops`,
        icon: 'schedule',
        roles: ['super'],
      },
      {
        title: 'New Account Approvals',
        link: `/goalkeeper`,
        icon: 'user',
        roles: ['super'],
      },
      {
        title: 'Processes',
        link: `/${currentOrganization.subdomain}/processes`,
        icon: 'cluster',
      },
      {
        title: 'Letter Templates',
        link: `/templates`,
        icon: 'copy',
        roles: ['super'],
      },
      {
        title: 'Notification Templates',
        link: `/notificationtemplates`,
        icon: 'form',
        roles: ['super'],
      },
    ];
    const mapRoleToValue: { [key in AdminRoleWithSuper]: boolean } = {
      super: isSuper,
      owner: isOwner,
      admin: isAdmin,
      'account-manager': isAccountManager,
      manager: isManager,
      agent: isAgent,
    };

    const hasPermission = (roles: AdminRoleWithSuper[]) =>
      roles.some(role => mapRoleToValue[role] || isSuper);

    const renderMenuItem = (item: Menu) => {
      return (
        <Menu.Item key={item.title}>
          {item.icon && <LegacyIcon type={item.icon} />}
          <span className="nav-text">{item.title}</span>
          <NavLink to={item.link!} />
        </Menu.Item>
      );
    };

    const renderSubMenu = (menuItem: Menu) => (
      <SubMenu
        key={menuItem.title.toLowerCase()}
        title={
          <span>
            {menuItem.icon && <LegacyIcon type={menuItem.icon} />}
            <span>{menuItem.title}</span>
          </span>
        }
      >
        {menuItem.children?.map(subItem =>
          !subItem.roles
            ? renderMenuItem(subItem)
            : hasPermission(subItem.roles)
              ? renderMenuItem(subItem)
              : undefined,
        )}
      </SubMenu>
    );

    const navResult = [];
    for (const menuItem of menu) {
      if (menuItem.roles && menuItem.roles.length > 0) {
        if (hasPermission(menuItem.roles)) {
          if (menuItem.submenu) {
            navResult.push(renderSubMenu(menuItem));
          } else {
            navResult.push(renderMenuItem(menuItem));
          }
        }
      } else {
        if (menuItem.submenu) {
          navResult.push(renderSubMenu(menuItem));
        } else {
          navResult.push(renderMenuItem(menuItem));
        }
      }
    }
    return navResult;
  };

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        theme="light"
        width="300"
      // Authorized={Authorized}
      >
        <div className="logo" style={{ padding: '20px' }}>
          <ScoreshuttleLogo />
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['dashboard']}
          selectedKeys={[activeNavKeys.selectedKey]}
          openKeys={activeNavKeys.openSubmenuKeys}
          onOpenChange={openKeys => {
            setActiveNavKeys(prevNav => ({
              ...prevNav,
              openSubmenuKeys: Array.from(new Set(openKeys)) as any,
            }));
          }}
        >
          {buildMenuItems({
            isSuper,
            isOwner,
            isAdmin,
            isAccountManager,
            isManager,
            isAgent,
            currentOrganization,
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{ background: '#fff', padding: 0, height: '68px' }}
          css={headerStyles}
        >
          <div className="header-right">
            <RightMenu isLoggedInAsAdmin={isLoggedInAsAdmin} />
          </div>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, minHeight: 360 }}>
            <Switch>
              <Suspense fallback={<Spin size="large" />}>
                <Route
                  exact
                  path="/"
                  render={() => {
                    let destination = '/organizations';
                    if (currentOrganization.subdomain) {
                      destination = `/${currentOrganization.subdomain}/dashboard`;
                    }
                    return <Redirect to={destination} />;
                  }}
                />
                <Route
                  key="users-detail"
                  path="/:organizationId/subscribers/:userId"
                  component={UserProfile}
                />
                <Route
                  key="subscribers"
                  path="/:organizationId/subscribers"
                  exact
                  component={UsersPage}
                />
                <Route
                  key="admins-detail"
                  path="/:organizationId/admins/:adminId"
                  component={AdminProfile}
                />
                <Route
                  key="admins"
                  path="/:organizationId/admins"
                  exact
                  component={AdminsPage}
                />
                <Route
                  key="organizations"
                  path="/organizations"
                  component={isSuper ? OrganizationManager : OrganizationsPage}
                />
                <Route
                  key="organization-settings"
                  path="/:organizationId/settings"
                  component={OrganizationSettings}
                />
                <Route
                  key="marketing-resources"
                  path="/:organizationId/marketing/resources/:category?"
                  component={
                    ((isAdmin || isOwner || isSuper) &&
                      AdminMarketingResources) ||
                    OrganizationsPage
                  }
                />
                <Route
                  key="super-marketing-resources"
                  path="/marketing/resources/:category?"
                  component={SuperMarketingResources}
                />
                <Route
                  exact
                  key="dashboard"
                  path="/:organizationId/dashboard"
                  component={
                    (isSuper && roleToDashboard.super) ||
                    roleToDashboard[currentAdminRole] ||
                    roleToDashboard.default
                  }
                />
                <Route
                  key="user-settings"
                  path="/settings"
                  component={Settings}
                />
                <Route key="jobs" path="/jobs" component={JobsPage} />
                <Route
                  key="feature-flags"
                  path="/feature-flags"
                  component={FeatureFlagsPage}
                />

                <Route key="devops" path="/devops" component={DevOpsPage} />
                <Route
                  key="goalkeeper"
                  path="/goalkeeper"
                  component={GoalKeeper}
                />
                <Route
                  key="processes"
                  path="/:organizationId/processes"
                  component={ProcessesPage}
                />
                <Route
                  key="marketing"
                  path="/marketing/campaigns"
                  component={MarketingPage}
                />
                <Route
                  exact
                  key="templates"
                  path="/templates"
                  component={TemplatesPage}
                />
                <Route
                  exact
                  key="templates-edit"
                  path="/templates/:templateId"
                  component={TemplateEditor}
                />
                <Route
                  exact
                  key="notificationtemplates"
                  path="/notificationtemplates"
                  component={NotificationTemplatesPage}
                />
                <Route
                  exact
                  key="notificationtemplates-update"
                  path="/notificationtemplates/update/:templateId"
                  component={NotificationTemplateEditor}
                />
                <Route
                  exact
                  key="notificationtemplates-create"
                  path="/notificationtemplates/create/:deliveryMethod/:notificationTypeId"
                  component={NotificationTemplateEditor}
                />
                {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
              </Suspense>
            </Switch>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          &copy; {new Date().getFullYear()} ScoreShuttle
        </Footer>
      </Layout>

      <NunjucksModal
        ref={registerModal}
        contextVariables={configContextVariables}
      />
    </Layout>
  );
};

export default AppContainer;
