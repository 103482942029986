import { createSelector } from 'reselect';
import get from 'lodash/get';
import { RootState } from '..';
import { AuthState } from '../reducers/auth.reducer';
// import isEmpty from 'lodash/isEmpty';

export const getCredentials = (state: RootState) => state.credentials;
export const getCurrentAdmin = (state: RootState) => state.credentials.admin;
export const getIsAuthenticated = (state: RootState) =>
  state.credentials.isAuthenticated;
export const getAuthFetched = (state: RootState) =>
  get(state, 'credentials.admin.id') || !state.credentials.isLoading;
export const getAuthLoading = (state: RootState) => state.credentials.isLoading;
export const getAuthSuccessMessage = (state: RootState) =>
  state.credentials.successMessage;
export const getAuthErrorMessage = (state: RootState) =>
  state.credentials.errorMessage;

export const selectIsSuper = createSelector(
  getCredentials,
  (credentials: AuthState): boolean => get(credentials, 'admin.super', false),
);

export const selectIsLoggedInAsAdmin = createSelector(
  getCredentials,
  (credentials: AuthState) => credentials.isLoggedInAsAdmin,
);
export const selectCredentials = createSelector(
  getCredentials,
  credentials => credentials,
);
export const selectCurrentAdmin = createSelector(
  getCurrentAdmin,
  admin => admin,
);
export const selectAuthFetched = createSelector(
  getAuthFetched,
  fetched => fetched,
);
export const selectIsAuthenticated = createSelector(
  getIsAuthenticated,
  auth => auth,
);

export const selectAuthLoading = createSelector(getAuthLoading, auth => auth);
export const selectAuthErrorMessage = createSelector(
  getAuthErrorMessage,
  auth => auth,
);
export const selectAuthSuccessMessage = createSelector(
  getAuthSuccessMessage,
  auth => auth,
);

export default {
  selectCredentials,
  selectCurrentAdmin,
  selectAuthFetched,
  selectAuthLoading,
  selectAuthErrorMessage,
  selectAuthSuccessMessage,
  selectIsLoggedInAsAdmin,

  // role helpers
  selectIsSuper,
};
