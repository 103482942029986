/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { Fragment, Suspense } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import { Layout } from 'antd';

import logo from '../assets/logo.svg';
import { authRoutes } from '../modules/auth/auth.routes';
import ScoreshuttleLogo from '../icons/Logo';

const { Footer } = Layout;

const pageStyle = css`
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background: #f0f2f5;
    background-image: linear-gradient(
      85deg,
      #3b3bff 0%,
      #8041ab 75%
    ) !important;
  }

  .ant-layout-footer {
    background: transparent !important;
    color: #fff;
  }

  .content {
    padding: 32px 0;
    flex: 1;
  }

  @media (min-width: 768px) {
    .content {
      padding: 100px 0 24px 0;
    }
  }

  .top {
    text-align: center;
  }

  .header {
    height: 44px;
    line-height: 44px;
    a {
      text-decoration: none;
    }
  }

  .logo {
    height: 44px;
    vertical-align: top;
    margin-right: 16px;
    color: #fff;
  }

  .title {
    font-size: 33px;
    color: fade(#000, 85%);
    font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 600;
    position: relative;
    top: 2px;
  }

  .desc {
    font-size: 14px;
    color: fade(@black, 45%);
    margin-top: 12px;
    margin-bottom: 40px;
  }
`;

const copyright = (
  <Fragment>Copyright {new Date().getFullYear()} ScoreShuttle</Fragment>
);

class Auth extends React.PureComponent<RouteComponentProps<{}>> {
  state = {
    collapsed: false,
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  render() {
    return (
      <div css={pageStyle}>
        <div className="container">
          <div className="content">
            <div className="top">
              <div className="header">
                <ScoreshuttleLogo fill="#fff" />
              </div>
              <div className="desc" />
              <div style={{ padding: 24, minHeight: 360 }}>
                <Switch>
                  <Suspense fallback={<div />}>{authRoutes}</Suspense>
                </Switch>
              </div>
            </div>
          </div>

          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            {copyright}
          </Footer>
        </div>
      </div>
    );
  }
}

export default Auth;
