/* eslint-disable default-case */
import produce from 'immer';
import {
  FETCH_API_KEYS_START,
  FETCH_API_KEYS_SUCCESS,
  FETCH_API_KEYS_ERROR,
  FETCH_API_KEY_START,
  FETCH_API_KEY_SUCCESS,
  FETCH_API_KEY_ERROR,
  UPDATE_API_KEY_SUCCESS,
  DELETE_API_KEY_SUCCESS,
  CREATE_API_KEY_SUCCESS,
} from '../constants/apikeys.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';
import omitBy from 'lodash/omitBy';

export interface ApiKey {
  id: number;
  admin_id: number;
  name: string;
  description?: string;
  secret?: string;
  key_last_5: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
}

export interface ApiKeyUpdateValues {
  name: string;
  description: string;
}

export interface ApiKeysState extends CallAPIAsyncState<ApiKey> {
  total: number;
}

const initialState: ApiKeysState = {
  ...INITIAL_ASYNC_STATE,
  total: 0,
};

const apiKeysReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case FETCH_API_KEYS_SUCCESS:
        draft.items = action.payload.api_keys;
        draft.total = action.payload.meta.count;
        draft.isFetched = true;
        return;
      case FETCH_API_KEY_SUCCESS:
        draft.item = action.payload.api_key;
        return;
      case CREATE_API_KEY_SUCCESS:
        draft.items.push(omitBy(action.payload.api_key, 'secret') as ApiKey);
        return;
      case UPDATE_API_KEY_SUCCESS:
        draft.items[
          draft.items.findIndex(item => item.id === action.payload.api_key.id)
        ] = action.payload.api_key;
        draft.item = action.payload.api_key;
        return;
      case DELETE_API_KEY_SUCCESS:
        draft.items.splice(
          draft.items.findIndex(element => element.id === action.id),
          1,
        );
        draft.total -= 1;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<ApiKeysState>({
  isLoadingActions: [FETCH_API_KEYS_START, FETCH_API_KEY_START],
  successActions: [FETCH_API_KEYS_SUCCESS, FETCH_API_KEY_SUCCESS],
  errorActions: [FETCH_API_KEYS_ERROR, FETCH_API_KEY_ERROR],
})(apiKeysReducer);
