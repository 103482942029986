import { default as notificationTemplatesConstants } from '../constants/notification_templates.constants';
import { SearchQuery } from '../middleware/api';

export interface CreateTemplatePayload {
  delivery_method: string;
  notification_type_id: number;
  notification_template_name: string;
  subject: string;
  body: string;
}

export interface UpdateTemplatePayload {
  notification_template_name?: string;
  subject?: string;
  body?: string;
  priority?: number;
}

export interface UpdateNotificationTypePayload {
  action_type?: string;
  id?: number;
  notification_category_id?: number;
  notification_type_name?: string;
  priority?: number;
  sendgrid_template_name?: string;
}

export interface UpdateTypePriorityPayload {
  priority?: number;
}

export interface EnableTemplate {
  templateId: number;
}

export const fetchNotificationTemplatesByType = (templateTypeId?: number) => {
  return {
    types: [
      notificationTemplatesConstants.FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_START,
      notificationTemplatesConstants.FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_SUCCESS,
      notificationTemplatesConstants.FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_ERROR,
    ],
    method: 'get',
    path: `admin/super/notifications/templates/types/${templateTypeId}`,
  };
};

export const fetchAllNotificationCategories = (search?: SearchQuery) => {
  return {
    types: [
      notificationTemplatesConstants.FETCH_ALL_NOTIFICATION_CATEGORIES_START,
      notificationTemplatesConstants.FETCH_ALL_NOTIFICATION_CATEGORIES_SUCCESS,
      notificationTemplatesConstants.FETCH_ALL_NOTIFICATION_CATEGORIES_ERROR,
    ],
    method: 'get',
    path: `admin/super/notifications/categories`,
    search,
  };
};

export const fetchNotificationTypesByCategory = (categoryId: number) => {
  return {
    types: [
      notificationTemplatesConstants.FETCH_NOTIFICATION_TYPES_BY_CATEGORY_START,
      notificationTemplatesConstants.FETCH_NOTIFICATION_TYPES_BY_CATEGORY_SUCCESS,
      notificationTemplatesConstants.FETCH_NOTIFICATION_TYPES_BY_CATEGORY_ERROR,
    ],
    method: 'get',
    path: `admin/super/notifications/types/${categoryId}`,
  };
};

export const fetchNotificationTemplateById = (templateId?: number) => {
  return {
    types: [
      notificationTemplatesConstants.FETCH_NOTIFICATION_TEMPLATE_BY_ID_START,
      notificationTemplatesConstants.FETCH_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS,
      notificationTemplatesConstants.FETCH_NOTIFICATION_TEMPLATE_BY_ID_ERROR,
    ],
    method: 'get',
    path: `admin/super/notifications/templates/${templateId}`,
  };
};

export const fetchSendgridTemplates = () => {
  return {
    types: [
      notificationTemplatesConstants.FETCH_SENDGRID_TEMPLATES_START,
      notificationTemplatesConstants.FETCH_SENDGRID_TEMPLATES_SUCCESS,
      notificationTemplatesConstants.FETCH_SENDGRID_TEMPLATES_ERROR,
    ],
    method: 'get',
    path: `admin/super/notifications/sendgrid`,
  };
};

export const updateNotificationTemplate = (templateId: number, payload: UpdateTemplatePayload) => {
  return {
    types: [
      notificationTemplatesConstants.UPDATE_NOTIFICATION_TEMPLATE_START,
      notificationTemplatesConstants.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS,
      notificationTemplatesConstants.UPDATE_NOTIFICATION_TEMPLATE_ERROR,
    ],
    method: 'put',
    path: `admin/super/notifications/templates/${templateId}`,
    payload,
  };
};

export const createNotificationTemplate = (payload: CreateTemplatePayload) => {
  return {
    types: [
      notificationTemplatesConstants.CREATE_NOTIFICATION_TEMPLATE_START,
      notificationTemplatesConstants.CREATE_NOTIFICATION_TEMPLATE_SUCCESS,
      notificationTemplatesConstants.CREATE_NOTIFICATION_TEMPLATE_ERROR,
    ],
    method: 'post',
    path: 'admin/super/notifications/templates',
    payload,
  };
};

export const updateNotificationType = (payload: UpdateNotificationTypePayload) => {
  return {
    types: [
      notificationTemplatesConstants.UPDATE_NOTIFICATION_TYPE_START,
      notificationTemplatesConstants.UPDATE_NOTIFICATION_TYPE_SUCCESS,
      notificationTemplatesConstants.UPDATE_NOTIFICATION_TYPE_ERROR,
    ],
    method: 'put',
    path: `admin/super/notifications/types`,
    payload,
  };
};

export const enableTemplate = (payload: EnableTemplate) => {
  return {
    types: [
      notificationTemplatesConstants.ENABLE_TEMPLATE_START,
      notificationTemplatesConstants.ENABLE_TEMPLATE_SUCCESS,
      notificationTemplatesConstants.ENABLE_TEMPLATE_ERROR,
    ],
    method: 'put',
    path: `admin/super/notifications/templates/enable`,
    payload,
  };
};

export default {
  fetchNotificationTemplatesByType,
  fetchNotificationTypesByCategory,
  createNotificationTemplate,
  updateNotificationTemplate,
  fetchNotificationTemplateById,
  enableTemplate,
  fetchSendgridTemplates,
};
