import { createSelector } from 'reselect';
import { RootState } from '..';

export const getAdminsById = (state: RootState) => state.admins.byId;
export const getDisabledAdmins = (state: RootState) => state.admins.disabled;
export const getDisabledAdminsById = (state: RootState) =>
  state.admins.disabledById;
export const getAdminsLoading = (state: RootState) => state.admins.isLoading;
export const getAdminsSuccessMessage = (state: RootState) =>
  state.admins.successMessage;
export const getAdminsErrorMessage = (state: RootState) =>
  state.admins.errorMessage;

export const selectAdmin = (state: RootState) => state.admins.item;
export const selectAdmins = (state: RootState) => state.admins.items;
export const selectAdminsById = createSelector(getAdminsById, admins => admins);
export const selectDisabledAdmins = createSelector(
  getDisabledAdmins,
  disabled => disabled,
);
export const selectDisabledAdminsById = createSelector(
  getDisabledAdminsById,
  byId => byId,
);
export const selectAdminsLoading = createSelector(
  getAdminsLoading,
  loading => loading,
);
export const selectAdminsErrorMessage = createSelector(
  getAdminsErrorMessage,
  message => message,
);
export const selectAdminsSuccessMessage = createSelector(
  getAdminsSuccessMessage,
  message => message,
);

export default {
  selectAdmin,
  selectAdmins,
  selectAdminsById,
  selectDisabledAdmins,
  selectDisabledAdminsById,
  selectAdminsLoading,
  selectAdminsErrorMessage,
  selectAdminsSuccessMessage,
};
