/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  DeleteOutlined,
  DownOutlined,
  FileAddOutlined,
  FormOutlined,
  PauseOutlined,
  ReloadOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';

import {
  Card,
  Input,
  Collapse,
  Button,
  notification,
  Tag,
  Modal,
  Dropdown,
  Menu,
} from 'antd';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { convertDispatchProps } from '../../store/middleware/api';
import {
  fetchTemplate,
  updateTemplate,
  createTemplate,
  deleteTemplate,
  restoreTemplate,
} from '../../store/actions/templates.actions';
import {
  selectTemplate,
  selectTemplatesLoading,
} from '../../store/selectors/templates.selectors';
import TemplateVariablesToolbarOption from './utils/TemplateVariablesToolbarOption';
import PasteHTMLToolbarOption from './utils/PasteHTMLToolbarOption';
import PageHeaderWrapper from '../shared/PageHeaderWrapper';
import { push } from 'connected-react-router';
import ButtonGroup from 'antd/lib/button/button-group';
import { AntInput, AntInputTextArea, AntSelect } from '../shared/AntFields';
import Text from 'antd/lib/typography/Text';

const { Panel } = Collapse;

const TemplateEditor: React.FC = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  const editorRef = useRef<any>();

  const apiAction = convertDispatchProps({
    fetchTemplate,
    updateTemplate,
    createTemplate,
  });

  const dispatch = useDispatch();
  const { templateId } = useParams<{ templateId: string }>();
  const template = useSelector(selectTemplate);
  const templateLoading = useSelector(selectTemplatesLoading);

  const handleLoadTemplate = useCallback(async () => {
    try {
      await dispatch(fetchTemplate(templateId));
    } catch (err) {
      if (err.errorMessage === 'Not Found') {
        notification.error({
          message: 'Error',
          description: 'This template does not exist.',
        });
      }
      dispatch(push('/templates'));
    }
  }, [dispatch, fetchTemplate, push]);

  useEffect(() => {
    handleLoadTemplate();
  }, [templateId]);

  useEffect(() => {
    if (Object.keys(template).length > 1) {
      const blocksFromHtml = htmlToDraft(template.currentTemplateVersion.body);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );

      const newEditorState = EditorState.push(
        editorState,
        contentState,
        'insert-fragment',
      );
      setEditorState(newEditorState);
    }
  }, [template]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  interface StatusTagMap {
    [key: string]: string;
  }
  const buildTags: any = () => {
    const statusToTagMap: StatusTagMap = {
      active: 'green',
      inactive: 'red',
      draft: 'orange',
    };
    if (template.deleted_at) {
      return <Tag color="red">deleted</Tag>;
    }
    return (
      <Tag color={statusToTagMap[template.status] || 'blue'}>
        {template.status}
      </Tag>
    );
  };

  const activeTemplateMenu = () => (
    <Menu>
      {template.status === 'draft' ? (
        <Menu.Item
          key="publish-template"
          onClick={async () => {
            try {
              await dispatch(updateTemplate(template.id, { status: 'active' }));
              notification.success({
                message: 'Success',
                description: 'Your template was published',
              });
            } catch (err) {
              notification.error({
                message: 'Error',
                description: (
                  <>
                    There was an error publishing this template
                    <br />
                    {err.toString()}
                  </>
                ),
              });
            }
          }}
        >
          <FormOutlined /> Publish
        </Menu.Item>
      ) : (
        <Menu.Item
          key="unpublish-template"
          onClick={async () => {
            try {
              await dispatch(updateTemplate(template.id, { status: 'draft' }));
              notification.success({
                message: 'Success',
                description: 'Your template was unpublished',
              });
            } catch (err) {
              notification.error({
                message: 'Error',
                description: (
                  <>
                    There was an error unpublishing this template
                    <br />
                    {err.toString()}
                  </>
                ),
              });
            }
          }}
        >
          <PauseOutlined /> Unpublish
        </Menu.Item>
      )}
      <Menu.Item
        key="duplicate-template"
        onClick={() => setShowDuplicateModal(true)}
      >
        Duplicate
      </Menu.Item>
      <Menu.Item
        key="delete-template"
        onClick={() =>
          Modal.confirm({
            title: 'Confirm deletion',
            content: 'Are you sure you want to delete this template?',
            onOk: async () => {
              try {
                await dispatch(deleteTemplate(template.id));
                notification.success({
                  message: 'Success',
                  description: 'The template was deleted',
                });
              } catch (err) {
                if (err.errorMessage === 'Not Found') {
                  notification.error({
                    message: 'Error',
                    description: 'This template does not exist.',
                  });
                }
              }
              dispatch(push('/templates'));
            },
            okText: 'Yes, Delete',
            cancelText: 'Cancel',
          })
        }
      >
        <DeleteOutlined /> Delete
      </Menu.Item>
    </Menu>
  );

  const deletedTemplateMenu = () => (
    <Menu>
      <Menu.Item
        key="restore-template"
        onClick={() => dispatch(restoreTemplate(template.id))}
      >
        <FileAddOutlined /> Restore
      </Menu.Item>
      <Menu.Item
        key="duplicate-template"
        onClick={() => setShowDuplicateModal(true)}
      >
        Duplicate
      </Menu.Item>
    </Menu>
  );

  return (
    <PageHeaderWrapper
      onBack={() => dispatch(push('/templates'))}
      title={
        <React.Fragment>
          {template.name}{' '}
          <Text code style={{ fontSize: 11 }}>
            (id: {template.id})
          </Text>
        </React.Fragment>
      }
      subTitle={``}
      htmlTitle={
        template.name
          ? `${template.name} | Letter Templates`
          : 'Letter Template'
      }
      tags={buildTags(template)}
      action={
        <div>
          <ButtonGroup>
            <Button
              onClick={() => handleLoadTemplate()}
              icon={<ReloadOutlined />}
              loading={templateLoading}
            >
              Refresh
            </Button>
            <Dropdown
              overlay={
                !template.deleted_at
                  ? activeTemplateMenu()
                  : deletedTemplateMenu()
              }
            >
              <Button style={{ marginLeft: 8 }}>
                <ThunderboltOutlined /> Actions <DownOutlined />
              </Button>
            </Dropdown>
          </ButtonGroup>
        </div>
      }
      content={
        <div className="wrap">
          <div className="content padding">
            {template.deleted_at
              ? `In order to edit this template, you must first restore it.`
              : `Content coming`}
          </div>
        </div>
      }
    >
      <Card>
        <Formik
          initialValues={{
            editorState,
          }}
          enableReinitialize
          onSubmit={async () => {
            const convertedHTML = draftToHtml(
              convertToRaw(editorState.getCurrentContent()),
            );

            try {
              await dispatch(
                updateTemplate(template.id, { body: convertedHTML }),
              );
              notification.success({
                message: 'Success',
                description: 'Your template was saved',
              });
            } catch (err) {
              notification.error({
                message: 'Error saving content',
                description: err.toString(),
              });
            }
            return;
          }}
        >
          {({ values, setFieldValue, isSubmitting, submitForm }) => (
            <>
              <Editor
                ref={editorRef}
                readOnly={Boolean(template.deleted_at)}
                editorState={values.editorState}
                editorStyle={{ border: '1px solid #eee', padding: 20 }}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={editorState => {
                  setFieldValue('editorState', editorState);
                  onEditorStateChange(editorState);
                }}
                toolbarCustomButtons={[
                  <TemplateVariablesToolbarOption
                    key="variable-toolbar-opt"
                    editorRef={editorRef}
                  />,
                  <PasteHTMLToolbarOption
                    key="paste-html-toolbar-opt"
                    editorRef={editorRef}
                  />,
                ]}
              />
              <Collapse>
                <Panel header="HTML Preview" key="1">
                  <Input.TextArea
                    disabled
                    value={draftToHtml(
                      convertToRaw(editorState.getCurrentContent()),
                    )}
                    autoSize={{ minRows: 20 }}
                  />
                </Panel>
              </Collapse>
              {!template.deleted_at && (
                <Button
                  type="primary"
                  onClick={() => submitForm()}
                  loading={isSubmitting}
                >
                  Save
                </Button>
              )}
            </>
          )}
        </Formik>
      </Card>
      <Formik
        initialValues={{
          name: `${template.name} (copy)`,
          description: template.description,
          round: template.round,
        }}
        enableReinitialize
        onSubmit={async values => {
          const result: any = await dispatch(
            apiAction.createTemplate({
              body: template.currentTemplateVersion.body,
              status: 'draft',
              ...values,
            }),
          );
          setShowDuplicateModal(false);
          dispatch(push(`/templates/${result.payload.template.id}`));
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Modal
            title="Create a duplicate"
            onOk={() => submitForm()}
            onCancel={() => setShowDuplicateModal(false)}
            visible={showDuplicateModal}
            confirmLoading={isSubmitting}
            okText={<React.Fragment>Create Duplicate</React.Fragment>}
          >
            <Field label="Name" name="name" component={AntInput} type="text" />
            <Field
              name="description"
              label="Description"
              component={AntInputTextArea}
              type="text"
            />
            <Field
              name="round"
              label="Round"
              component={AntSelect}
              style={{ width: 100 }}
              selectOptions={Array.from({ length: 31 }, (x, i) => i)
                .slice(1)
                .map(num => ({
                  value: num,
                  label: num,
                }))}
            />
          </Modal>
        )}
      </Formik>
    </PageHeaderWrapper>
  );
};

export default TemplateEditor;
