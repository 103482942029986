/* eslint-disable @typescript-eslint/no-empty-interface */
import produce from 'immer';
import { compareDesc } from 'date-fns';
import {
  FETCH_SMS_MESSAGES_START,
  FETCH_SMS_MESSAGES_SUCCESS,
  FETCH_SMS_MESSAGES_ERROR,
} from '../constants/message.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface SMSMessage {
  accountSid: string;
  apiVersion: string;
  body: string;
  dateCreated: Date;
  dateUpdated: Date;
  dateSent: Date;
  direction: string;
  errorCode: string;
  errorMessage: string;
  from: string;
  messagingServiceSid: string;
  numMedia: string;
  numSegments: number;
  price: number;
  priceUnit: string;
  sid: string;
  status: string;
  to: string;
}

export interface SMSMessagesState extends CallAPIAsyncState<SMSMessage> {}

const initialState: SMSMessagesState = {
  ...INITIAL_ASYNC_STATE,
};

const messageReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case FETCH_SMS_MESSAGES_SUCCESS:
        const { sms }: { sms: SMSMessage[] } = action.payload;
        draft.items = sms.sort((a, b) => compareDesc(a.dateSent, b.dateSent));
        draft.isFetched = true;
        return;
      case FETCH_SMS_MESSAGES_ERROR:
        draft.isFetched = true;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<SMSMessagesState>({
  isLoadingActions: [FETCH_SMS_MESSAGES_START],
  successActions: [FETCH_SMS_MESSAGES_SUCCESS],
  errorActions: [FETCH_SMS_MESSAGES_ERROR],
})(messageReducer);
