export const CREATE_CUSTOMER_START = 'CREATE_CUSTOMER_START';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';

export const FETCH_SUBSCRIPTION_START = 'FETCH_SUBSCRIPTION_START';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_ERROR = 'FETCH_SUBSCRIPTION_ERROR';
export const FETCH_PAYMENT_PREVIEW_START = 'FETCH_PAYMENT_PREVIEW_START';
export const FETCH_PAYMENT_PREVIEW_SUCCESS = 'FETCH_PAYMENT_PREVIEW_SUCCESS';
export const FETCH_PAYMENT_PREVIEW_ERROR = 'FETCH_PAYMENT_PREVIEW_ERROR';

export const UPDATE_SUBSCRIPTION_START = 'UPDATE_SUBSCRIPTION_START';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR';
export const UPDATE_PAYMENT_METHOD_START = 'UPDATE_PAYMENT_METHOD_START';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_ERROR = 'UPDATE_PAYMENT_METHOD_ERROR';

export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR';
export const REACTIVATE_SUBSCRIPTION_START = 'REACTIVATE_SUBSCRIPTION_START';
export const REACTIVATE_SUBSCRIPTION_SUCCESS =
  'REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_ERROR = 'REACTIVATE_SUBSCRIPTION_ERROR';

export const HOLD_SUBSCRIPTION_START = 'HOLD_SUBSCRIPTION_START';
export const HOLD_SUBSCRIPTION_SUCCESS = 'HOLD_SUBSCRIPTION_SUCCESS';
export const HOLD_SUBSCRIPTION_ERROR = 'HOLD_SUBSCRIPTION_ERROR';
export const REMOVE_HOLD_START = 'REMOVE_HOLD_START';
export const REMOVE_HOLD_SUCCESS = 'REMOVE_HOLD_SUCCESS';
export const REMOVE_HOLD_ERROR = 'REMOVE_HOLD_ERROR';

export const RESET_PREVIEW = 'RESET_PREVIEW';

export const FETCH_SUBSCRIPTION_PAYMENTS_START =
  'FETCH_SUBSCRIPTION_PAYMENTS_START';
export const FETCH_SUBSCRIPTION_PAYMENTS_SUCCESS =
  'FETCH_SUBSCRIPTION_PAYMENTS_SUCCESS';
export const FETCH_SUBSCRIPTION_PAYMENTS_ERROR =
  'FETCH_SUBSCRIPTION_PAYMENTS_ERROR';

export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export default {
  CREATE_CUSTOMER_START,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_ERROR,
  FETCH_SUBSCRIPTION_START,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_ERROR,
  FETCH_PAYMENT_PREVIEW_START,
  FETCH_PAYMENT_PREVIEW_SUCCESS,
  FETCH_PAYMENT_PREVIEW_ERROR,
  UPDATE_SUBSCRIPTION_START,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_PAYMENT_METHOD_START,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_ERROR,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  REACTIVATE_SUBSCRIPTION_START,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_ERROR,
  HOLD_SUBSCRIPTION_START,
  HOLD_SUBSCRIPTION_SUCCESS,
  HOLD_SUBSCRIPTION_ERROR,
  REMOVE_HOLD_START,
  REMOVE_HOLD_SUCCESS,
  REMOVE_HOLD_ERROR,
  RESET_PREVIEW,

  FETCH_SUBSCRIPTION_PAYMENTS_START,
  FETCH_SUBSCRIPTION_PAYMENTS_SUCCESS,
  FETCH_SUBSCRIPTION_PAYMENTS_ERROR,

  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
};
