import countBy from 'lodash/countBy';
import sortBy from 'lodash/sortBy';
import { format } from 'date-fns';

const groupByDate = (collection: any[], date_field: string) => {
  const grouped = countBy(collection, item =>
    format(item[date_field], 'YYYY-MM-DD'),
  );

  const dates = sortBy(Object.keys(grouped), (date: string) => {
    return new Date(date);
  });
  const result = dates.map((date: string) => {
    return {
      x: date,
      y: grouped[date],
    };
  });

  return result;
};

export default groupByDate;
