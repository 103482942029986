/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import {
  withFormik,
  Field,
  Form,
  FormikHelpers,
  FormikErrors,
  InjectedFormikProps,
} from 'formik';
import { Row, Col, Button } from 'antd';
import { RowProps } from 'antd/lib/row';

import { AntInput, AntSelect, AntMasked } from '../../shared/AntFields';
import { validatePhone } from '../../../utils';
import { addUserSchema } from '../../../schemas/user.schema';
import { SubscriptionPreviewWidget } from '../../shared/SubscriptionPreviewWidget';
import { useSelector } from 'react-redux';
import {
  selectDefaultProductsConfigForCurrentOrg,
  selectProductsAvailableToOrgByAsSelectOptions,
  selectProductEntities,
} from '../../products/productsSlice';

const rowProps: RowProps = { type: 'flex', gutter: 8 };
const personalInputProps = { xs: 24, sm: 12 };
const paymentInputProps1 = { xs: 24, sm: 18 };
const paymentInputProps2 = { xs: 24, sm: 24 - paymentInputProps1.sm };

export interface AddUserFormValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  target_score: number;
  number: string;
  exp_date: string;
  cvv: string;
  product_handle: string;
  payment_zip: string;
  promo_code: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  dob: string;
  ssn: string;
  estimated_cost?: null | number;
}

interface Props {
  onSubmit: (
    values: AddUserFormValues,
    helpers: FormikHelpers<AddUserFormValues>,
  ) => void;
  onCancel?: (e: any) => void;
  // onPricingChange?: (handle: string) => any;
  onEmailBlur?: (value: string) => Promise<any>;
  initialValues: AddUserFormValues;
  productHandles: { label: string; value: string }[];
  loading: boolean;
}

const C: React.FC<InjectedFormikProps<Props, AddUserFormValues>> = ({
  onCancel,
  // onPricingChange = () => {},
  onEmailBlur,
  loading,
  values,
  errors,
  setFieldError,
  setFieldValue,
}) => {
  const { product_handle: selectedProductHandle, email } = values;
  // setup the defaults
  const defaultProductsConfig = useSelector(
    selectDefaultProductsConfigForCurrentOrg,
  );
  const productEntities = useSelector(selectProductEntities);

  const availableProductHandlesForOrg = useSelector(
    selectProductsAvailableToOrgByAsSelectOptions,
  );

  const handleEmailBlur = () => {
    if (email && !errors.email) {
      onEmailBlur &&
        onEmailBlur(email).catch(err =>
          setFieldError('email', err.error || err.errorMessage),
        );
    }
  };

  return (
    <Form
      css={css`
        .ant-form-item-label {
          line-height: 21px;
        }
        .ant-form-item {
          margin-bottom: 10px;
        }
      `}
    >
      <Row {...rowProps}>
        <Col {...personalInputProps}>
          <Field
            required
            name="first_name"
            label="First Name"
            placeholder="First"
            type="text"
            component={AntInput}
            hasFeedback
          />
        </Col>
        <Col {...personalInputProps}>
          <Field
            required
            name="last_name"
            label="Last Name"
            placeholder="Last"
            type="text"
            component={AntInput}
            hasFeedback
          />
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col {...personalInputProps}>
          <Field
            required
            name="email"
            label="Email"
            type="email"
            placeholder="example@email.com"
            component={AntInput}
            hasFeedback
            onBlur={handleEmailBlur}
          />
        </Col>
        <Col {...personalInputProps}>
          <Field
            required
            name="phone"
            label="Phone"
            type="tel"
            format="(###) ###-####"
            placeholder="(555) 123-1234"
            className="ant-input"
            component={AntMasked}
            validate={validatePhone}
            hasFeedback
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col span={24}>
          <Field
            name="target_score"
            label="Target Score"
            type="tel"
            format="###"
            placeholder="720"
            className="ant-input"
            component={AntMasked}
            hasFeedback
          />
        </Col>
      </Row>
      <Row style={{ display: 'none' }}>
        <Col>
          <Field
            name="product_handle"
            label="Product Type"
            defaultValue={defaultProductsConfig.monthly.product_handle}
            selectOptions={availableProductHandlesForOrg}
            component={AntSelect}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>

      <Row style={{ display: 'none' }}>
        <Col>
          <Field
            name="promo_code"
            label="Promo/Coupon code"
            component={AntInput}
            type="text"
            allowClear
            style={{ width: '100%' }}
          />
        </Col>
      </Row>

      <SubscriptionPreviewWidget
        term={productEntities?.[selectedProductHandle]?.interval || 1}
        productHandle={selectedProductHandle}
        couponCode={values.promo_code}
        onResult={data => {
          const total = data?.current_billing_manifest.total_in_cents / 100;
          setFieldValue('estimated_cost', total);
        }}
        visible={false}
      />
      {values.product_handle && <PaymentMethodFields />}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button htmlType="button" onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          loading={loading}
          type="primary"
          style={{ marginLeft: '8px' }}
        >
          Add Subscriber
        </Button>
      </div>
    </Form>
  );
};

export const AddUserForm = withFormik<Props, AddUserFormValues>({
  handleSubmit: (values, { props, ...helpers }) =>
    props.onSubmit(values, helpers),
  mapPropsToValues: props => props.initialValues,
  enableReinitialize: true,
  validationSchema: addUserSchema,
  validateOnBlur: false,
  validate: values => {
    const errors: FormikErrors<AddUserFormValues> = {};

    // Free === null, so only validate actual products
    if (
      values.product_handle &&
      values.product_handle !== 'launchpad' &&
      values.estimated_cost === 0
    ) {
      //number, cvv, exp_date, payment_zip
      if (!values.number) {
        errors.number = 'Please enter a valid card number';
      }
      if (!values.cvv) {
        errors.cvv = 'Please enter a valid cvv';
      }
      if (!values.exp_date) {
        errors.exp_date = 'Please enter a valid expiration date';
      }
      if (!values.payment_zip) {
        errors.payment_zip = 'Please enter a valid zip code';
      }
    }

    return errors;
  },
})(C);

interface PaymentMethodFieldsProps {
  displayPaymentMethodFields?: boolean;
}

export const PaymentMethodFields: React.FC<PaymentMethodFieldsProps> = ({
  displayPaymentMethodFields = false,
}) => (
  <>
    <Row
      {...rowProps}
      style={{ display: displayPaymentMethodFields ? '' : 'none' }}
    >
      <Col {...paymentInputProps1}>
        <Field
          name="number"
          label="Card Number"
          type="tel"
          format="#### #### #### ####"
          placeholder="xxxx xxxx xxxx xxxx"
          className="ant-input"
          component={AntMasked}
          hasFeedback
        />
      </Col>
      <Col {...paymentInputProps2}>
        <Field
          name="cvv"
          label="CVV"
          type="tel"
          format="####"
          placeholder="720"
          className="ant-input"
          component={AntMasked}
          hasFeedback
        />
      </Col>
    </Row>
    <Row
      {...rowProps}
      style={{ display: displayPaymentMethodFields ? '' : 'none' }}
    >
      <Col {...paymentInputProps1}>
        <Field
          name="exp_date"
          label="Expiration Date"
          type="tel"
          format="##/##"
          placeholder="MM/YY"
          className="ant-input"
          component={AntMasked}
          hasFeedback
          preserveFormatting={true}
        />
      </Col>
      <Col
        {...paymentInputProps2}
        style={{ display: displayPaymentMethodFields ? '' : 'none' }}
      >
        <Field
          name="payment_zip"
          label="Postal Code"
          type="tel"
          format="#####"
          placeholder="zip"
          className="ant-input"
          component={AntMasked}
          hasFeedback
        />
      </Col>
    </Row>
  </>
);
