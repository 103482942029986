import axios, { AxiosRequestConfig } from 'axios';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { API_URI } from '../../config';

interface SearchOptions {
  q?: string;
  i?: string;
  order?: string;
  page?: number;
  page_size?: number;
}
interface ScoreShuttleAPIRequest extends AxiosRequestConfig {
  payload?: any;
  search?: SearchOptions;
  query?: Record<string, any>;
}

function config(request: any): AxiosRequestConfig {
  request = request || { auth: true };
  if (isUndefined(request.auth)) {
    request.auth = true;
  }
  const headers: any = { 'Content-Type': 'application/json' };

  let withCredentials = false;
  if (request.auth) {
    withCredentials = true;
    const accessToken =
      sessionStorage.getItem('access_token') ||
      localStorage.getItem('access_token');
    if (!isEmpty(accessToken)) {
      headers.authorization = `Bearer ${accessToken}`;
    }
  }

  return { headers, withCredentials };
}

export function fetchConfig(request?: any): RequestInit {
  request = request || { auth: true };
  if (isUndefined(request.auth)) {
    request.auth = true;
  }
  const headers: any = { 'Content-Type': 'application/json' };

  let withCredentials = false;
  if (request.auth) {
    withCredentials = true;
    const accessToken =
      sessionStorage.getItem('access_token') ||
      localStorage.getItem('access_token');
    if (!isEmpty(accessToken)) {
      headers.authorization = `Bearer ${accessToken}`;
    }
  }

  const credentials = withCredentials ? 'include' : undefined;

  return { headers, credentials };
}

const setSearchParams = (searchParams: any, values: any) => {
  keys(values).forEach(key => {
    const value = values[key];
    if (!isUndefined(value)) {
      searchParams.set(key, value);
    }
  });
};

const buildURL = (path: string, request: ScoreShuttleAPIRequest): any => {
  const url = new URL(`${API_URI}/${path}`);
  request = request || {};
  if (request.search) {
    const { search } = request;
    setSearchParams(url.searchParams, {
      ...(search.q ? { q: JSON.stringify(search.q) } : {}),
      ...(search.i ? { i: JSON.stringify(search.i) } : {}),
      order: search.order && search.order,
      page: search.page,
      page_size: search.page_size,
    });
  }
  if (request.query) {
    setSearchParams(url.searchParams, request.query);
  }

  return url;
};

class ApiRequest {
  get<T>(path: string, request: ScoreShuttleAPIRequest = {}) {
    const url = buildURL(path, request);
    return axios.get<T>(url, config(request));
  }

  post<T>(path: string, request: ScoreShuttleAPIRequest) {
    const url = buildURL(path, request);
    return axios.post<T>(url, request.payload, config(request));
  }

  put<T>(path: string, request: ScoreShuttleAPIRequest) {
    const url = buildURL(path, request);
    return axios.put<T>(url, request.payload, config(request));
  }

  delete(path: string, request: ScoreShuttleAPIRequest = {}) {
    const url = buildURL(path, request);
    return axios.delete(url, config(request));
  }
}

export const apiRequest = new ApiRequest();

export type ApiRequestMethod = keyof typeof apiRequest;
