import { default as adminConstants } from '../constants/admin.constants';
import { SearchQuery } from '../middleware/api';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { adminUserSchema } from '../../schemas/admin.schema';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

export const fetchAdmins = (
  organization: string,
  search?: SearchQuery,
  query?: Record<string, any>,
) => {
  return {
    types: [
      adminConstants.LIST_ADMINS_START,
      adminConstants.LIST_ADMINS_SUCCESS,
      adminConstants.LIST_ADMINS_ERROR,
    ],
    method: 'get',
    path: `admin/organizations/${organization}/admins`,
    search,
    query,
  };
};

export const fetchAdmin = (organization: string, adminId: string) => {
  return {
    types: [
      adminConstants.FETCH_ADMIN_START,
      adminConstants.FETCH_ADMIN_SUCCESS,
      adminConstants.FETCH_ADMIN_ERROR,
    ],
    method: 'get',
    path: `admin/organizations/${organization}/admins/${adminId}`,
  };
};

export const createAdmin = (organization: string, values: any) => {
  return {
    types: [
      adminConstants.CREATE_ADMIN_START,
      adminConstants.CREATE_ADMIN_SUCCESS,
      adminConstants.CREATE_ADMIN_ERROR,
    ],
    method: 'post',
    path: `admin/organizations/${organization}/admins`,
    payload: {
      ...values,
      phone: get(values, 'phone').replace(/\D/g, ''),
      send_enrollment: true,
    },
  };
};

export const updateAdmin = ({
  organization,
  userId,
  values,
}: {
  organization: string;
  userId: string;
  values: any;
}) => {
  return {
    types: [
      adminConstants.UPDATE_ADMIN_START,
      adminConstants.UPDATE_ADMIN_SUCCESS,
      adminConstants.UPDATE_ADMIN_ERROR,
    ],
    method: 'put',
    path: `admin/organizations/${organization}/admins/${userId}`,
    payload: {
      ...pick(values, Object.keys(adminUserSchema.fields)),
      phone: get(values, 'phone').replace(/\D/g, ''),
    },
  };
};

export const deleteAdmin = (organization: string, id: string) => {
  return {
    types: [
      adminConstants.DELETE_ADMIN_START,
      adminConstants.DELETE_ADMIN_SUCCESS,
      adminConstants.DELETE_ADMIN_ERROR,
    ],
    method: 'delete',
    path: `admin/organizations/${organization}/admins/${id}`,
    payload: {
      id,
    },
  };
};

export const reactivateAdmin = (organization: string, id: string) => {
  return {
    types: [
      adminConstants.REACTIVATE_ADMIN_START,
      adminConstants.REACTIVATE_ADMIN_SUCCESS,
      adminConstants.REACTIVATE_ADMIN_ERROR,
    ],
    method: 'post',
    path: `admin/organizations/${organization}/admins/${id}/reactivate`,
    query: { include_deleted: true },
  };
};

export const loginAsAdmin = (organization: string, adminId: string) => {
  return {
    types: [
      adminConstants.LOGIN_AS_ADMIN_START,
      adminConstants.LOGIN_AS_ADMIN_SUCCESS,
      adminConstants.LOGIN_AS_ADMIN_ERROR,
    ],
    method: 'post',
    path: `admin/organizations/${organization}/admins/${adminId}/token`,
  };
};

export const logoutAsAdmin: any = () => (dispatch: Dispatch) => {
  sessionStorage.removeItem('access_token');

  dispatch({
    type: adminConstants.LOGOUT_AS_ADMIN,
  });
  dispatch(push('/organizations'));
};

export default {
  fetchAdmins,
  fetchAdmin,
  createAdmin,
  updateAdmin,
  deleteAdmin,
  reactivateAdmin,
  loginAsAdmin,
};
