/* eslint-disable default-case */
import produce from 'immer';
import {
  FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_ERROR,
  FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_START,
  FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_SUCCESS,
  FETCH_NOTIFICATION_TYPES_BY_CATEGORY_SUCCESS,
  FETCH_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS,
  FETCH_ALL_NOTIFICATION_CATEGORIES_SUCCESS,
  FETCH_SENDGRID_TEMPLATES_SUCCESS,
} from '../constants/notification_templates.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export type NotificationTemplateStatus = 'active' | 'inactive' | 'draft';

export interface NotificationTemplate {
  id?: number;
  notification_type_id?: number;
  delivery_method?: string;
  notification_template_name?: string;
  subject?: string;
  body?: string;
  is_active?: boolean;
  created_at?: string;
  sendgrid_template_name?: string;
  updated_at?: string;
  notificationType?: NotificationType;
}

export interface NotificationType {
  id: number;
  notification_type_name: string;
  action_type: string;
  notification_category_id: number;
  is_required: boolean;
  created_at: string;
  updated_at: string;
  priority: number;
}

export interface NotificationCategory {
  id: number;
  notification_category_name: string;
  created_at: string;
  updated_at: string;
}

export interface SendgridTemplates {
  id: number;
  name: string;
}

export interface NotificationTemplatesState extends CallAPIAsyncState<NotificationTemplate> {
  allNotificationCategories: NotificationCategory[];
  notificationTemplatesByType: NotificationTemplate[];
  notificationTypesByCategory: NotificationType[];
  notificationTemplateById: NotificationTemplate;
  sendgridTemplates: SendgridTemplates[];
}

const initialState: NotificationTemplatesState = {
  ...INITIAL_ASYNC_STATE,
  allNotificationCategories: [],
  notificationTemplateById: {},
  notificationTemplatesByType: [],
  notificationTypesByCategory: [],
  sendgridTemplates: [],
};

const notificationTemplatesReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case FETCH_ALL_NOTIFICATION_CATEGORIES_SUCCESS:
        draft.allNotificationCategories = action.payload;
        return;
      case FETCH_NOTIFICATION_TYPES_BY_CATEGORY_SUCCESS:
        draft.notificationTypesByCategory = action.payload;
        return;
      case FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_SUCCESS:
        draft.notificationTemplatesByType = action.payload;
        return;
      case FETCH_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS:
        draft.notificationTemplateById = action.payload[0];
        return;
      case FETCH_SENDGRID_TEMPLATES_SUCCESS:
        draft.sendgridTemplates = action.payload;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<NotificationTemplatesState>({
  successActions: [FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_SUCCESS, FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_SUCCESS],
  isLoadingActions: [FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_START, FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_START],
  errorActions: [FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_ERROR, FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_ERROR],
})(notificationTemplatesReducer);
