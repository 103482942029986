import { createSelector } from 'reselect';
import { RootState } from '..';
import { format } from 'date-fns';
import countBy from 'lodash/countBy';
import sortBy from 'lodash/sortBy';
import { Surge__Commission__c } from '../reducers/commission.reducer';

export const getCommissions = (state: RootState) => state.commissions.items;
export const getCommissionsLoading = (state: RootState) =>
  state.commissions.isLoading;

export const selectCommissions = createSelector(
  getCommissions,
  commissions => commissions,
);

export const selectCommissionsLoading = createSelector(
  getCommissionsLoading,
  loading => loading,
);

export const selectCommissionsByDayForChart = createSelector(
  getCommissions,
  commissions => {
    const grouped = countBy(commissions, txn =>
      format(txn.CreatedDate, 'YYYY-MM-DD'),
    );

    const dates = sortBy(Object.keys(grouped), (a: string) => {
      return new Date(a);
    });
    const result = dates.map((date: string) => {
      return {
        x: date,
        y: grouped[date],
      };
    });

    return result;
  },
);

export const selectTotalCommissions = createSelector(
  getCommissions,
  commissions =>
    commissions.reduce(
      (acc: any, curr: Surge__Commission__c) => acc + curr.Surge__Amount__c,
      0,
    ),
);

export const selectDailyCommissions = createSelector(
  getCommissions,
  commissions => {
    const date = format(new Date(), 'YYYY-MM-DD');
    return commissions
      .filter(
        (txn: Surge__Commission__c) =>
          format(txn.CreatedDate, 'YYYY-MM-DD') === date,
      )
      .reduce(
        (acc: any, curr: Surge__Commission__c) => acc + curr.Surge__Amount__c,
        0,
      );
  },
);
export default {
  selectCommissions,
  selectCommissionsLoading,
  selectCommissionsByDayForChart,
  selectTotalCommissions,
  selectDailyCommissions,
};
