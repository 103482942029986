export const FETCH_DISPUTED_ITEMS_START = 'FETCH_DISPUTED_ITEMS_START';
export const FETCH_DISPUTED_ITEMS_SUCCESS = 'FETCH_DISPUTED_ITEMS_SUCCESS';
export const FETCH_DISPUTED_ITEMS_ERROR = 'FETCH_DISPUTED_ITEMS_ERROR';

export const UPDATE_DISPUTED_ITEMS_START = 'UPDATE_DISPUTED_ITEMS_START';
export const UPDATE_DISPUTED_ITEMS_SUCCESS = 'UPDATE_DISPUTED_ITEMS_SUCCESS';
export const UPDATE_DISPUTED_ITEMS_ERROR = 'UPDATE_DISPUTED_ITEMS_ERROR';

export default {
  FETCH_DISPUTED_ITEMS_START,
  FETCH_DISPUTED_ITEMS_SUCCESS,
  FETCH_DISPUTED_ITEMS_ERROR,

  UPDATE_DISPUTED_ITEMS_START,
  UPDATE_DISPUTED_ITEMS_SUCCESS,
  UPDATE_DISPUTED_ITEMS_ERROR,
};
