type Role = 'super' | 'manager' | 'admin' | 'account-manager' | 'agent';
type Authority = Role | Role[];
// use localStorage to store the authority info
export function getAuthority(str?: any) {
  // return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
  const authorityString =
    typeof str === 'undefined'
      ? localStorage.getItem('antd-pro-authority')
      : str;
  // authorityString could be admin, "admin", ["admin"]
  let authority;
  try {
    authority = JSON.parse(authorityString);
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority] as Authority;
  }
  console.log(
    'got: authority, ls',
    authority,
    localStorage.getItem('antd-pro-authority'),
  );
  return authority || (['admin'] as Authority);
}

export function setAuthority(authority: Authority) {
  console.log('setting authority', authority);
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  return localStorage.setItem(
    'antd-pro-authority',
    JSON.stringify(proAuthority),
  );
}

export function removeAuthority() {
  return localStorage.removeItem('antd-pro-authority');
}
