/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Middleware,
  MiddlewareAPI,
  Dispatch,
  createAction,
  AnyAction,
} from '@reduxjs/toolkit';
import { nesClient } from '../../utils';
import { WS_URI } from '../../config';
import { Client } from '@hapi/nes/lib/client';
import { RootState } from '..';
import {
  subscribeToVerifications,
  addPendingVerification,
  removePendingVerification,
} from '../../modules/goalkeeper/verificationsSlice';

export const subscribeVerifications = createAction(
  'ws/subscribe',
  function prepare(organizationId) {
    return {
      payload: {
        organizationId,
      },
    };
  },
);
export const unsubscribeVerifications = createAction(
  'ws/unsubscribe',
  function prepare(organizationId) {
    return {
      payload: {
        organizationId,
      },
    };
  },
);

function ws() {
  let _clientSingleton: null | Omit<Client, 'reauthenticate'> = null; // update this nesClient to have this later
  const getClientSingleton = async () => {
    if (!_clientSingleton) {
      _clientSingleton = new nesClient(WS_URI, {});
      const accessToken =
        sessionStorage.getItem('access_token') ||
        localStorage.getItem('access_token');

      await _clientSingleton.connect({
        auth: {
          headers: {
            authorization: [
              localStorage.getItem('token_type') || 'Bearer',
              accessToken,
            ].join(' '),
          },
        },
      });
    }
    return _clientSingleton;
  };

  const middleware: Middleware = ({
    getState,
    dispatch,
  }: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => (
    next: Dispatch,
  ) => async action => {
    if (subscribeToVerifications.fulfilled.match(action)) {
      const client = await getClientSingleton();

      try {
        await client.subscribe(
          `/v1/admin/super/manual_review`,
          ({ event, job }: { event: string; job: any }) => {
            switch (event) {
              case 'add':
                dispatch(addPendingVerification(job));
                break;
              case 'enqueue':
                dispatch(removePendingVerification(job.id));
                break;
              case 'cancel':
                dispatch(removePendingVerification(job.id));
                break;
            }
          },
        );
      } catch (err) {
        console.log('Error subscribing:', err);
      }
    } else if (unsubscribeVerifications.match(action)) {
      if (!_clientSingleton) return;

      _clientSingleton.disconnect();
    }

    return next(action);
  };

  return middleware;
}

const wsMiddleware = ws();

export default wsMiddleware;
