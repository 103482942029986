import { createSelector } from 'reselect';
import { RootState } from '..';

export const getSendgridCampaigns = (state: RootState) =>
  state.sendgridCampaigns.items;
export const getSendgridCampaignsLoading = (state: RootState) =>
  state.sendgridCampaigns.isLoading;
export const getSendgridCampaignsErrorMessage = (state: RootState) =>
  state.sendgridCampaigns.errorMessage;

export const selectSendgridCampaigns = createSelector(
  getSendgridCampaigns,
  campaigns => campaigns,
);
export const selectSendgridCampaignsLoading = createSelector(
  getSendgridCampaignsLoading,
  loading => loading,
);
export const selectSendgridCampaignsErrorMessage = createSelector(
  getSendgridCampaignsLoading,
  msg => msg,
);

export default {
  selectSendgridCampaigns,
  selectSendgridCampaignsLoading,
  selectSendgridCampaignsErrorMessage,
};
