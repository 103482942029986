import React, { ComponentProps, useState, CSSProperties } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownOutlined } from '@ant-design/icons';
import { Modal, notification, Button, Menu, Dropdown } from 'antd';
import { ModalProps } from 'antd/lib/modal';

import {
  selectUsersLoading,
  selectSubscriptionsLoading,
  selectCurrentAdmin,
  selectIsSuper,
} from '../../../store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  createUser,
  checkEmail,
  CreateUserResp,
} from '../../../store/actions/user.actions';
import { fetchPaymentPreview } from '../../../store/actions/subscriptions.actions';
import {
  convertDispatchProps,
  CallAPIResponse,
} from '../../../store/middleware/api';
import { AddUserForm, AddUserFormValues } from './AddUserForm';
import pick from 'lodash/pick';
import { DropDownProps } from 'antd/lib/dropdown';
import { ButtonProps } from 'antd/lib/button';
import { selectDefaultProductsConfigForCurrentOrg } from '../../products/productsSlice';
import { selectCurrentOrganization } from '../../../store/reducers/organizationSlice';

type AddUserFormProps = ComponentProps<typeof AddUserForm>;

interface Props extends ModalProps {
  onSubmit?: (
    values: AddUserFormProps['initialValues'] & {
      user_id: number;
      errors: string[];
    },
  ) => void;
}

export const AddUserModal: React.FC<Props> = ({ onSubmit, ...modalProps }) => {
  const [overrideValues, setOverrideValues] = useState<
    AddUserFormProps['initialValues']
  >();
  const dispatch = useDispatch();
  const currentAdmin = useSelector(selectCurrentAdmin);
  const isSuper = useSelector(selectIsSuper);
  const apiActions = convertDispatchProps({
    createUser,
    fetchPaymentPreview,
    checkEmail,
  });

  const currentOrg = useSelector(selectCurrentOrganization);

  const usersLoading = useSelector(selectUsersLoading);
  const subscriptionsLoading = useSelector(selectSubscriptionsLoading);

  const defaultProductsConfig = useSelector(
    selectDefaultProductsConfigForCurrentOrg,
  );

  let defaultTargetScore = Number(currentOrg.default_user_target_score);
  defaultTargetScore = Number.isInteger(defaultTargetScore)
    ? defaultTargetScore
    : 720;

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    target_score: defaultTargetScore,
    number: '',
    exp_date: '',
    cvv: '',
    payment_zip: '',
    product_handle: defaultProductsConfig.monthly.product_handle,
    promo_code: '',
  };

  const handleAddUser: AddUserFormProps['onSubmit'] = (values, helpers) => {
    const expSplit = values.exp_date
      ? values.exp_date.split('/')
      : values.exp_date;
    const userFields = {
      ...pick(values, [
        'first_name',
        'last_name',
        'email',
        'phone',
        'target_score',
        'address',
        'city',
        'state',
        'zip',
        'ssn',
        'dob',
        'promo_code',
        'product_handle',
      ]),
      ...(values.number
        ? {
            payment_method: {
              cvv: values.cvv,
              zip: values.payment_zip,
              number: values.number.replace(/\D/g, ''),
              exp_month: expSplit[0],
              exp_year: `20${expSplit[1]}`,
            },
          }
        : {}),
    };

    return dispatch(apiActions.createUser(currentOrg.subdomain, userFields))
      .then(
        (resp: CallAPIResponse<CreateUserResp>) =>
          onSubmit &&
          onSubmit({
            ...values,
            user_id: Number(resp.payload.user.id),
            errors: resp.payload.errors,
          }),
      )
      .catch(err => {
        if (err.payload.duplicate_id) {
          helpers.setFieldTouched('email');
          helpers.setFieldError('email', err.payload.message);
        }

        const hasFieldErrors = err.payload.details;
        let errorMessage = `There was an error adding this subscriber, please review the data
        and try again.`;
        if (err.payload.details?.[0].path?.includes('payment_method')) {
          errorMessage = `There was a problem with the provided payment method. Please double check all fields and try again`;
        }

        // TODO: handle field errors - they need to be mapped from the api schema -> form fields

        notification.error({
          message: 'Error',
          description: (
            <div>
              {errorMessage}
              {!hasFieldErrors && (
                <div>
                  {' '}
                  <br />
                  <br />
                  {err.message ||
                    err.errorMessage ||
                    'An Unknown Error Occurred'}
                </div>
              )}
            </div>
          ),
        });
      });
  };

  // const handlePricingChange: AddUserFormProps['onPricingChange'] = handle =>
  //   apiActions.fetchPaymentPreview(currentOrg.subdomain, {
  //     price_point_handle: handle,
  //     promo_code: currentOrg.default_promo_code,
  //   });

  type OneClickRiskLevel = 'low' | 'medium' | 'high';

  const dynamicEmail = (email: string, risk?: OneClickRiskLevel) => {
    if (!email.includes('@')) {
      return email;
    }
    // eslint-disable-next-line prefer-const
    let [username, domain] = email.split('@');
    const separator = username.includes('+') ? '' : '+';
    const timestamp = Math.floor(new Date().valueOf() / 1000);
    username = `${username}${separator}${timestamp}`;
    if (risk) {
      // this will look like myadminname+234234234__low@gmail.com
      // the __flag pattern is dealt with on the API
      username = `${username}__${risk}`;
    }
    return [username, domain].join('@');
  };

  const generateOverrideValues = ({
    withCard = false,
    failingCard = false,
  }: {
    withCard: boolean;
    failingCard: boolean;
  }) => {
    const values = {
      ...initialValues,
      first_name: 'Donald',
      last_name: 'Blair',
      email: dynamicEmail(currentAdmin.email),
      phone: currentAdmin.phone,
      address: '3627 W Poplar St',
      city: 'San Antonio',
      state: 'TX',
      zip: '78228',
      dob: '09/20/1939',
      ssn: '666285344',
      ...(withCard
        ? {
            number: `4242 4242 4242 424${failingCard ? 3 : 1}`,
            cvv: '123',
            exp_date: '12/29',
            payment_zip: '01020',
          }
        : {}),
    };
    return (values as unknown) as AddUserFormValues;
  };

  const generateOneClickUserValues = ({
    withCard = true,
    failingCard = false,
    risk = 'low',
  }: {
    withCard: boolean;
    failingCard?: boolean;
    risk: OneClickRiskLevel;
  }) => {
    const values = {
      ...initialValues,
      first_name: 'PEGGY',
      last_name: 'PAPKE',
      email: dynamicEmail(currentAdmin.email, risk),
      phone: currentAdmin.phone,
      address: '120 JEFFERSON DR',
      city: 'PITTSBURGH',
      state: 'PA',
      zip: '15228',
      dob: '12/16/1974',
      ssn: '666129360',
      ...(withCard
        ? {
            number: `4242 4242 4242 424${failingCard ? 3 : 1}`,
            cvv: '123',
            exp_date: '12/29',
            payment_zip: '01020',
          }
        : {}),
    };
    return (values as unknown) as AddUserFormValues;
  };

  const prepopulateMenuOptions = {
    'without-card': {
      title: 'Donald without card',
      icon: 'user',
    },
    'valid-card': {
      title: 'Donald with valid card',
      icon: 'user',
    },
    'failing-card': {
      title: 'Donald with invalid card',
      icon: 'user',
    },
    'one-click-low': {
      title: (
        <>
          One-Click - Pass <i>(low risk)</i>
        </>
      ),
      icon: 'user',
    },
    'one-click-medium': {
      title: (
        <>
          One-Click - Return Questions <i>(medium risk)</i>
        </>
      ),
      icon: 'user',
    },
    'one-click-high': {
      title: (
        <>
          One-Click - Fail <i>(high risk)</i>
        </>
      ),
      icon: 'user',
    },
  };
  type MenuKeyTypes = keyof typeof prepopulateMenuOptions;
  const handleMenuClick = (e: any) => {
    const typedKey = e.key as MenuKeyTypes;
    let values: AddUserFormValues;

    if (typedKey.includes('one-click')) {
      const risk = typedKey.split('-')[2] as OneClickRiskLevel;
      values = generateOneClickUserValues({
        withCard: typedKey !== 'without-card',
        risk,
      });
    } else {
      values = generateOverrideValues({
        withCard: typedKey !== 'without-card',
        failingCard: typedKey === 'failing-card',
      });
    }
    setOverrideValues(values);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {Object.entries(prepopulateMenuOptions).map(([key, params]) => (
        <Menu.Item key={key}>
          <LegacyIcon type={params.icon} />
          {params.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const PrepopulateDropdown: React.FC<{
    dropdownProps?: DropDownProps;
    buttonProps?: ButtonProps;
    style?: CSSProperties;
  }> = ({ dropdownProps, buttonProps, style }) => (
    <div style={style}>
      <Dropdown overlay={menu} {...dropdownProps}>
        <Button size="small" {...buttonProps}>
          Prepopulate <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );

  return (
    <Modal
      {...modalProps}
      title={
        <div>
          Add Subscriber{' '}
          {isSuper ? (
            <>
              <br />
              <br />
              <PrepopulateDropdown />
            </>
          ) : (
            ''
          )}
        </div>
      }
      footer={null}
    >
      <AddUserForm
        initialValues={
          overrideValues
            ? overrideValues
            : {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                target_score: defaultTargetScore,
                number: '',
                exp_date: '',
                cvv: '',
                payment_zip: '',
                product_handle: defaultProductsConfig.monthly.product_handle,
                promo_code: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                ssn: '',
                dob: '',
                estimated_cost: null,
              }
        }
        onSubmit={handleAddUser}
        onCancel={modalProps.onCancel}
        // onPricingChange={handlePricingChange}
        onEmailBlur={event => dispatch(apiActions.checkEmail(event))}
        productHandles={[]}
        loading={usersLoading || subscriptionsLoading}
      />
    </Modal>
  );
};
