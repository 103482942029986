import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import store from './store';
import history from './store/history';
import AxiosInterceptors from './utils/network-interceptors';
import { Bugsnag } from './utils';

import { App } from './App';

const ErrorBoundary = Bugsnag.getPlugin('react');

AxiosInterceptors.setup(store);

ReactDOM.render(
  ErrorBoundary ? (
    <ErrorBoundary>
      <App history={history} />
    </ErrorBoundary>
  ) : (
    <App history={history} />
  ),
  document.getElementById('root'),
);

window.React = React; // @future: hack to get around React is undefined errors in production

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
