export const FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_START = 'FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_START';
export const FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_SUCCESS = 'FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_SUCCESS';
export const FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_ERROR = 'FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_ERROR';
export const FETCH_ALL_NOTIFICATION_CATEGORIES_START = 'FETCH_ALL_NOTIFICATION_CATEGORIES_START';
export const FETCH_ALL_NOTIFICATION_CATEGORIES_SUCCESS = 'FETCH_ALL_NOTIFICATION_CATEGORIES_SUCCESS';
export const FETCH_ALL_NOTIFICATION_CATEGORIES_ERROR = 'FETCH_ALL_NOTIFICATION_CATEGORIES_ERORR';
export const FETCH_NOTIFICATION_TYPES_BY_CATEGORY_START = 'FETCH_NOTIFICATION_TYPES_BY_CATEGORY_START';
export const FETCH_NOTIFICATION_TYPES_BY_CATEGORY_SUCCESS = 'FETCH_NOTIFICATION_TYPES_BY_CATEGORY_SUCESS';
export const FETCH_NOTIFICATION_TYPES_BY_CATEGORY_ERROR = 'FETCH_NOTIFICATION_TYPES_BY_CATEGORY_ERROR';
export const FETCH_NOTIFICATION_TEMPLATE_BY_ID_START = 'FETCH_NOTIFICATION_TEMPLATE_BY_ID_START';
export const FETCH_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS = 'FETCH_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS';
export const FETCH_NOTIFICATION_TEMPLATE_BY_ID_ERROR = 'FETCH_NOTIFICATION_TEMPLATE_BY_ID_ERROR';
export const FETCH_SENDGRID_TEMPLATES_START = 'FETCH_SENDGRID_TEMPLATE_START';
export const FETCH_SENDGRID_TEMPLATES_SUCCESS = 'FETCH_SENDGRID_TEMPLATE_SUCCESS';
export const FETCH_SENDGRID_TEMPLATES_ERROR = 'FETCH_SENDGRID_TEMPLATE_ERROR';
export const UPDATE_NOTIFICATION_TEMPLATE_START = 'UPDATE_NOTIFICATION_TEMPLATE_START';
export const UPDATE_NOTIFICATION_TEMPLATE_SUCCESS = 'UPDATE_NOTIFICATION_TEMPLATE_SUCCESS';
export const UPDATE_NOTIFICATION_TEMPLATE_ERROR = 'UPDATE_NOTIFICATION_TEMPLATE_ERROR';
export const CREATE_NOTIFICATION_TEMPLATE_START = 'CREATE_NOTIFICATION_TEMPLATE_START';
export const CREATE_NOTIFICATION_TEMPLATE_SUCCESS = 'CREATE_NOTIFICATION_TEMPLATE_SUCCESS';
export const CREATE_NOTIFICATION_TEMPLATE_ERROR = 'CREATE_NOTIFICATION_TEMPLATE_ERROR';
export const UPDATE_NOTIFICATION_TYPE_START = 'UPDATE_NOTIFICATION_TYPE_START';
export const UPDATE_NOTIFICATION_TYPE_SUCCESS = 'UPDATE_NOTIFICATION_TYPE_SUCCESS';
export const UPDATE_NOTIFICATION_TYPE_ERROR = 'UPDATE_NOTIFICATION_TYPE_ERROR';
export const UPDATE_NOTIFICATION_TYPE_PRIORITY_START = 'UPDATE_NOTIFICATION_TYPE_PRIORITY_START';
export const UPDATE_NOTIFICATION_TYPE_PRIORITY_SUCCESS = 'UPDATE_NOTIFICATION_TYPE_PRIORITY_SUCCESS';
export const UPDATE_NOTIFICATION_TYPE_PRIORITY_ERROR = 'UPDATE_NOTIFICATION_TYPE_PRIORITY_ERROR';
export const ENABLE_TEMPLATE_START = 'ENABLE_TEMPLATE_START';
export const ENABLE_TEMPLATE_SUCCESS = 'ENABLE_TEMPLATE_SUCCESS';
export const ENABLE_TEMPLATE_ERROR = 'ENABLE_TEMPLATE_ERROR';

export default {
  FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_START,
  FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_SUCCESS,
  FETCH_NOTIFICATION_TEMPLATES_BY_TYPE_ERROR,
  FETCH_ALL_NOTIFICATION_CATEGORIES_START,
  FETCH_ALL_NOTIFICATION_CATEGORIES_SUCCESS,
  FETCH_ALL_NOTIFICATION_CATEGORIES_ERROR,
  FETCH_NOTIFICATION_TYPES_BY_CATEGORY_START,
  FETCH_NOTIFICATION_TYPES_BY_CATEGORY_SUCCESS,
  FETCH_NOTIFICATION_TYPES_BY_CATEGORY_ERROR,
  UPDATE_NOTIFICATION_TEMPLATE_START,
  UPDATE_NOTIFICATION_TEMPLATE_SUCCESS,
  UPDATE_NOTIFICATION_TEMPLATE_ERROR,
  FETCH_NOTIFICATION_TEMPLATE_BY_ID_START,
  FETCH_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS,
  FETCH_NOTIFICATION_TEMPLATE_BY_ID_ERROR,
  FETCH_SENDGRID_TEMPLATES_START,
  FETCH_SENDGRID_TEMPLATES_SUCCESS,
  FETCH_SENDGRID_TEMPLATES_ERROR,
  CREATE_NOTIFICATION_TEMPLATE_START,
  CREATE_NOTIFICATION_TEMPLATE_SUCCESS,
  CREATE_NOTIFICATION_TEMPLATE_ERROR,
  UPDATE_NOTIFICATION_TYPE_START,
  UPDATE_NOTIFICATION_TYPE_SUCCESS,
  UPDATE_NOTIFICATION_TYPE_ERROR,
  UPDATE_NOTIFICATION_TYPE_PRIORITY_START,
  UPDATE_NOTIFICATION_TYPE_PRIORITY_SUCCESS,
  UPDATE_NOTIFICATION_TYPE_PRIORITY_ERROR,
  ENABLE_TEMPLATE_START,
  ENABLE_TEMPLATE_SUCCESS,
  ENABLE_TEMPLATE_ERROR,
};
