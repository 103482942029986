import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { AntInputTextArea } from '../../shared/AntFields';
import { Button, Modal } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const PasteHTMLToolbarOption: React.FC<any> = ({ editorState, onChange }) => {
  // the types are incomplete for this lib. A toolbar is passed editorState, onChange, modalHandler, translations
  const [modalVisible, setModalVisible] = useState(false);
  const insertHTMLContent = (content: string) => {
    // current state
    const currentContentState = editorState.getCurrentContent();
    const currentBlockMap = currentContentState.getBlockMap();

    // convert pasted html
    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const newState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    const newBlockMap = newState.getBlockMap();
    const combinedBlocks = newBlockMap.concat(currentBlockMap);

    const combinedContentState = ContentState.createFromBlockArray(
      combinedBlocks.toArray(),
    );

    onChange(
      EditorState.push(editorState, combinedContentState, 'insert-fragment'),
    );
  };

  return (
    <>
      <Button onClick={() => setModalVisible(true)}>Insert html</Button>
      <Formik
        initialValues={{ content: '' }}
        onSubmit={values => {
          insertHTMLContent(values.content);
          setModalVisible(false);
        }}
      >
        {({ submitForm }) => (
          <Modal
            title="Convert HTML"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            maskClosable
            footer={null}
          >
            <Paragraph type="secondary">
              Paste some html below and hit insert data to add it to the
              existing data in the editor
            </Paragraph>
            <Field
              name="content"
              component={AntInputTextArea}
              type="text"
              autoSize={{ minRows: 8 }}
            />
            <Button onClick={() => submitForm()}>Insert data</Button>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default PasteHTMLToolbarOption;
