export const FETCH_TEMPLATES_START = 'FETCH_TEMPLATES_START';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_ERROR = 'FETCH_TEMPLATES_ERROR';

export const FETCH_DELETED_TEMPLATES_START = 'FETCH_DELETED_TEMPLATES_START';
export const FETCH_DELETED_TEMPLATES_SUCCESS =
  'FETCH_DELETED_TEMPLATES_SUCCESS';
export const FETCH_DELETED_TEMPLATES_ERROR = 'FETCH_DELETED_TEMPLATES_ERROR';

export const FETCH_TEMPLATE_START = 'FETCH_TEMPLATE_START';
export const FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS';
export const FETCH_TEMPLATE_ERROR = 'FETCH_TEMPLATE_ERROR';

export const UPDATE_TEMPLATE_START = 'UPDATE_TEMPLATE_START';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_ERROR = 'UPDATE_TEMPLATE_ERROR';

export const CREATE_TEMPLATE_START = 'CREATE_TEMPLATE_START';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_ERROR = 'CREATE_TEMPLATE_ERROR';

export const DELETE_TEMPLATE_START = 'DELETE_TEMPLATE_START';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';

export const RESTORE_TEMPLATE_START = 'RESTORE_TEMPLATE_START';
export const RESTORE_TEMPLATE_SUCCESS = 'RESTORE_TEMPLATE_SUCCESS';
export const RESTORE_TEMPLATE_ERROR = 'RESTORE_TEMPLATE_ERROR';

export default {
  FETCH_TEMPLATE_START,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_ERROR,
  FETCH_TEMPLATES_START,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_ERROR,
  FETCH_DELETED_TEMPLATES_START,
  FETCH_DELETED_TEMPLATES_SUCCESS,
  FETCH_DELETED_TEMPLATES_ERROR,
  UPDATE_TEMPLATE_START,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_ERROR,
  CREATE_TEMPLATE_START,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_ERROR,
  DELETE_TEMPLATE_START,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_ERROR,
  RESTORE_TEMPLATE_START,
  RESTORE_TEMPLATE_SUCCESS,
  RESTORE_TEMPLATE_ERROR,
};
