import { Global, jsx, css } from '@emotion/react';
import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';

import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';

import { AppContainer, Auth as AuthContainer } from './containers';

import 'ant-design-pro/dist/ant-design-pro.css'; // Import whole style :(
import store from './store';

import PrivateRoute from './modules/shared/PrivateRoute';

import Invitation from './modules/organizations/OrganizationInvitation';

const globalStyles = css`
  html,
  body,
  #root {
    height: 100%;
  }

  .colorWeak {
    filter: invert(80%);
  }

  .ant-layout {
    min-height: 100vh;
  }

  canvas {
    display: block;
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .globalSpin {
    width: 100%;
    margin: 40px 0 !important;
  }

  ul,
  ol {
    list-style: none;
  }
`;

interface Props {
  history: History;
}

interface OtherProps {
  children?: (props: Props) => React.ReactNode;
}

export class App extends React.Component<Props & OtherProps> {
  render() {
    const { history } = this.props;

    return (
      <Provider store={store}>
        <>
          <Helmet
            titleTemplate="%s | ScoreShuttle Admin"
            defaultTitle="ScoreShuttle Admin"
          />
          <Global styles={globalStyles} />
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/login" component={AuthContainer} />
              <Route
                key="invitation"
                path="/:organizationId/invitation"
                component={Invitation}
              />
              <PrivateRoute path="/">
                <AppContainer />
              </PrivateRoute>
            </Switch>
          </ConnectedRouter>
        </>
      </Provider>
    );
  }
}

export default App;
