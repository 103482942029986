import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  LIST_ADMINS_START,
  LIST_ADMINS_SUCCESS,
  LIST_ADMINS_ERROR,
  FETCH_ADMIN_START,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_ERROR,
  CREATE_ADMIN_START,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_ERROR,
  DELETE_ADMIN_START,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_ERROR,
  REACTIVATE_ADMIN_START,
  REACTIVATE_ADMIN_SUCCESS,
  REACTIVATE_ADMIN_ERROR,
  UPDATE_ADMIN_START,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_ERROR,
} from '../constants/admin.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface Admin {
  readonly id: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  created_at: Date;
  updated_at: Date;
  role?: Role;
  role_deleted_at?: Date;
  permissions: null | string[];
}

export interface AdminsById {
  [key: string]: Admin;
}

export type AdminUpdateOptions = Partial<Admin>;

export type Role = 'super' | 'admin' | 'manager';

export interface AdminsState extends CallAPIAsyncState<Admin> {
  editing: string;
  byId: AdminsById;
  disabled: Admin[];
  disabledById: Record<string, Admin>;
}

const initialState: AdminsState = {
  editing: '',
  byId: {},
  disabled: [],
  disabledById: {},
  ...INITIAL_ASYNC_STATE,
};

const adminReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case LIST_ADMINS_SUCCESS:
        draft.items = [];
        draft.disabled = [];
        for (const admin of action.payload.admins) {
          if (admin.role_deleted_at) {
            draft.disabled.push(admin);
            draft.disabledById[admin.id] = admin;
          } else {
            draft.items.push(admin);
            draft.byId[admin.id] = admin;
          }
        }
        draft.isFetched = true;
        return;
      case FETCH_ADMIN_SUCCESS:
        draft.item = action.payload.admin;
        return;
      case CREATE_ADMIN_SUCCESS:
        draft.items.push(action.payload.admin);
        draft.byId[action.payload.admin.id] = action.payload.admin;
        return;
      case DELETE_ADMIN_SUCCESS:
        const [removed] = draft.items.splice(
          draft.items.findIndex(element => element.id === action.id),
          1,
        );
        delete draft.byId[action.id];
        draft.disabled.push(removed);
        draft.disabled = draft.disabled.sort(
          (a, b) => Number(a.id) - Number(b.id),
        );
        draft.disabledById[action.id] = removed;
        return;
      case UPDATE_ADMIN_SUCCESS:
        draft.items[
          draft.items.findIndex(item => item.id === action.payload.admin.id)
        ] = action.payload.admin;
        draft.byId = draft.items.reduce(
          (adminsById: AdminsById, admin: Admin) => ({
            ...adminsById,
            [admin.id]: admin,
          }),
          {},
        );
        return;
      case REACTIVATE_ADMIN_SUCCESS:
        draft.disabled.splice(
          draft.disabled.findIndex(item => item.id === action.payload.admin.id),
          1,
        );
        delete draft.disabledById[action.payload.admin.id];
        draft.items.push(action.payload.admin);
        draft.items = draft.items.sort((a, b) => Number(a.id) - Number(b.id));
        draft.byId[action.payload.admin.id] = action.payload.admin;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<AdminsState>({
  isLoadingActions: [
    LIST_ADMINS_START,
    FETCH_ADMIN_START,
    CREATE_ADMIN_START,
    DELETE_ADMIN_START,
    REACTIVATE_ADMIN_START,
    UPDATE_ADMIN_START,
  ],
  successActions: [
    LIST_ADMINS_SUCCESS,
    FETCH_ADMIN_SUCCESS,
    CREATE_ADMIN_SUCCESS,
    DELETE_ADMIN_SUCCESS,
    REACTIVATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_SUCCESS,
  ],
  errorActions: [
    LIST_ADMINS_ERROR,
    FETCH_ADMIN_ERROR,
    CREATE_ADMIN_ERROR,
    DELETE_ADMIN_ERROR,
    REACTIVATE_ADMIN_ERROR,
    UPDATE_ADMIN_ERROR,
  ],
})(adminReducer);
