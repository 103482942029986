import * as React from 'react';
import { Route } from 'react-router-dom';

const Login = React.lazy(() => import('./Login'));
const PasswordReset = React.lazy(() => import('./PasswordReset'));
const UpdatePasswordFromCode = React.lazy(() =>
  import('./UpdatePasswordFromCode'),
);
const Enrollment = React.lazy(() => import('./Enrollment'));
const LoginAsAdmin = React.lazy(() => import('./LoginAsAdmin'));

export const authRoutes = [
  <Route
    exact
    key="code-password"
    path="/login/reset/:code"
    component={UpdatePasswordFromCode}
  />,
  <Route
    exact
    key="enrollment"
    path="/login/enrollment/:code"
    component={Enrollment}
  />,
  <Route exact key="reset" path="/login/reset" component={PasswordReset} />,
  <Route exact key="login" path="/login" component={Login} />,
  <Route
    exact
    key="as-admin"
    path="/login/as_admin"
    component={LoginAsAdmin}
  />,
];
