import {
  buildCreateApi,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { API_URI } from '../config';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URI,
    prepareHeaders: (headers, api) => {
      const accessToken =
        sessionStorage.getItem('access_token') ||
        localStorage.getItem('access_token');

      if (!headers.get('authorization') && accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: 'include',
  }),
  tagTypes: ['Setting', 'SystemEventHandler'],
  endpoints: builder => ({
    getSettings: builder.query<{ settings: Setting[] }, void>({
      query: () => ({
        url: `/admin/super/settings`,
      }),
      providesTags: ['Setting'],
    }),
    createSetting: builder.mutation<void, Setting>({
      query: body => ({
        method: 'POST',
        url: `/admin/super/settings`,
        body,
      }),
      invalidatesTags: (response, error) => (!error ? ['Setting'] : []),
    }),
    updateSetting: builder.mutation<
      void,
      Pick<Setting, 'id' | 'key' | 'value'>
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/admin/super/settings/${id}`,
        body,
      }),
      invalidatesTags: (response, error) => (!error ? ['Setting'] : []),
    }),
    getSystemEventHandlers: builder.query<
      {
        system_event_handlers: SystemEventHandlerAttributes[];
      },
      string
    >({
      query: organizationId => ({
        url: `/admin/organizations/${organizationId}/system_events/handlers`,
      }),
      providesTags: ['SystemEventHandler'],
    }),
    createSystemEventHandler: builder.mutation<
      void,
      Omit<
        SystemEventHandlerAttributes,
        'organization_id' | 'id' | 'created_at' | 'updated_at'
      > & {
        organizationId: string;
      }
    >({
      query: ({ organizationId, ...body }) => ({
        method: 'POST',
        url: `/admin/organizations/${organizationId}/system_events/handlers`,
        body,
      }),
      invalidatesTags: (response, error) =>
        !error ? ['SystemEventHandler'] : [],
    }),
    updateSystemEventHandler: builder.mutation<
      void,
      Partial<
        Omit<
          SystemEventHandlerAttributes,
          'organization_id' | 'created_at' | 'updated_at'
        >
      > & {
        id: SystemEventHandlerAttributes['id'];
        organizationId: string;
      }
    >({
      query: ({ organizationId, id, ...body }) => ({
        method: 'PUT',
        url: `/admin/organizations/${organizationId}/system_events/handlers/${id}`,
        body,
      }),
      invalidatesTags: (response, error) =>
        !error ? ['SystemEventHandler'] : [],
    }),
    deleteSystemEventHandler: builder.mutation<
      void,
      {
        id: number;
        organizationId: string;
      }
    >({
      query: ({ organizationId, id }) => ({
        method: 'DELETE',
        url: `/admin/organizations/${organizationId}/system_events/handlers/${id}`,
      }),
      invalidatesTags: (response, error) =>
        !error ? ['SystemEventHandler'] : [],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useCreateSettingMutation,
  useUpdateSettingMutation,

  useGetSystemEventHandlersQuery,
  useCreateSystemEventHandlerMutation,
  useUpdateSystemEventHandlerMutation,
  useDeleteSystemEventHandlerMutation,
} = api;

export type Setting = {
  id: number;
  key: string;
  value: unknown;
  created_at: string;
  updated_at: string;
};

interface Params {
  url: string;
  body: { [key: string]: Resolvable };
}

export const bureaus = ['Experian', 'TransUnion', 'Equifax'] as const;
export type Bureau = typeof bureaus[number];
export type ScoreChanges = {
  [bureau in Bureau]?: {
    previous: number;
    current: number;
  };
};

type SystemEventActions =
  | 'check_goals'
  | 'console_log'
  | 'email'
  | 'webhook'
  | 'sms';
export interface BaseSystemEventHandler<A extends SystemEventActions = any> {
  action: A;
  conditions: Condition[];
  parameters: any;
}

export interface SystemEventHandlerAttributes
  extends BaseSystemEventHandler<any> {
  id: number;
  organization_id?: number;
  action: 'check_goals' | 'console_log' | 'email' | 'webhook' | 'sms';
  description: string;
  event_type: string;
  conditions: Condition[];
  parameters: any;
  active: boolean;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export type Resolvable =
  | string
  | number
  | boolean
  | { ref: string }
  | { template: string }
  | { constant: any };

export const operators = {
  eq: 'Equal to',
  neq: 'Not equal to',
  gt: 'Greater than',
  gte: 'Greater than or Equal to',
  lt: 'Less than',
  lte: 'Less than or Equal to',
  in: 'In',
  nin: 'Not In',
  null: 'Null',
  nnull: 'Not Null',
} as const;
export type Operator = keyof typeof operators;
export interface Condition {
  left: Resolvable;
  operator: Operator;
  right?: Resolvable;
}
