import { createSelector } from 'reselect';
import { RootState } from '..';

export const getApiKey = (state: RootState) => state.apiKeys.item;
export const getApiKeys = (state: RootState) => state.apiKeys.items;
export const getApiKeysLoading = (state: RootState) => state.apiKeys.isLoading;
export const getApiKeysErrorMessage = (state: RootState) =>
  state.apiKeys.errorMessage;

export const selectApiKey = createSelector(getApiKey, template => template);
export const selectApiKeys = createSelector(getApiKeys, apiKeys => apiKeys);

export const selectApiKeysTotal = createSelector(
  (state: RootState) => state.apiKeys.total,
  total => total,
);
export const selectApiKeysLoading = createSelector(
  getApiKeysLoading,
  loading => loading,
);

export const selectApiKeysFetched = (state: RootState) =>
  state.apiKeys.isFetched;

export const selectApiKeysErrorMessage = createSelector(
  getApiKeysLoading,
  msg => msg,
);

export default {
  selectApiKey,
  selectApiKeys,
  selectApiKeysTotal,
  selectApiKeysLoading,
  selectApiKeysFetched,
  selectApiKeysErrorMessage,
};
