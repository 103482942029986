/* eslint-disable default-case */
import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  SET_USER_PASSWORD_START,
  SET_USER_PASSWORD_SUCCESS,
  SET_USER_PASSWORD_ERROR,
  DEACTIVATE_EXPERIAN_USER_START,
  DEACTIVATE_EXPERIAN_USER_SUCCESS,
  DEACTIVATE_EXPERIAN_USER_ERROR,
  REACTIVATE_EXPERIAN_USER_START,
  REACTIVATE_EXPERIAN_USER_SUCCESS,
  REACTIVATE_EXPERIAN_USER_ERROR,
  UPDATE_EXPERIAN_PRODUCT_TYPE_START,
  UPDATE_EXPERIAN_PRODUCT_TYPE_SUCCESS,
  UPDATE_EXPERIAN_PRODUCT_TYPE_ERROR,
  REPROCESS_DISPUTES_ALL_START,
  REPROCESS_DISPUTES_ALL_SUCCESS,
  REPROCESS_DISPUTES_ALL_ERROR,
} from '../constants/user.constants';
import {
  CANCEL_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
} from '../constants/subscriptions.constants';
import { CallAPIAsyncState, APIResponseMeta } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export type UserStatus =
  | 'AWAIT_GOALS'
  | 'AWAIT_PERSONAL_INFO'
  | 'AWAIT_CONFIRMATION'
  | 'AWAIT_EXPERIAN_THINLOAD'
  | 'AWAIT_CREDIT_ENROLLMENT'
  | 'AWAIT_PAYMENT'
  | 'KBA_FAILURE'
  | 'AWAIT_SIGNATURE'
  | 'ACTIVE'
  | 'SUSPEND'
  | 'CANCEL';
export interface User {
  id: string;
  member_id: string;
  email: string;
  confirmed_email: string;
  first_name: string;
  last_name: string;
  phone: string;
  confirmed_phone: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  ssn: string;
  dob: Date | string;
  password: string;
  status: UserStatus;
  db_email: string;
  reset_token: string;
  reset_token_sent_at: Date;
  enrollment_token: string;
  enrollment_token_sent_at: Date;
  sale_id: string;
  signature_id: string;
  idcs_status: string;
  idcs_modal: string;
  idcs_type: string;
  strategy: string;
  round: number;
  round_process_date: Date;
  program_status: string;
  customer_stripe_id: string;
  stripe_charge_id: string;
  stripe_plan: string;
  stripe_subscription_id: string;
  stripe_subscription_status: string;
  pull_fee: number;
  subscription_start_date: Date;
  sf_user_id: string;
  affiliate_code: string;
  referral_code: string;
  referral_processed: boolean;
  promo_code: string;
  affiliate_code_updated_at: Date;
  bypass_address_verification: boolean;
  mobile_confirmed_at: Date;
  recent_active_date: Date;
  first_active_date: Date;
  status_changed_at: Date;
  recent_nsf_date: Date;
  chargify_customer_id: string;
  chargify_plan_handle: string;
  chargify_payment_profile_id: string;
  chargify_subscription_status: string;
  chargify_subscription_id: string;
  cancel_reason: string;
  sendgrid_sync_pending: boolean;
  created_by_admin_id: number;
  current_organization_id: number;
  experian_product_type: number;
  experian_subscriber_number: string;
  experian_subscriber_status: boolean;
  experian_kba_status: string;
  experian_thinload_attempts: number;
  experian_question_attempts: number;
  dispute_program_status: string;
  esign_accepted_version: number;
  user_agreement_accepted_version: number;
  credibility_factor: number;
  target_score: number;
  target_score_reached_at?: Date;
  credit_report_fetched_at: Date;
  credit_camp_course_unlocked_at: Date;
  tokens_disbursed_at: Date;
  monthly_subscription_cost: number;
  experian_hash_migration_status: 'pending' | 'complete' | 'incomplete';
  created_at: Date;
  updated_at: Date;
  [key: string]: any;
}

export type UserStatuses =
  | 'AWAIT_GOALS'
  | 'AWAIT_PERSONAL_INFO'
  | 'AWAIT_CONFIRMATION'
  | 'AWAIT_CREDIT_ENROLLMENT'
  | 'AWAIT_PAYMENT'
  | 'KBA_FAILURE'
  | 'AWAIT_SIGNATURE'
  | 'ACTIVE'
  | 'SUSPEND'
  | 'CANCEL';

export type UserUpdateOptions = Partial<
  Pick<
    User,
    | 'first_name'
    | 'last_name'
    | 'phone'
    | 'email'
    | 'db_email'
    | 'address'
    | 'address2'
    | 'city'
    | 'state'
    | 'zip'
    | 'status'
    | 'dob'
    | 'ssn'
    | 'dispute_program_status'
    | 'target_score'
    | 'letter_delivery_method'
  >
> & { [key: string]: any };

export const ACTIVE_USER_STATUSES = ['ACTIVE', 'SUSPEND', 'CANCEL'];

export interface UsersState extends CallAPIAsyncState<User> {
  readonly editing: string;
  meta: Pick<APIResponseMeta, 'count'>;
}

const initialState: UsersState = {
  editing: '',
  ...INITIAL_ASYNC_STATE,
};

const userReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_USER_SUCCESS:
      case CANCEL_SUBSCRIPTION_SUCCESS:
      case REACTIVATE_SUBSCRIPTION_SUCCESS:
        draft.item = action.payload.user;
        return;
      case FETCH_USERS_SUCCESS:
        draft.items = action.payload.users;
        draft.meta = action.payload.meta;
        draft.isFetched = true;
        return;
      case CREATE_USER_SUCCESS:
        if (action.payload.user) {
          draft.items.unshift(action.payload.user);
        }
        return;
      case UPDATE_USER_SUCCESS:
      case UPDATE_EXPERIAN_PRODUCT_TYPE_SUCCESS:
      case DEACTIVATE_EXPERIAN_USER_SUCCESS:
      case REACTIVATE_EXPERIAN_USER_SUCCESS:
        draft.item = action.payload.user;
        draft.items[
          draft.items.findIndex(item => item.id === action.payload.user.id)
        ] = action.payload.user;
        return;
      case DELETE_USER_SUCCESS:
        draft.items.splice(
          draft.items.findIndex(element => element.id === action.id),
          1,
        );
        return;
    }
  },
  { ...initialState },
);

export default withLoading<UsersState>({
  isLoadingActions: [
    FETCH_USER_START,
    FETCH_USERS_START,
    CREATE_USER_START,
    UPDATE_USER_START,
    DELETE_USER_START,
    SET_USER_PASSWORD_START,
    DEACTIVATE_EXPERIAN_USER_START,
    REACTIVATE_EXPERIAN_USER_START,
    UPDATE_EXPERIAN_PRODUCT_TYPE_START,
    REPROCESS_DISPUTES_ALL_START,
  ],
  successActions: [
    FETCH_USER_SUCCESS,
    FETCH_USERS_SUCCESS,
    CREATE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    SET_USER_PASSWORD_SUCCESS,
    DEACTIVATE_EXPERIAN_USER_SUCCESS,
    REACTIVATE_EXPERIAN_USER_SUCCESS,
    UPDATE_EXPERIAN_PRODUCT_TYPE_SUCCESS,
    REPROCESS_DISPUTES_ALL_SUCCESS,
  ],
  errorActions: [
    FETCH_USER_ERROR,
    FETCH_USERS_ERROR,
    CREATE_USER_ERROR,
    UPDATE_USER_ERROR,
    DELETE_USER_ERROR,
    SET_USER_PASSWORD_ERROR,
    DEACTIVATE_EXPERIAN_USER_ERROR,
    REACTIVATE_EXPERIAN_USER_ERROR,
    UPDATE_EXPERIAN_PRODUCT_TYPE_ERROR,
    REPROCESS_DISPUTES_ALL_ERROR,
  ],
})(userReducer);
