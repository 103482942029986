/* eslint-disable default-case */
import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_ERROR,
} from '../constants/transaction.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface Transaction__c {
  attributes: {
    type: string;
    url: string;
  };
  Id: string;
  IsDeleted: boolean;
  Name: string;
  CreatedDate: Date;
  CreatedById: string;
  LastModifiedDate: Date;
  LastModifiedById: string;
  SystemModstamp: Date;
  LastViewedDate: Date;
  LastReferencedDate: Date;
  Account__c: string;
  Amount__c: number;
  Payment_Date__c: string;
  Payment_Method__c: string;
  Response_Date__c: Date;
  Response_Message__c: string;
  Response_Status__c: string;
  Chargify_Transaction_ID__c: number;
  Type__c: string;
  Unit__c: string;
  Response_Status_Approved__c: boolean;
  Full_Refund__c: boolean;
  Stripe_Charge_ID__c: string;
  Payment_Number__c: number;
  Refunded_Chargify_Transaction_ID__c: string;
  Refunded_Transaction__c: string;
}

export interface TransactionsById {
  [key: string]: Transaction__c;
}

export interface TransactionsState extends CallAPIAsyncState {
  readonly byId: TransactionsById;
}

const initialState: TransactionsState = {
  byId: {},
  ...INITIAL_ASYNC_STATE,
};

const transactionsReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_TRANSACTIONS_SUCCESS:
        draft.items = action.payload.Transaction__c;
        draft.byId = action.payload.Transaction__c.reduce(
          (
            transactionsById: TransactionsById,
            transaction: Transaction__c,
          ) => ({
            ...transactionsById,
            [transaction.Id]: transaction,
          }),
          {},
        );
        draft.isFetched = true;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<TransactionsState>({
  isLoadingActions: [FETCH_TRANSACTIONS_START],
  successActions: [FETCH_TRANSACTIONS_SUCCESS],
  errorActions: [FETCH_TRANSACTIONS_ERROR],
})(transactionsReducer);
