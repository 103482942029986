/* eslint-disable default-case */
import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';

import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

import {
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SIGNIN_START,
  SIGNIN_ERROR,
  SIGNIN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  CHECK_SESSION_START,
  CHECK_SESSION_SUCCESS,
  CHECK_SESSION_ERROR,
  UPDATE_CURRENT_ADMIN_START,
  UPDATE_CURRENT_ADMIN_SUCCESS,
  UPDATE_CURRENT_ADMIN_ERROR,
  UPDATE_PHONE_START,
  UPDATE_PHONE_SUCCESS,
  UPDATE_PHONE_ERROR,
  UPDATE_EMAIL_START,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  SET_ENROLLMENT_PASSWORD_SUCCESS,
  SET_ENROLLMENT_PASSWORD_START,
  SET_ENROLLMENT_PASSWORD_ERROR,
  FETCH_ADMIN_FROM_TOKEN_START,
  FETCH_ADMIN_FROM_TOKEN_SUCCESS,
  FETCH_ADMIN_FROM_TOKEN_ERROR,
} from '../constants/auth.constants';

import {
  LOGIN_AS_ADMIN_SUCCESS,
  LOGOUT_AS_ADMIN,
} from '../constants/admin.constants';

import { setAuthority, removeAuthority } from '../../utils/authority';
import { CallAPIAsyncState } from '../middleware/api';

export interface Admin {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  status: string;
  super: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface AuthState extends CallAPIAsyncState {
  admin: Admin;
  isAuthenticated: boolean;
  isLoggedInAsAdmin: boolean;
}

const initialState: AuthState = {
  admin: {} as Admin,
  isAuthenticated: false,
  isLoggedInAsAdmin: false,
  ...INITIAL_ASYNC_STATE,
};

const authReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case SIGNIN_SUCCESS:
      case CHECK_SESSION_SUCCESS:
      case UPDATE_CURRENT_ADMIN_SUCCESS:
      case UPDATE_PHONE_SUCCESS:
      case UPDATE_EMAIL_SUCCESS:
      case CHANGE_PASSWORD_SUCCESS:
      case UPDATE_PASSWORD_SUCCESS:
      case SET_ENROLLMENT_PASSWORD_SUCCESS:
      case FETCH_ADMIN_FROM_TOKEN_SUCCESS:
        if (action.payload.admin.role) {
          //setAuthority(action.payload.admin.role); // TODO(matt): update to handle real roles when ready - action.payload.admin.super ? 'super' : action.payload.meta.role
          setAuthority('super');
        }
        draft.admin = action.payload.admin;
        draft.isAuthenticated = true;
        draft.isFetched = true;
        return;
      case LOGIN_AS_ADMIN_SUCCESS:
        // setAuthority
        // draft.admin = action.payload.admin;
        draft.isLoggedInAsAdmin = true;
        return;
      case LOGOUT_AS_ADMIN:
        // removeAuthority();
        draft.admin = {} as Admin;
        draft.isLoggedInAsAdmin = false;
        return;
      case SIGN_OUT_SUCCESS:
      case SIGN_OUT_ERROR:
      case CHECK_SESSION_ERROR:
        sessionStorage.removeItem('access_token');
        removeAuthority();
        return initialState;
    }
  },

  { ...initialState },
);

export default withLoading<AuthState>({
  isLoadingActions: [
    RESET_PASSWORD_START,
    SIGNIN_START,
    UPDATE_PASSWORD_START,
    UPDATE_CURRENT_ADMIN_START,
    UPDATE_EMAIL_START,
    UPDATE_PHONE_START,
    CHANGE_PASSWORD_START,
    SET_ENROLLMENT_PASSWORD_START,
    FETCH_ADMIN_FROM_TOKEN_START,
    CHECK_SESSION_START,
  ],
  successActions: [
    RESET_PASSWORD_SUCCESS,
    SIGNIN_SUCCESS,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_CURRENT_ADMIN_SUCCESS,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_PHONE_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
    SET_ENROLLMENT_PASSWORD_SUCCESS,
    FETCH_ADMIN_FROM_TOKEN_SUCCESS,
    CHECK_SESSION_SUCCESS,
  ],
  errorActions: [
    RESET_PASSWORD_ERROR,
    SIGNIN_ERROR,
    UPDATE_PASSWORD_ERROR,
    RESET_PASSWORD_ERROR,
    UPDATE_CURRENT_ADMIN_ERROR,
    UPDATE_EMAIL_ERROR,
    UPDATE_PHONE_ERROR,
    CHANGE_PASSWORD_ERROR,
    SET_ENROLLMENT_PASSWORD_ERROR,
    FETCH_ADMIN_FROM_TOKEN_ERROR,
  ],
})(authReducer);
