/* eslint-disable default-case */
import produce from 'immer';
import { compareDesc } from 'date-fns';
import {
  FETCH_EMAIL_MESSAGES_START,
  FETCH_EMAIL_MESSAGES_SUCCESS,
  FETCH_EMAIL_MESSAGES_ERROR,
} from '../constants/message.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface EmailMessage {
  from_email: string;
  msg_id: string;
  subject: string;
  to_email: string;
  status: string;
  opens_count: number;
  clicks_count: number;
  last_event_time: Date;
}
export type EmailMessagesState = CallAPIAsyncState<EmailMessage>

const initialState: EmailMessagesState = {
  ...INITIAL_ASYNC_STATE,
};

const messageReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case FETCH_EMAIL_MESSAGES_SUCCESS:
        const { messages }: { messages: EmailMessage[] } = action.payload;
        draft.items = messages.sort((a, b) =>
          compareDesc(a.last_event_time, b.last_event_time),
        );
        draft.isFetched = true;
        return;
      case FETCH_EMAIL_MESSAGES_ERROR:
        draft.isFetched = true;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<EmailMessagesState>({
  isLoadingActions: [FETCH_EMAIL_MESSAGES_START],
  successActions: [FETCH_EMAIL_MESSAGES_SUCCESS],
  errorActions: [FETCH_EMAIL_MESSAGES_ERROR],
})(messageReducer);
