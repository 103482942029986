import { createSelector } from 'reselect';

import { RootState } from '..';

export const getSystemEventTypes = (state: RootState) =>
  state.systemEventTypes.types;
export const getSystemEventTargetModels = (state: RootState) =>
  state.systemEventTypes.models;
export const getSystemEventTypesLoading = (state: RootState) =>
  state.systemEventTypes.isLoading;
export const getSystemEventTypesFetched = (state: RootState) =>
  state.systemEventTypes.isFetched;

export const selectSystemEventTypes = createSelector(
  getSystemEventTypes,
  type => type,
);
export const selectSystemEventTargetModels = createSelector(
  getSystemEventTargetModels,
  models => models,
);
export const selectSystemEventTypesLoading = createSelector(
  getSystemEventTypesLoading,
  loading => loading,
);
export const selectSystemEventTypesFetched = createSelector(
  getSystemEventTypesFetched,
  fetched => fetched,
);

export default {
  selectSystemEventTypes,
  selectSystemEventTargetModels,
  selectSystemEventTypesFetched,
  selectSystemEventTypesLoading,
};
