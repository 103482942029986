import { createSelector } from 'reselect';
import { RootState } from '..';

export const getTemplate = (state: RootState) => state.templates.item;
export const getTemplates = (state: RootState) => state.templates.items;
export const getTemplatesLoading = (state: RootState) =>
  state.templates.isLoading;
export const getTemplatesErrorMessage = (state: RootState) =>
  state.templates.errorMessage;

export const getDeletedTemplates = (state: RootState) =>
  state.templates.deleted.items;

export const selectTemplate = createSelector(getTemplate, template => template);
export const selectTemplates = createSelector(
  getTemplates,
  templates => templates,
);

export const selectTemplatesTotal = createSelector(
  (state: RootState) => state.templates.total,
  total => total,
);
export const selectTemplatesLoading = createSelector(
  getTemplatesLoading,
  loading => loading,
);
export const selectTemplatesErrorMessage = createSelector(
  getTemplatesLoading,
  msg => msg,
);

export const selectDeletedTemplates = createSelector(
  getDeletedTemplates,
  templates => templates,
);
export const selectDeletedTemplatesTotal = createSelector(
  (state: RootState) => state.templates.deleted.total,
  total => total,
);

export default {
  selectTemplate,
  selectTemplates,
  selectTemplatesTotal,
  selectTemplatesLoading,
  selectTemplatesErrorMessage,

  selectDeletedTemplates,
  selectDeletedTemplatesTotal,
};
