import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_DISPUTED_ITEMS_START,
  FETCH_DISPUTED_ITEMS_SUCCESS,
  FETCH_DISPUTED_ITEMS_ERROR,
  UPDATE_DISPUTED_ITEMS_START,
  UPDATE_DISPUTED_ITEMS_SUCCESS,
  UPDATE_DISPUTED_ITEMS_ERROR,
} from '../constants/disputeditems.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface DisputeItemDetails {
  reportingBureau: 'TransUnion' | 'Equifax' | 'Experian';
  accountDetails: DisputeAccountDetails;
  accountOverview: DisputeAccountOverview;
}

export interface DisputeAccountOverview {
  'Original Creditor': string;
  'Pay Status': string;
  Responsibility: string;
  Remarks: string | [];
}

export interface DisputeAccountDetails {
  Balance: string;
  'Date Opened': string;
  'Date Reported': string;
  Type: string;
}

export type DisputedAccountStatus =
  | 'new'
  | 'removed'
  | 'pending'
  | 'repaired'
  | 'actionable';

interface DisputeByBureauProps {
  isDisputed: boolean;
  status: DisputedAccountStatus;
  itemId: number | string;
  isDisputable: boolean;
}

export const BUREAUS = ['Experian', 'Equifax', 'TransUnion'] as const;
export type Bureaus = typeof BUREAUS[number];
export interface DisputeHubAccount {
  accountId: string;
  hashId: string;
  accountName: string;
  Experian?: DisputeByBureauProps;
  TransUnion?: DisputeByBureauProps;
  Equifax?: DisputeByBureauProps;
  createdAt: string;
  updatedAt: string;
  accountStatus: DisputedAccountStatus;
  itemDetails: DisputeItemDetails;
  dispute_reason_id: number;
  [key: string]: any;
}

export type DisputedItemsBulkUpdate = Record<
  number,
  {
    disputed: boolean;
    disputed_reason_id?: string;
    status?: DisputeHubAccount['accountStatus'];
    is_disputable: boolean;
  }
>;

export type DisputedItemsState = CallAPIAsyncState & {
  byUserId: {
    [key: string]: DisputeHubAccount[];
  };
};

const initialState: DisputedItemsState = {
  ...INITIAL_ASYNC_STATE,
  byUserId: {},
};

const reducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_DISPUTED_ITEMS_SUCCESS:
        draft.byUserId[action.userId] = action.payload.disputed_items;
        draft.isFetched = true;
        return;
      case UPDATE_DISPUTED_ITEMS_SUCCESS:
        // This is the worst, but should be refactored down the road when all of this nonsense goes away
        const updates = action.payload
          .disputed_items as DisputedItemsBulkUpdate;
        const userId = Number(action.reducerKey); // TODO: this is a hack - i forget how to actually do this, so i'm passing the userId through reducerKey as the action has no reference to the user id this updated was done for
        for (const [itemId, update] of Object.entries(updates)) {
          for (const bureau of BUREAUS) {
            const item = draft.byUserId[userId].find(
              item => item[bureau]?.itemId === Number(itemId),
            );
            if (item) {
              item[bureau]!.isDisputed = update.disputed;
              item[bureau]!.isDisputable = update.is_disputable!;
            }
          }
        }
        return;
    }
  },
  { ...initialState },
);

export default withLoading<DisputedItemsState>({
  isLoadingActions: [FETCH_DISPUTED_ITEMS_START, UPDATE_DISPUTED_ITEMS_START],
  successActions: [FETCH_DISPUTED_ITEMS_SUCCESS, UPDATE_DISPUTED_ITEMS_SUCCESS],
  errorActions: [FETCH_DISPUTED_ITEMS_ERROR, UPDATE_DISPUTED_ITEMS_ERROR],
})(reducer);
