export const FETCH_SENDGRID_CAMPAIGNS_START = 'FETCH_SENDGRID_CAMPAIGNS_START';
export const FETCH_SENDGRID_CAMPAIGNS_SUCCESS =
  'FETCH_SENDGRID_CAMPAIGNS_SUCCESS';
export const FETCH_SENDGRID_CAMPAIGNS_ERROR = 'FETCH_SENDGRID_CAMPAIGNS_ERROR';

export const UPDATE_SENDGRID_CAMPAIGN_START = 'UPDATE_SENDGRID_CAMPAIGN_START';
export const UPDATE_SENDGRID_CAMPAIGN_SUCCESS =
  'UPDATE_SENDGRID_CAMPAIGN_SUCCESS';
export const UPDATE_SENDGRID_CAMPAIGN_ERROR = 'UPDATE_SENDGRID_CAMPAIGN_ERROR';

export const DELETE_SENDGRID_CAMPAIGN_START = 'DELETE_SENDGRID_CAMPAIGN_START';
export const DELETE_SENDGRID_CAMPAIGN_SUCCESS =
  'DELETE_SENDGRID_CAMPAIGN_SUCCESS';
export const DELETE_SENDGRID_CAMPAIGN_ERROR = 'DELETE_SENDGRID_CAMPAIGN_ERROR';

export const IMPORT_SENDGRID_CAMPAIGNS_START =
  'IMPORT_SENDGRID_CAMPAIGNS_START';
export const IMPORT_SENDGRID_CAMPAIGNS_SUCCESS =
  'IMPORT_SENDGRID_CAMPAIGNS_SUCCESS';
export const IMPORT_SENDGRID_CAMPAIGNS_ERROR =
  'IMPORT_SENDGRID_CAMPAIGNS_ERROR';

export const FETCH_REMOTE_SENDGRID_CAMPAIGNS_START =
  'FETCH_REMOTE_SENDGRID_CAMPAIGNS_START';
export const FETCH_REMOTE_SENDGRID_CAMPAIGNS_SUCCESS =
  'FETCH_REMOTE_SENDGRID_CAMPAIGNS_SUCCESS';
export const FETCH_REMOTE_SENDGRID_CAMPAIGNS_ERROR =
  'FETCH_REMOTE_SENDGRID_CAMPAIGNS_ERROR';

export default {
  FETCH_SENDGRID_CAMPAIGNS_START,
  FETCH_SENDGRID_CAMPAIGNS_SUCCESS,
  FETCH_SENDGRID_CAMPAIGNS_ERROR,
  UPDATE_SENDGRID_CAMPAIGN_START,
  UPDATE_SENDGRID_CAMPAIGN_SUCCESS,
  UPDATE_SENDGRID_CAMPAIGN_ERROR,
  DELETE_SENDGRID_CAMPAIGN_START,
  DELETE_SENDGRID_CAMPAIGN_SUCCESS,
  DELETE_SENDGRID_CAMPAIGN_ERROR,
  IMPORT_SENDGRID_CAMPAIGNS_START,
  IMPORT_SENDGRID_CAMPAIGNS_SUCCESS,
  IMPORT_SENDGRID_CAMPAIGNS_ERROR,
  FETCH_REMOTE_SENDGRID_CAMPAIGNS_START,
  FETCH_REMOTE_SENDGRID_CAMPAIGNS_SUCCESS,
  FETCH_REMOTE_SENDGRID_CAMPAIGNS_ERROR,
};
