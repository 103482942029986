import trim from 'lodash/trim';
import { get, pick } from 'lodash';

import authConstants, {
  CHECK_SESSION_START,
  CHECK_SESSION_SUCCESS,
  CHECK_SESSION_ERROR,
  SIGNIN_START,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  STORE_AUTH_TOKEN,
  SET_ENROLLMENT_PASSWORD_START,
  SET_ENROLLMENT_PASSWORD_SUCCESS,
  SET_ENROLLMENT_PASSWORD_ERROR,
  FETCH_ADMIN_FROM_TOKEN_START,
  FETCH_ADMIN_FROM_TOKEN_SUCCESS,
  FETCH_ADMIN_FROM_TOKEN_ERROR,
  SIGN_OUT_START,
  SIGN_OUT_SUCCESS,
} from '../constants/auth.constants';
import { Admin } from '../reducers/admin.reducer';
import {
  adminUpdateSchema,
  changePasswordSchema,
} from '../../schemas/admin.schema';

export const checkSession = () => {
  return {
    types: [CHECK_SESSION_START, CHECK_SESSION_SUCCESS, CHECK_SESSION_ERROR],
    method: 'get',
    path: `admin`,
  };
};

export const updatePassword = (
  code: string,
  values: { password: string; password_confirmation: string },
  successMessage?: string,
) => {
  sessionStorage.removeItem('access_token');

  return {
    types: [
      UPDATE_PASSWORD_START,
      UPDATE_PASSWORD_SUCCESS,
      UPDATE_PASSWORD_ERROR,
    ],
    method: 'put',
    path: `admin/password/reset`,
    payload: { reset_token: code, ...values },
    auth: true,
    successMessage,
  };
};

export const resetPassword = (email: string) => ({
  types: [RESET_PASSWORD_START, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR],
  method: 'post',
  path: `admin/password/reset`,
  payload: { email: trim(email) },
  successMessage: `You will receive an email with instructions on how to reset your password in a few minutes.`,
  auth: false,
});

export const signinUser = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => ({
  types: [SIGNIN_START, SIGNIN_SUCCESS, SIGNIN_ERROR],
  method: 'post',
  path: `admin/auth/token`,
  payload: {
    grant_type: 'password',
    client_id: 'web',
    username: trim(username).toLowerCase(),
    password,
  },
});

export const signOutUser = () => ({
  types: [SIGN_OUT_START, SIGN_OUT_SUCCESS, SIGN_OUT_SUCCESS], // just dispatch this no matter what to clear tokens and reset state.
  method: 'delete',
  path: `admin/auth`,
});

type StoreAuthTokenValues = {
  token: string;
  admin?: Admin;
  storageType?: typeof sessionStorage | typeof localStorage;
};
export const storeAuthToken = ({
  token,
  admin,
  storageType = localStorage,
}: StoreAuthTokenValues) => {
  storageType.setItem('access_token', token);

  if (storageType === sessionStorage) {
    localStorage.removeItem('access_token');
  }

  return {
    type: STORE_AUTH_TOKEN,
    admin,
  };
};

export const updateCurrentAdmin = (values: Admin) => {
  return {
    types: [
      authConstants.UPDATE_CURRENT_ADMIN_START,
      authConstants.UPDATE_CURRENT_ADMIN_SUCCESS,
      authConstants.UPDATE_CURRENT_ADMIN_ERROR,
    ],
    method: 'put',
    path: `admin`,
    payload: {
      ...pick(values, Object.keys(adminUpdateSchema.fields)),
      phone: get(values, 'phone').replace(/\D/g, ''),
    },
  };
};

export const updatePhone = (values: any) => {
  return {
    types: [
      authConstants.UPDATE_PHONE_START,
      authConstants.UPDATE_PHONE_SUCCESS,
      authConstants.UPDATE_PHONE_ERROR,
    ],
    method: 'put',
    path: `admin/phone`,
    payload: {
      phone: get(values, 'new_phone').replace(/\D/g, ''),
    },
  };
};

export const updateEmail = (values: any) => {
  return {
    types: [
      authConstants.UPDATE_EMAIL_START,
      authConstants.UPDATE_EMAIL_SUCCESS,
      authConstants.UPDATE_EMAIL_ERROR,
    ],
    method: 'put',
    path: `admin/email`,
    payload: {
      email: get(values, 'new_email'),
    },
  };
};

export const changePassword = (values: any) => {
  return {
    types: [
      authConstants.CHANGE_PASSWORD_START,
      authConstants.CHANGE_PASSWORD_SUCCESS,
      authConstants.CHANGE_PASSWORD_ERROR,
    ],
    method: 'put',
    path: `admin/password`,
    payload: {
      ...pick(values, Object.keys(changePasswordSchema.fields)),
    },
  };
};

export const fetchAdminFromEnrollmentToken = (token: string) => ({
  types: [
    FETCH_ADMIN_FROM_TOKEN_START,
    FETCH_ADMIN_FROM_TOKEN_SUCCESS,
    FETCH_ADMIN_FROM_TOKEN_ERROR,
  ],
  method: 'get',
  path: `admin/enrollments/${token}`,
});

export const updatePasswordFromEnrollment = (
  code: string,
  values: object,
  successMessage?: string,
) => ({
  types: [
    SET_ENROLLMENT_PASSWORD_START,
    SET_ENROLLMENT_PASSWORD_SUCCESS,
    SET_ENROLLMENT_PASSWORD_ERROR,
  ],
  method: 'post',
  path: `admin/enrollments/${code}`,
  payload: pick(values, 'password'),
  successMessage: successMessage || 'Your password was successfully updated',
});

export default {
  checkSession,
  signinUser,
  signOutUser,
  updatePassword,
  resetPassword,
  storeAuthToken,
  updateCurrentAdmin,
  changePassword,
  updateEmail,
  updatePhone,
  fetchAdminFromEnrollmentToken,
  updatePasswordFromEnrollment,
};
