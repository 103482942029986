export const FETCH_JOBS_START = 'FETCH_JOBS_START';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_ERROR = 'FETCH_JOBS_ERROR';

export const FETCH_JOB_TYPES_START = 'FETCH_JOB_TYPES_START';
export const FETCH_JOB_TYPES_SUCCESS = 'FETCH_JOB_TYPES_SUCCESS';
export const FETCH_JOB_TYPES_ERROR = 'FETCH_JOB_TYPES_ERROR';

export const CREATE_JOB_START = 'CREATE_JOB_START';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR';

export default {
  FETCH_JOBS_START,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  FETCH_JOB_TYPES_START,
  FETCH_JOB_TYPES_SUCCESS,
  FETCH_JOB_TYPES_ERROR,
  CREATE_JOB_START,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,
};
