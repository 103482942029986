import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import { compareAsc } from 'date-fns';
import {
  FETCH_REPORTS_START,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_ERROR,
} from '../constants/reports.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export const reportTypes = [
  'AdditionalDataTrackers',
  'AuthenticationEvent',
  'BillingReport',
  'BureauEnrollmentStatus',
  'CSICreditScoreReport',
  'CSIMinorMonitoringElements',
  'CSIMonitoringElements',
  'CreditFulfillmentFailure',
  'CreditMonitoringEvent',
  'CreditSummaryEvent',
  'CsiActiveStatus',
  'CsiEmailAlerts',
  'CsiWebActiveUpdate',
  'MinorActivationStatusReport',
  'MinorNonCreditAlert',
  'MinorSubscriberReport',
  'NonCreditAlerts',
  'NonCreditFulfillment',
  'NotificationPreferenceReport',
  'NotificationSummaryEvent',
  'PhoneCallReport',
  'ProductChangeEvent',
  'RealTimeAlertEvent',
  'RemovedMinorSubscriberReport',
  'RestorationCaseReport',
  'ScoreVarianceEvent',
  'SubscriberExternalIdentifier',
  'SubscriberReport',
  'SubscriberTransactionErrors',
  'csiCreditAlerts',
  'csiCreditReports',
  'csiCreditScoreReport',
] as const;

export type ReportTypes = typeof reportTypes[number];

export interface Report {
  id: number;
  type: ReportTypes;
  subscriber_number: string;
  data: any;
  ext_date: Date;
  created_at: Date;
  updated_at: Date;
}

export type CreditReportsState = CallAPIAsyncState & {
  byUserId: {
    [key: string]: Report[];
  };
};

const initialState: CreditReportsState = {
  ...INITIAL_ASYNC_STATE,
  byUserId: {},
};

const reportsReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_REPORTS_SUCCESS:
        const { reports }: { reports: Report[] } = action.payload;
        draft.byUserId[action.userId] = reports.sort((a, b) =>
          compareAsc(a.created_at, b.created_at),
        );
        draft.isFetched = true;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<CreditReportsState>({
  isLoadingActions: [FETCH_REPORTS_START],
  successActions: [FETCH_REPORTS_SUCCESS],
  errorActions: [FETCH_REPORTS_ERROR],
})(reportsReducer);
