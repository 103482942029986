/* eslint-disable default-case */
import produce from 'immer';

import {
  SET_CONTEXT_VARIABLES,
  CLEAR_CONTEXT_VARIABLES,
} from '../constants/config.constants';

export interface ContextVariable {
  text: string;
  displayText: string;
}

export interface ConfigState {
  contextVariables: ContextVariable[];
}

const initialState: ConfigState = {
  contextVariables: [],
};

const configReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case CLEAR_CONTEXT_VARIABLES:
        draft.contextVariables = [];
        return;

      case SET_CONTEXT_VARIABLES:
        draft.contextVariables = action.payload;
        return;
    }
  },

  { ...initialState },
);

export default configReducer;
