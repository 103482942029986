import { combineReducers, AnyAction } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import history from '../history';
import { default as credentials } from './auth.reducer';
import { default as organizations } from './organizationSlice';
import { default as admins } from './admin.reducer';
import { default as users } from './user.reducer';
import { default as transactions } from './transaction.reducer';
import { default as commissions } from './commission.reducer';
import { default as messages } from './message.reducer';
import { default as promoCodes } from './promo_code.reducer';
import { default as jobs } from './job.reducer';
import { default as sendgridCampaigns } from './sendgridcampaign.reducer';
import { default as creditReports } from './creditreports.reducer';
import { default as reports } from './reports.reducer';
import { default as marketing } from './marketing.reducer';
import { default as subscriptions } from './subscriptions.reducer';
import { default as systemEventTypes } from './systemeventtypes.reducer';
import { default as config } from './config.reducer';
import { default as templates } from './templates.reducer';
import { default as apiKeys } from './apikeys.reducer';
import { default as products } from '../../modules/products/productsSlice';
import { default as verifications } from '../../modules/goalkeeper/verificationsSlice';
import { default as notificationTemplates } from './notification_templates.reducer';
import { default as disputedItems } from './disputeditems.reducer';
import { SIGN_OUT_SUCCESS } from '../constants';
import { api } from '../api';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    config,
    credentials,
    organizations,
    admins,
    users,
    verifications,
    transactions,
    commissions,
    messages,
    promoCodes,
    jobs,
    sendgridCampaigns,
    creditReports,
    reports,
    marketing,
    products,
    subscriptions,
    systemEventTypes,
    templates,
    notificationTemplates,
    apiKeys,
    disputedItems,
    api: api.reducer,
  });

const appReducer = createRootReducer(history);

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === SIGN_OUT_SUCCESS) {
    state = undefined;
    // force an entry onto the stack, otherwise connected router could potentially redirect someone to the enrollment/pw reset/etc page instead of login
    window.history.pushState(null, 'Login | ScoreShuttle Admin', '/login');
  }

  return appReducer(state, action);
};

export default rootReducer;
