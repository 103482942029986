/* eslint-disable no-fallthrough */
import produce from 'immer';
import { compareDesc } from 'date-fns';

import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';
import {
  CREATE_CUSTOMER_START,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_ERROR,
  FETCH_SUBSCRIPTION_START,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_ERROR,
  FETCH_PAYMENT_PREVIEW_START,
  FETCH_PAYMENT_PREVIEW_SUCCESS,
  FETCH_PAYMENT_PREVIEW_ERROR,
  UPDATE_SUBSCRIPTION_START,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_PAYMENT_METHOD_START,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_ERROR,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  REACTIVATE_SUBSCRIPTION_START,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_ERROR,
  HOLD_SUBSCRIPTION_START,
  HOLD_SUBSCRIPTION_SUCCESS,
  HOLD_SUBSCRIPTION_ERROR,
  REMOVE_HOLD_START,
  REMOVE_HOLD_SUCCESS,
  REMOVE_HOLD_ERROR,
  FETCH_SUBSCRIPTION_PAYMENTS_START,
  FETCH_SUBSCRIPTION_PAYMENTS_SUCCESS,
  FETCH_PRODUCTS_SUCCESS,
} from '../constants/subscriptions.constants';
import { FETCH_USER_START } from '../constants';

interface SubscriptionState extends CallAPIAsyncState<Subscription> {
  products: {
    [key: string]: Product;
  };
  transactions: SubscriptionTransaction[];
  meta: {
    chargify_base_url: string;
  };
}

const initialState: SubscriptionState = {
  ...INITIAL_ASYNC_STATE,
  products: {},
  transactions: [],
  meta: {
    chargify_base_url: '',
  },
};

const subscriptionsReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case FETCH_SUBSCRIPTION_SUCCESS:
        draft.item = action.payload.subscription || {};
        draft.meta = action.payload.meta;
        draft.isFetched = true;
        return;
      case FETCH_SUBSCRIPTION_PAYMENTS_START:
        draft.transactions = [];
        return;
      case FETCH_SUBSCRIPTION_PAYMENTS_SUCCESS:
        const {
          transactions,
        }: { transactions: SubscriptionTransaction[] } = action.payload;
        draft.transactions = transactions.sort((a, b) =>
          compareDesc(a.updated_at, b.updated_at),
        );
        draft.isFetched = true;
        return;
      case FETCH_PRODUCTS_SUCCESS:
        draft.products = action.payload.products;
      case FETCH_SUBSCRIPTION_ERROR:
        draft.isFetched = true;
        return;
      case FETCH_SUBSCRIPTION_START:
        draft.isFetched = false;
        return;
      case FETCH_PAYMENT_PREVIEW_SUCCESS:
        // draft.subscriptionPreview = action.payload.subscription_preview;
        return;
      case UPDATE_PAYMENT_METHOD_SUCCESS:
        draft.item.credit_card = action.payload.payment_method;
        return;
      case UPDATE_SUBSCRIPTION_SUCCESS:
      case CANCEL_SUBSCRIPTION_SUCCESS:
      case REACTIVATE_SUBSCRIPTION_SUCCESS:
      case HOLD_SUBSCRIPTION_SUCCESS:
      case REMOVE_HOLD_SUCCESS:
        draft.isFetched = false;
        return;
      case FETCH_USER_START:
        draft.item = {};
        draft.isFetched = false;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<SubscriptionState>({
  isLoadingActions: [
    CREATE_CUSTOMER_START,
    FETCH_PAYMENT_PREVIEW_START,
    FETCH_SUBSCRIPTION_START,
    UPDATE_SUBSCRIPTION_START,
    UPDATE_PAYMENT_METHOD_START,
    CANCEL_SUBSCRIPTION_START,
    REACTIVATE_SUBSCRIPTION_START,
    HOLD_SUBSCRIPTION_START,
    REMOVE_HOLD_START,
  ],
  successActions: [
    CREATE_CUSTOMER_SUCCESS,
    FETCH_PAYMENT_PREVIEW_SUCCESS,
    FETCH_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    CANCEL_SUBSCRIPTION_SUCCESS,
    REACTIVATE_SUBSCRIPTION_SUCCESS,
    HOLD_SUBSCRIPTION_SUCCESS,
    REMOVE_HOLD_SUCCESS,
  ],
  errorActions: [
    CREATE_CUSTOMER_ERROR,
    FETCH_PAYMENT_PREVIEW_ERROR,
    FETCH_SUBSCRIPTION_ERROR,
    UPDATE_SUBSCRIPTION_ERROR,
    UPDATE_PAYMENT_METHOD_ERROR,
    CANCEL_SUBSCRIPTION_ERROR,
    REACTIVATE_SUBSCRIPTION_ERROR,
    HOLD_SUBSCRIPTION_ERROR,
    REMOVE_HOLD_ERROR,
  ],
})(subscriptionsReducer);

interface ProductFamily {
  readonly id: string;
  name: string;
  handle?: string;
  description?: string;
}

interface PublicSignupPages {
  readonly id: number;
  url: string;
}

export interface Product {
  readonly id: number;
  readonly created_at: Date;
  readonly updated_at: Date;
  readonly archived_at: Date;
  name: string;
  handle: string;
  description: string;
  account_code: string;
  price_in_cents: number;
  interval: number;
  interval_unit: 'month' | 'day';
  inital_charge_in_cents: null | number;
  expiration_interval: null | number;
  expiration_interval_unit: null | 'month' | 'day' | 'never';
  trial_price_in_cents: null | number;
  trial_interval: null | number;
  trial_interval_unit: 'month' | 'day';
  inital_charge_after_trial: boolean;
  return_params: string; // The params to be appended to the return_url (public_signup_pages only)
  request_credit_card: boolean;
  require_credit_card: boolean;
  update_return_url: string;
  update_return_params: string;
  product_family: ProductFamily;
  public_signup_pages: PublicSignupPages;
  taxable: boolean;
  version_number: number;
  amount: number; // NOTE(chris): Does this really exist?  It's used by the payment settings but wasn't defined here
  default_product_price_point_id: number;
  product_price_point_id: number;
  product_price_point_handle: string;
}

export interface Customer {
  readonly id: string;
  readonly created_at: Date;
  readonly updated_at: Date;
  first_name: string;
  last_name: string;
  email: string;
  cc_emails?: any;
  organization?: string;
  reference?: string;
  vat_number?: string;
  address?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zip?: number;
  country?: string;
  phone?: string;
  tax_exempt?: string;
}

export interface Subscription {
  readonly id?: string;
  readonly created_at?: Date;
  readonly updated_at?: Date;
  state?: SubscriptionStates;
  balance_in_cents?: number;
  current_billing_amount_in_cents?: number;
  total_revenue_in_cents?: number;
  product_price_in_cents?: number;
  product_version_number?: number;
  current_period_ends_at?: string;
  next_assessment_at?: string;
  trial_started_at?: null | string;
  trial_ended_at?: null | string;
  activated_at?: string;
  expires_at?: null | string;
  cancellation_message?: null | string;
  cancellation_method?: null | string;
  cancel_at_end_of_period?: boolean | null;
  canceled_at?: null | string;
  current_period_started_at?: string;
  previous_state?: string;
  signup_payment_id?: number;
  signup_revenue?: string;
  delayed_cancel_at?: null | string;
  coupon_code?: null | string;
  payment_collection_method?: string;
  snap_day?: null | number;
  reason_code?: null | string;
  customer?: Customer;
  product?: Product;
  credit_card?: CreditCard;
  payment_type?: string;
  referral_code?: string;
  next_product_id?: null | number;
  next_product_handle?: string;
  coupon_use_count?: number | null;
  coupon_uses_allowed?: number | null;
  automatically_resume_at?: Date;
}

export interface SubscriptionPreview {
  current_billing_manifest: BillingManifest;
  next_billing_manifest: BillingManifest;
}

export type SubscriptionStates =
  | 'active'
  | 'canceled'
  | 'expired'
  | 'expired_cards'
  | 'on_hold'
  | 'past_due'
  | 'pending_cancellation'
  | 'pending_renewal'
  | 'suspended'
  | 'trial_ended'
  | 'trialing'
  | 'unpaid';

export interface Subscription {
  readonly id?: string;
  readonly created_at?: Date;
  readonly updated_at?: Date;
  state?: SubscriptionStates;
  balance_in_cents?: number;
  current_billing_amount_in_cents?: number;
  total_revenue_in_cents?: number;
  product_price_in_cents?: number;
  product_version_number?: number;
  current_period_ends_at?: string;
  next_assessment_at?: string;
  trial_started_at?: null | string;
  trial_ended_at?: null | string;
  activated_at?: string;
  expires_at?: null | string;
  cancellation_message?: null | string;
  cancellation_method?: null | string;
  cancel_at_end_of_period?: boolean | null;
  canceled_at?: null | string;
  current_period_started_at?: string;
  previous_state?: string;
  signup_payment_id?: number;
  signup_revenue?: string;
  delayed_cancel_at?: null | string;
  coupon_code?: null | string;
  payment_collection_method?: string;
  snap_day?: null | number;
  reason_code?: null | string;
  customer?: Customer;
  product?: Product;
  credit_card?: CreditCard;
  payment_type?: string;
  referral_code?: string;
  next_product_id?: null | number;
  coupon_use_count?: number | null;
  coupon_uses_allowed?: number | null;
}

export interface Customer {
  readonly id: string;
  readonly created_at: Date;
  readonly updated_at: Date;
  first_name: string;
  last_name: string;
  email: string;
  cc_emails?: any;
  organization?: string;
  reference?: string;
  vat_number?: string;
  address?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zip?: number;
  country?: string;
  phone?: string;
  tax_exempt?: string;
}

export interface Product {
  readonly id: number;
  readonly created_at: Date;
  readonly updated_at: Date;
  readonly archived_at: Date;
  name: string;
  handle: string;
  description: string;
  account_code: string;
  price_in_cents: number;
  interval: number;
  interval_unit: 'month' | 'day';
  inital_charge_in_cents: null | number;
  expiration_interval: null | number;
  expiration_interval_unit: null | 'month' | 'day' | 'never';
  trial_price_in_cents: null | number;
  trial_interval: null | number;
  trial_interval_unit: 'month' | 'day';
  inital_charge_after_trial: boolean;
  return_params: string; // The params to be appended to the return_url (public_signup_pages only)
  request_credit_card: boolean;
  require_credit_card: boolean;
  update_return_url: string;
  update_return_params: string;
  product_family: ProductFamily;
  public_signup_pages: PublicSignupPages;
  taxable: boolean;
  version_number: number;
  amount: number; // NOTE(chris): Does this really exist?  It's used by the payment settings but wasn't defined here
  default_product_price_point_id: number;
  product_price_point_id: number;
  product_price_point_handle: string;
}

interface PublicSignupPages {
  readonly id: number;
  url: string;
}

interface ProductFamily {
  readonly id: string;
  name: string;
  handle?: string;
  description?: string;
}
interface BillingManifest {
  line_items: LineItem[];
  total_in_cents: number;
  total_discount_in_cents: number;
  total_tax_in_cents: number;
  subtotal_in_cents: number;
  start_date: Date;
  end_date: Date;
  period_type: string;
  existing_balance_in_cents: number;
}

interface LineItem {
  transaction_type: string;
  kind: string;
  amount_in_cents: number;
  memo: string;
  discount_amount_in_cents: number;
  taxable_amount_in_cents: number;
  component_id?: number;
}

export interface CreditCard {
  id: 21982014;
  first_name: string;
  last_name: string;
  masked_card_number: string;
  card_type: string;
  expiration_month: number;
  expiration_year: number;
  customer_id: number;
  current_vault: string;
  vault_token: string;
  payment_type: string;
  billing_zip: string;
  disabled: boolean;
  billing_address?: string;
  billing_city?: string;
  billing_state?: string;
  billing_country?: string;
  customer_vault_token?: string;
  billing_address_2?: string;
}

export interface SubscriptionTransaction {
  id: number;
  event: string;
  type: string;
  unit: string;
  amount: number;
  updated_at: Date;
}

export interface Product {
  product_id: string;
  price_points: PricePoint[];
}

export interface PricePoint {
  id: number;
  name: string;
  handle: string;
  product_id: number;
  price_in_cents: number;
  interval: number;
  interval_unit: string;
  trial_price_in_cents: number;
  trial_interval: number;
  trial_interval_unit: string;
  trial_type: string;
  initial_charge_in_cents: number;
  initial_charge_after_trial: boolean;
  expiration_interval?: any;
  expiration_interval_unit: string;
  archived_at?: any;
  created_at: Date;
  updated_at: Date;
}
