export function validateRole(value: string) {
  let error;
  if (!value) {
    error = 'Please select a role';
  }

  return error;
}

export default validateRole;
