import { AnyAction, Action } from 'redux';

export * from './loading-reducer/withLoading';
export * from './apiRequest';

// simple utils that can be reused in our slices
export const hasPrefix = (action: Action, prefix: string) =>
  action.type.startsWith(prefix); // takes a prefix, but would most often be a slice name
export const isPending = (action: AnyAction) =>
  action.type.endsWith('/pending');
export const isRejected = (action: Action) => action.type.endsWith('/rejected');
export const isFulfilled = (action: Action) =>
  action.type.endsWith('/fulfilled');

export const isPendingAction = (prefix: string) => (
  action: AnyAction,
): action is AnyAction => {
  return action.type.startsWith(prefix) && action.type.endsWith('/pending');
};
export const isFulfilledAction = (prefix: string) => (
  action: AnyAction,
): action is AnyAction => {
  return hasPrefix(action, prefix) && isFulfilled(action);
};
export const isRejectedAction = (prefix: string) => (
  action: AnyAction,
): action is AnyAction => {
  return hasPrefix(action, prefix) && isRejected(action);
};
