import { createSelector } from 'reselect';
import { RootState } from '..';

export const getReports = (state: RootState) => state.reports.items;
export const getReportsByUserId = (state: RootState) => state.reports.byUserId;

export const getReportsLoading = (state: RootState) => state.reports.isLoading;
export const getReportsSuccessMessage = (state: RootState) =>
  state.reports.successMessage;
export const getReportsErrorMessage = (state: RootState) =>
  state.reports.errorMessage;

export const selectReports = createSelector(getReports, Reports => {
  return Reports;
});
export const selectReportsLoading = createSelector(
  getReportsLoading,
  loading => loading,
);
export const selectReportsErrorMessage = createSelector(
  getReportsErrorMessage,
  message => message,
);
export const selectReportsSuccessMessage = createSelector(
  getReportsSuccessMessage,
  message => message,
);
export const selectReportsByUserId = createSelector(
  getReportsByUserId,
  reports => {
    return reports;
  },
);

export default {
  selectReports,
  selectReportsLoading,
  selectReportsErrorMessage,
  selectReportsSuccessMessage,

  selectReportsByUserId,
};
