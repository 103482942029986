import { createSelector } from 'reselect';
import { RootState } from '..';

export const getPromoCodes = (state: RootState) => state.promoCodes.items;
export const getPromoCodesLoading = (state: RootState) =>
  state.promoCodes.isLoading;
export const getPromoCodesFetched = (state: RootState) =>
  state.promoCodes.isFetched;
export const getPromoCodesSuccessMessage = (state: RootState) =>
  state.promoCodes.successMessage;
export const getPromoCodesErrorMessage = (state: RootState) =>
  state.promoCodes.errorMessage;

export const selectPromoCodes = createSelector(
  getPromoCodes,
  promoCodes => promoCodes,
);
export const selectPromoCodeSelectOptions = createSelector(
  getPromoCodes,
  promoCodes => {
    const result = [];

    for (const promoCode of promoCodes) {
      if (promoCode.code) {
        const discount = promoCode.amount_in_cents
          ? `$${(promoCode.amount_in_cents / 100).toFixed(2)}`
          : `${Math.round(Number(promoCode.percentage))}%`;

        result.push({
          label: `${promoCode.code} | Discount: ${discount}`,
          value: promoCode.code,
        });
      }
    }

    return [{ label: 'None', value: null }, ...result];
  },
);
export const selectPromoCodesLoading = createSelector(
  getPromoCodesLoading,
  loading => loading,
);
export const selectPromoCodesFetched = createSelector(
  getPromoCodesFetched,
  isFetched => isFetched,
);
export const selectPromoCodesErrorMessage = createSelector(
  getPromoCodesErrorMessage,
  message => message,
);
export const selectPromoCodesSuccessMessage = createSelector(
  getPromoCodesSuccessMessage,
  message => message,
);

export default {
  selectPromoCodes,
  selectPromoCodeSelectOptions,
  selectPromoCodesLoading,
  selectPromoCodesFetched,
  selectPromoCodesErrorMessage,
  selectPromoCodesSuccessMessage,
};
