import { createSelector } from 'reselect';
import { RootState } from '..';

export const getJobs = (state: RootState) => state.jobs.items;
export const getJobsLoading = (state: RootState) => state.jobs.isLoading;
export const getJobsSuccessMessage = (state: RootState) =>
  state.jobs.successMessage;
export const getJobsErrorMessage = (state: RootState) =>
  state.jobs.errorMessage;

export const selectJobs = createSelector(getJobs, jobs => {
  return jobs;
});
export const selectJobsTotal = createSelector(
  (state: RootState) => state.jobs.total,
  total => total,
);
export const selectJobTypes = createSelector(
  (state: RootState) => state.jobs.jobTypes,
  jobTypes => jobTypes,
);
export const selectJobsLoading = createSelector(
  getJobsLoading,
  loading => loading,
);
export const selectJobsErrorMessage = createSelector(
  getJobsErrorMessage,
  message => message,
);
export const selectJobsSuccessMessage = createSelector(
  getJobsSuccessMessage,
  message => message,
);

export default {
  selectJobs,
  selectJobsTotal,
  selectJobTypes,
  selectJobsLoading,
  selectJobsErrorMessage,
  selectJobsSuccessMessage,
};
