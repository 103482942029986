import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsAuthenticated,
  selectCurrentAdmin,
} from '../../store/selectors';
import { checkSession } from '../../store/actions/auth.actions';
import { LogRocket, usePrevious } from '../../utils';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const [resolved, setResolved] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectCurrentAdmin);
  const dispatch = useDispatch();
  const prevIsAuthenticated = usePrevious(isAuthenticated);

  useEffect(() => {
    const checkUser = async () => {
      await dispatch(checkSession());
    };

    const isAuthenticatedChanged =
      prevIsAuthenticated !== undefined &&
      isAuthenticated !== prevIsAuthenticated;

    // handle the edge case of when a user is logged in, then 401's on the next request
    if (isAuthenticatedChanged && !isAuthenticated) {
      setResolved(false);
      return;
    }

    if (!resolved) {
      if (!isAuthenticated) {
        checkUser().finally(() => {
          setResolved(true);
        });
      } else {
        setResolved(true);
      }
    }
  }, [isAuthenticated, prevIsAuthenticated, resolved, dispatch]);

  useEffect(() => {
    if (isAuthenticated && isAuthenticated !== prevIsAuthenticated) {
      LogRocket.identify(String(user.id), {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
    }
  }, [user, isAuthenticated, prevIsAuthenticated]);

  return !resolved ? null : (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
