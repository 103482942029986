import { createSelector } from 'reselect';
import { RootState } from '..';

const getConfigContextVariables = (state: RootState) =>
  state.config.contextVariables;
export const selectConfigContextVariables = createSelector(
  getConfigContextVariables,
  vars => vars,
);

export default {
  selectConfigContextVariables,
};
