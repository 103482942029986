import { css } from '@emotion/react';
import React, { PureComponent } from 'react';

const styles = css`
  .main {
    width: 100%;
    height: 100%;
    min-height: 100%;
    transition: 0.3s;
    &.wide {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
`;

interface Props {
  contentWidth?: string;
}

class GridContent extends PureComponent<Props> {
  render() {
    const { contentWidth, children } = this.props;
    let className = `main`;
    if (contentWidth === 'Fixed') {
      className = `main wide`;
    }
    return (
      <div css={styles} className={className}>
        {children}
      </div>
    );
  }
}

export default GridContent;
