import * as yup from 'yup';

export const organizationUpdateSchema: any = yup.object({
  name: yup.string().required(),
  subdomain: yup
    .string()
    .required()
    .min(3),
  sfid: yup.string(),
  default_user_target_score: yup
    .number()
    .min(300)
    .max(850),
  default_monthly_product_handle: yup.string(),
  default_prepay_6_months_product_handle: yup.string(),
  // default_prepay_12_months_product_handle: yup.string(),
  default_promo_code: yup.string(),
  allowed_product_handles: yup.array().of(yup.string()),
});
