export const LIST_ADMINS_START = 'LIST_ADMINS_START';
export const LIST_ADMINS_SUCCESS = 'LIST_ADMINS_SUCCESS';
export const LIST_ADMINS_ERROR = 'LIST_ADMINS_ERROR';

export const FETCH_ADMIN_START = 'FETCH_ADMIN_START';
export const FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS';
export const FETCH_ADMIN_ERROR = 'FETCH_ADMIN_ERROR';

export const CREATE_ADMIN_START = 'CREATE_ADMIN_START';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_ERROR = 'CREATE_ADMIN_ERROR';

export const UPDATE_ADMIN_START = 'UPDATE_ADMIN_START';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';

export const DELETE_ADMIN_START = 'DELETE_ADMIN_START';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';

export const REACTIVATE_ADMIN_START = 'REACTIVATE_ADMIN_START';
export const REACTIVATE_ADMIN_SUCCESS = 'REACTIVATE_ADMIN_SUCCESS';
export const REACTIVATE_ADMIN_ERROR = 'REACTIVATE_ADMIN_ERROR';

export const LOGIN_AS_ADMIN_START = 'LOGIN_AS_ADMIN_START';
export const LOGIN_AS_ADMIN_SUCCESS = 'LOGIN_AS_ADMIN_SUCCESS';
export const LOGIN_AS_ADMIN_ERROR = 'LOGIN_AS_ADMIN_ERROR';

export const LOGOUT_AS_ADMIN = 'LOGOUT_AS_ADMIN';

export default {
  LIST_ADMINS_START,
  LIST_ADMINS_SUCCESS,
  LIST_ADMINS_ERROR,
  FETCH_ADMIN_START,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_ERROR,
  CREATE_ADMIN_START,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_ERROR,
  UPDATE_ADMIN_START,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_ERROR,
  DELETE_ADMIN_START,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_ERROR,
  REACTIVATE_ADMIN_START,
  REACTIVATE_ADMIN_SUCCESS,
  REACTIVATE_ADMIN_ERROR,
  LOGIN_AS_ADMIN_START,
  LOGIN_AS_ADMIN_SUCCESS,
  LOGIN_AS_ADMIN_ERROR,
  LOGOUT_AS_ADMIN,
};
