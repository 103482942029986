import { createSelector } from 'reselect';
import { RootState } from '..';
import { format } from 'date-fns';
import countBy from 'lodash/countBy';
import sortBy from 'lodash/sortBy';
import { User, ACTIVE_USER_STATUSES } from '../reducers/user.reducer';
import { getAdminsById } from './admin.selectors';
import { AdminsById } from '../reducers/admin.reducer';
import { TotalByTitleDataPoint } from '../../modules/dashboard/ui/SalesPanel';

export const getUser = (state: RootState) => state.users.item;
export const getUsers = (state: RootState) => state.users.items;
export const getUsersLoading = (state: RootState) => state.users.isLoading;
export const getUsersSuccessMessage = (state: RootState) =>
  state.users.successMessage;
export const getUsersErrorMessage = (state: RootState) =>
  state.users.errorMessage;

export const selectUser = createSelector(getUser, user => user);

export const selectUserIsTypeOfActive = createSelector(
  getUser,
  (user: User) => {
    if (ACTIVE_USER_STATUSES.includes(user.status)) {
      return true;
    }
    return false;
  },
);
export const selectUsers = createSelector(getUsers, users => users);
export const selectCountUsersByDay = createSelector(
  getUsers,
  (users: User[]) => {
    const grouped = countBy(users, user =>
      format(user.created_at, 'YYYY-MM-DD'),
    );

    const dates = sortBy(Object.keys(grouped), (date: string) => {
      return new Date(date);
    });
    const result = dates.map((date: string) => {
      return {
        x: date,
        y: grouped[date],
      };
    });

    return result;
  },
);

export const selectCountUsersByAdminId = createSelector(
  [getUsers, getAdminsById],
  (users: User[], admins: AdminsById) => {
    const countByAdminId = countBy(users, 'created_by_admin_id');

    const result: TotalByTitleDataPoint[] = [];
    for (const [id, value] of Object.entries(countByAdminId)) {
      const adminRef = admins[id];
      if (adminRef) {
        result.push({
          title: `${adminRef.first_name} ${adminRef.last_name}`,
          total: value,
        });
      }
    }
    return result;
  },
);

export const selectUsersLoading = createSelector(
  getUsersLoading,
  loading => loading,
);
export const selectUsersErrorMessage = createSelector(
  getUsersErrorMessage,
  message => message,
);
export const selectUsersSuccessMessage = createSelector(
  getUsersSuccessMessage,
  message => message,
);

export default {
  selectUser,
  selectUsers,
  selectUsersLoading,
  selectUsersErrorMessage,
  selectUsersSuccessMessage,
  selectCountUsersByAdminId,
  selectCountUsersByDay,
};
