import { default as templatesConstants } from '../constants/templates.constants';
import { SearchQuery } from '../middleware/api';
import { TemplateUpdateValues } from '../reducers/templates.reducer';

export const fetchTemplates = (search?: SearchQuery) => {
  return {
    types: [
      templatesConstants.FETCH_TEMPLATES_START,
      templatesConstants.FETCH_TEMPLATES_SUCCESS,
      templatesConstants.FETCH_TEMPLATES_ERROR,
    ],
    method: 'get',
    path: `admin/super/templates`,
    search,
  };
};

export const fetchDeletedTemplates = (search?: SearchQuery) => {
  return {
    types: [
      templatesConstants.FETCH_DELETED_TEMPLATES_START,
      templatesConstants.FETCH_DELETED_TEMPLATES_SUCCESS,
      templatesConstants.FETCH_DELETED_TEMPLATES_ERROR,
    ],
    method: 'get',
    path: `admin/super/templates/deleted`,
    search,
  };
};

export const fetchTemplate = (templateId?: number | string) => {
  return {
    types: [
      templatesConstants.FETCH_TEMPLATE_START,
      templatesConstants.FETCH_TEMPLATE_SUCCESS,
      templatesConstants.FETCH_TEMPLATE_ERROR,
    ],
    method: 'get',
    path: `admin/super/templates/${templateId}`,
  };
};

export const updateTemplate = (
  id: number | string,
  values: Partial<TemplateUpdateValues>,
) => {
  return {
    types: [
      templatesConstants.UPDATE_TEMPLATE_START,
      templatesConstants.UPDATE_TEMPLATE_SUCCESS,
      templatesConstants.UPDATE_TEMPLATE_ERROR,
    ],
    method: 'put',
    path: `admin/super/templates/${id}`,
    payload: values,
  };
};

export const createTemplate = (values: Partial<TemplateUpdateValues>) => {
  return {
    types: [
      templatesConstants.CREATE_TEMPLATE_START,
      templatesConstants.CREATE_TEMPLATE_SUCCESS,
      templatesConstants.CREATE_TEMPLATE_ERROR,
    ],
    method: 'post',
    path: `admin/super/templates`,
    payload: values,
  };
};

export const deleteTemplate = (templateId: string | number) => {
  return {
    types: [
      templatesConstants.DELETE_TEMPLATE_START,
      templatesConstants.DELETE_TEMPLATE_SUCCESS,
      templatesConstants.DELETE_TEMPLATE_ERROR,
    ],
    method: 'delete',
    path: `admin/super/templates/${templateId}`,
    payload: {
      id: templateId,
    },
  };
};

export const restoreTemplate = (templateId: string | number) => {
  return {
    types: [
      templatesConstants.RESTORE_TEMPLATE_START,
      templatesConstants.RESTORE_TEMPLATE_SUCCESS,
      templatesConstants.RESTORE_TEMPLATE_ERROR,
    ],
    method: 'post',
    path: `admin/super/templates/${templateId}/restore`,
    payload: {},
  };
};

export default {
  fetchTemplate,
  fetchTemplates,
  updateTemplate,
  createTemplate,
  deleteTemplate,
  fetchDeletedTemplates,
};
