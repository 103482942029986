import * as yup from 'yup';
import moment from 'moment';
import { phoneRegExp, invalidEmail } from './common';

export const userStatuses = [
  'AWAIT_GOALS',
  'AWAIT_PERSONAL_INFO',
  'AWAIT_CONFIRMATION',
  'AWAIT_CREDIT_ENROLLMENT',
  'AWAIT_PAYMENT',
  'KBA_FAILURE',
  'AWAIT_SIGNATURE',
  'ACTIVE',
  'SUSPEND',
  'CANCEL',
];
export const disputeProgramStatuses = [
  'FREE',
  'UPGRADING',
  'SELECT_DISPUTES',
  'ACTIVE',
];

export const userDeliveryMethod = ['AUTOMATIC', 'HOME_DELIVERY', 'EMAIL'];

export const userSchema: any = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  email: yup
    .string()
    .email(invalidEmail)
    .required(),
  db_email: yup.string(),
  status: yup.string().oneOf(userStatuses),
  address: yup.string(),
  address2: yup.string(),
  city: yup.string(),
  state: yup
    .string()
    .matches(
      /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/,
    ),
  zip: yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/),
  ssn: yup
    .mixed()
    .oneOf([
      yup.string().matches(/^\d{3}-?\d{2}-?\d{4}$/),
      yup.string().matches(/^\d{4}/),
    ]),
  dob: yup.string().matches(/^\d{2}\/\d{2}\/\d{4}$/),
  affiliate_code: yup.string(),
  referral_code: yup.string(),
  dispute_program_status: yup.string().oneOf(disputeProgramStatuses),
  target_score: yup
    .number()
    .min(300)
    .max(850),
  idcs_type: yup.string(),
  organization_name: yup.string(),
});

export const addUserSchema: any = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  email: yup
    .string()
    .email(invalidEmail)
    .required(),
  status: yup.string().oneOf(userStatuses),
  address: yup.string(),
  address2: yup.string(),
  db_email: yup.string(),
  city: yup.string(),
  state: yup
    .string()
    .matches(
      /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/,
    ),
  zip: yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/),
  payment_zip: yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/),
  ssn: yup
    .mixed()
    .test('ssn-test', 'SSN must be last 4 or ###-##-####', (value: string) => {
      if (
        value &&
        !(value.match(/^\d{3}-?\d{2}-?\d{4}$/) || value.match(/^\d{4}/))
      ) {
        return false;
      }
      return true;
    }),
  dob: yup.string().matches(/^\d{2}\/\d{2}\/\d{4}$/),
  number: yup
    .string()
    .test(
      'number-test',
      'Card number must be at least 15 digits',
      (value: string) => {
        if (value && !/^\d{15,16}$/.test(String(value).replace(/\D/g, ''))) {
          return false;
        }
        return true;
      },
    ),
  cvv: yup.string().matches(/\d{3,4}/),
  exp_date: yup
    .string()
    .test(
      'date-test',
      'Expiration date must be valid and in the future.',
      (value: string) => {
        const date = moment(value, 'MMYY');
        if (
          value &&
          (!/^\d{4}$/.test(value.replace(/\D/g, '')) ||
            !date.isValid() ||
            date <= moment())
        ) {
          return false;
        }
        return true;
      },
    ),
  productHandle: yup.string(),
  affiliate_code: yup.string(),
  referral_code: yup.string(),
  promo_code: yup.string(),
  dispute_program_status: yup.string().oneOf(disputeProgramStatuses),
  target_score: yup
    .number()
    .min(300)
    .max(850),
});

export const userUpdateSchema: any = userSchema;

export const paymentMethodSchema: any = yup.object().shape({
  payment_zip: yup
    .string()
    .required()
    .test('zip-test', 'Zip code must be 5 digits', (value: string) => {
      if (value && /^\d{5}$/.test(String(value))) {
        return true;
      }
      return false;
    }),
  number: yup
    .string()
    .required()
    .test(
      'number-test',
      'Card number must be at least 15 digits',
      (value: string) => {
        if (value && !/^\d{15,16}$/.test(String(value).replace(/\D/g, ''))) {
          return false;
        }
        return true;
      },
    ),
  cvv: yup
    .string()
    .required()
    .test('cvv-test', 'cvv code must be 3 or 4 digits', (value: string) => {
      if (value && /\d{3,4}/.test(String(value))) {
        return true;
      }
      return false;
    }),
  exp_date: yup
    .string()
    .required()
    .test(
      'date-test',
      'Expiration date must be valid and in the future.',
      (value: string) => {
        const date = moment(value, 'MMYY');
        if (
          value &&
          (!/^\d{4}$/.test(value.replace(/\D/g, '')) ||
            !date.isValid() ||
            date <= moment())
        ) {
          return false;
        }
        return true;
      },
    ),
});
