import React, { useState } from 'react';
import { UserAddOutlined } from '@ant-design/icons';
import { Button, Modal, Collapse, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useSelector, useDispatch } from 'react-redux';
import { AddUserModal } from '../users/components/AddUserModal';
import { selectUsersLoading } from '../../store/selectors';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { selectCurrentOrganization } from '../../store/reducers/organizationSlice';

const { Paragraph, Text } = Typography;

interface AddSubscriberModalButtonProps {
  buttonProps?: ButtonProps;
}
const AddSubscriberModalButton: React.FC<AddSubscriberModalButtonProps> = ({
  buttonProps,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const usersLoading = useSelector(selectUsersLoading);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const location = useLocation();
  const dispatch = useDispatch();

  if (
    !currentOrganization.subdomain ||
    location.pathname.includes('/organizations')
  ) {
    return null;
  }

  return (
    <>
      <Button
        icon={<UserAddOutlined />}
        loading={usersLoading}
        onClick={() => setModalVisible(true)}
        {...buttonProps}
      >
        New Subscriber
      </Button>

      {modalVisible && (
        <AddUserModal
          visible={modalVisible}
          maskClosable={!usersLoading}
          closable={!usersLoading}
          onSubmit={values => {
            let modalConfirmationType = Modal.success;
            let modalTitle = `Subscriber successfully added`;
            const nextStepsContent = `The subscriber will recieve a text message and email in just a
          moment with a link to finish setting up their account. After
          they finish the enrollment process, we'll notify you when
          they reach their goals.`;
            let modalContent = (
              <div>
                <Paragraph>{nextStepsContent}</Paragraph>
                <Paragraph>
                  <a
                    onClick={() => {
                      dispatch(
                        push(
                          `/${currentOrganization.subdomain}/subscribers/${values.user_id}/details`,
                        ),
                      );
                      Modal.destroyAll();
                    }}
                  >
                    View user profile
                  </a>
                </Paragraph>
              </div>
            );
            if (values.errors?.length > 0) {
              modalConfirmationType = Modal.warning;
              modalTitle = `Subscriber added, but there was an issue`;
              modalContent = (
                <div>
                  <Paragraph>{nextStepsContent}</Paragraph>
                  <Paragraph>
                    <Collapse bordered={false}>
                      <CollapsePanel header="Show error information" key="1">
                        <Paragraph type="secondary">
                          The following subscription-related error
                          {values.errors.length > 1 ? 's' : ''} occured during
                          account provisioning. The subscriber will be prompted
                          to resolve this during enrollment.
                        </Paragraph>
                        <Paragraph>
                          <ul>
                            {values.errors.map((err, idx) => (
                              <li key={`${idx}-err`}>
                                {' '}
                                <Text type="secondary">{err}</Text>
                              </li>
                            ))}
                          </ul>
                        </Paragraph>
                      </CollapsePanel>
                    </Collapse>
                  </Paragraph>
                  <Paragraph>
                    <a
                      onClick={() => {
                        dispatch(
                          push(
                            `/${currentOrganization.subdomain}/subscribers/${values.user_id}/details`,
                          ),
                        );
                        Modal.destroyAll();
                      }}
                    >
                      View user profile
                    </a>
                  </Paragraph>
                </div>
              );
            }
            modalConfirmationType({
              title: modalTitle,
              content: modalContent,
              maskClosable: true,
              okText: 'Close',
              width: 500,
            });

            setModalVisible(false);
          }}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </>
  );
};

export default AddSubscriberModalButton;
