export const FETCH_CREDIT_REPORTS_START = 'FETCH_CREDIT_REPORTS_START';
export const FETCH_CREDIT_REPORTS_SUCCESS = 'FETCH_CREDIT_REPORTS_SUCCESS';
export const FETCH_CREDIT_REPORTS_ERROR = 'FETCH_CREDIT_REPORTS_ERROR';
export const FETCH_CREDIT_REPORT_MONITORING_URL_START =
  'FETCH_CREDIT_REPORT_MONITORING_URL_START';
export const FETCH_CREDIT_REPORT_MONITORING_URL_SUCCESS =
  'FETCH_CREDIT_REPORT_MONITORING_URL_SUCCESS';
export const FETCH_CREDIT_REPORT_MONITORING_URL_ERROR =
  'FETCH_CREDIT_REPORT_MONITORING_URL_ERROR';

export const FETCH_IDCS_REPORT_URL_START = 'FETCH_IDCS_REPORT_URL_START';
export const FETCH_IDCS_REPORT_URL_SUCCESS = 'FETCH_IDCS_REPORT_URL_SUCCESS';
export const FETCH_IDCS_REPORT_URL_ERROR = 'FETCH_IDCS_REPORT_URL_ERROR';

export const ORDER_EXPERIAN_REPORT_START = 'ORDER_EXPERIAN_REPORT_START';
export const ORDER_EXPERIAN_REPORT_SUCCESS = 'ORDER_EXPERIAN_REPORT_SUCCESS';
export const ORDER_EXPERIAN_REPORT_ERROR = 'ORDER_EXPERIAN_REPORT_ERROR';

export const ORDER_CREDIT_SCORE_START = 'ORDER_CREDIT_SCORE_START';
export const ORDER_CREDIT_SCORE_SUCCESS = 'ORDER_CREDIT_SCORE_SUCCESS';
export const ORDER_CREDIT_SCORE_ERROR = 'ORDER_CREDIT_SCORE_ERROR';

export default {
  FETCH_CREDIT_REPORTS_START,
  FETCH_CREDIT_REPORTS_SUCCESS,
  FETCH_CREDIT_REPORTS_ERROR,
  FETCH_CREDIT_REPORT_MONITORING_URL_START,
  FETCH_CREDIT_REPORT_MONITORING_URL_SUCCESS,
  FETCH_CREDIT_REPORT_MONITORING_URL_ERROR,
  FETCH_IDCS_REPORT_URL_START,
  FETCH_IDCS_REPORT_URL_SUCCESS,
  FETCH_IDCS_REPORT_URL_ERROR,
  ORDER_EXPERIAN_REPORT_START,
  ORDER_EXPERIAN_REPORT_SUCCESS,
  ORDER_EXPERIAN_REPORT_ERROR,
  ORDER_CREDIT_SCORE_START,
  ORDER_CREDIT_SCORE_SUCCESS,
  ORDER_CREDIT_SCORE_ERROR,
};
