import * as yup from 'yup';

export const passwordNotLongEnough = 'Password must be at least 8 characters';
export const invalidEmail = 'Email must be a valid email';
export const invalidLogin = 'Invalid login credentials';

export const validPassword = yup
  .string()
  .min(8, passwordNotLongEnough)
  .max(255)
  .required();

export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const passwordResetSchema = yup.object().shape({
  email: yup
    .string()
    .email('You must enter an email')
    .required(),
});

export const strongPasswordSchema = yup
  .string()
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
    'Password must have at least six characters and include one letter, one number, and one special character',
  );

export const passwordUpdateSchema = yup.object({
  password: validPassword,
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Passwords must match'),
});

export const adminPasswordUpdateSchema = yup.object({
  password: strongPasswordSchema,
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Passwords must match'),
});
