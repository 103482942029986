export default function moneyFormatter(
  amount: number | string,
  showChange?: boolean,
) {
  if (amount == null || amount === '') {
    return '';
  }
  if (typeof amount === 'string') {
    amount = amount.replace(/[$,]/g, '').trim();
    if (amount.length === 0) {
      return 'N/A';
    }
    amount = Number(amount);
  }
  if (isNaN(amount) || Math.abs(amount) === Infinity) {
    return 'N/A';
  }

  const negativeSign = amount < 0 ? '-' : '';
  amount = Math.abs(amount);
  const decimalPlaces = showChange ? 2 : 0;
  const dollars = parseInt(amount.toFixed(decimalPlaces)).toString();
  const commas = dollars.length > 3 ? dollars.length % 3 : 0;

  return (
    negativeSign +
    '$' +
    (commas ? dollars.substr(0, commas) + ',' : '') +
    dollars.substr(commas).replace(/(\d{3})(?=\d)/g, '$1,') +
    (decimalPlaces
      ? '.' +
        Math.abs(amount - Number(dollars))
          .toFixed(decimalPlaces)
          .slice(2)
      : '')
  );
}
