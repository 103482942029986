export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const LOGIN_AS_USER_START = 'LOGIN_AS_USER_START';
export const LOGIN_AS_USER_SUCCESS = 'LOGIN_AS_USER_SUCCESS';
export const LOGIN_AS_USER_ERROR = 'LOGIN_AS_USER_ERROR';

export const FETCH_USER_MESSAGES_START = 'FETCH_USER_MESSAGES_START';
export const FETCH_USER_MESSAGES_SUCCESS = 'FETCH_USER_MESSAGES_SUCCESS';
export const FETCH_USER_MESSAGES_ERROR = 'FETCH_USER_MESSAGES_ERROR';

export const FETCH_USER_SMS_MESSAGES_START = 'FETCH_USER_SMS_MESSAGES_START';
export const FETCH_USER_SMS_MESSAGES_SUCCESS =
  'FETCH_USER_SMS_MESSAGES_SUCCESS';
export const FETCH_USER_SMS_MESSAGES_ERROR = 'FETCH_USER_SMS_MESSAGES_ERROR';

export const SET_USER_PASSWORD_START = 'SET_USER_PASSWORD_START';
export const SET_USER_PASSWORD_SUCCESS = 'SET_USER_PASSWORD_SUCCESS';
export const SET_USER_PASSWORD_ERROR = 'SET_USER_PASSWORD_ERROR';

export const CHECK_EMAIL_START = 'CHECK_EMAIL_START';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_ERROR = 'CHECK_EMAIL_ERROR';

export const DEACTIVATE_EXPERIAN_USER_START = 'DEACTIVATE_EXPERIAN_USER_START';
export const DEACTIVATE_EXPERIAN_USER_SUCCESS =
  'DEACTIVATE_EXPERIAN_USER_SUCCESS';
export const DEACTIVATE_EXPERIAN_USER_ERROR = 'DEACTIVATE_EXPERIAN_USER_ERROR';

export const REACTIVATE_EXPERIAN_USER_START = 'REACTIVATE_EXPERIAN_USER_START';
export const REACTIVATE_EXPERIAN_USER_SUCCESS =
  'REACTIVATE_EXPERIAN_USER_SUCCESS';
export const REACTIVATE_EXPERIAN_USER_ERROR = 'REACTIVATE_EXPERIAN_USER_ERROR';

export const UPDATE_EXPERIAN_PRODUCT_TYPE_START =
  'UPDATE_EXPERIAN_PRODUCT_TYPE_START';
export const UPDATE_EXPERIAN_PRODUCT_TYPE_SUCCESS =
  'UPDATE_EXPERIAN_PRODUCT_TYPE_SUCCESS';
export const UPDATE_EXPERIAN_PRODUCT_TYPE_ERROR =
  'UPDATE_EXPERIAN_PRODUCT_TYPE_ERROR';

export const REPROCESS_DISPUTES_ALL_START = 'REPROCESS_DISPUTES_ALL_START';
export const REPROCESS_DISPUTES_ALL_SUCCESS = 'REPROCESS_DISPUTES_ALL_SUCCESS';
export const REPROCESS_DISPUTES_ALL_ERROR = 'REPROCESS_DISPUTES_ALL_ERROR';

export default {
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  LOGIN_AS_USER_START,
  LOGIN_AS_USER_SUCCESS,
  LOGIN_AS_USER_ERROR,
  FETCH_USER_MESSAGES_START,
  FETCH_USER_MESSAGES_SUCCESS,
  FETCH_USER_MESSAGES_ERROR,
  FETCH_USER_SMS_MESSAGES_START,
  FETCH_USER_SMS_MESSAGES_SUCCESS,
  FETCH_USER_SMS_MESSAGES_ERROR,
  SET_USER_PASSWORD_START,
  SET_USER_PASSWORD_SUCCESS,
  SET_USER_PASSWORD_ERROR,
  CHECK_EMAIL_START,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_ERROR,
  DEACTIVATE_EXPERIAN_USER_START,
  DEACTIVATE_EXPERIAN_USER_SUCCESS,
  DEACTIVATE_EXPERIAN_USER_ERROR,
  REACTIVATE_EXPERIAN_USER_START,
  REACTIVATE_EXPERIAN_USER_SUCCESS,
  REACTIVATE_EXPERIAN_USER_ERROR,
  UPDATE_EXPERIAN_PRODUCT_TYPE_START,
  UPDATE_EXPERIAN_PRODUCT_TYPE_SUCCESS,
  UPDATE_EXPERIAN_PRODUCT_TYPE_ERROR,
  REPROCESS_DISPUTES_ALL_START,
  REPROCESS_DISPUTES_ALL_SUCCESS,
  REPROCESS_DISPUTES_ALL_ERROR,
};
