export const FETCH_MARKETING_RESOURCES_START =
  'FETCH_MARKETING_RESOURCES_START';
export const FETCH_MARKETING_RESOURCES_SUCCESS =
  'FETCH_MARKETING_RESOURCES_SUCCESS';
export const FETCH_MARKETING_RESOURCES_ERROR =
  'FETCH_MARKETING_RESOURCES_ERROR';
export const FETCH_SUPER_MARKETING_RESOURCES_START =
  'FETCH_SUPER_MARKETING_RESOURCES_START';
export const FETCH_SUPER_MARKETING_RESOURCES_SUCCESS =
  'FETCH_SUPER_MARKETING_RESOURCES_SUCCESS';
export const FETCH_SUPER_MARKETING_RESOURCES_ERROR =
  'FETCH_SUPER_MARKETING_RESOURCES_ERROR';

export const CREATE_MARKETING_RESOURCE_START =
  'CREATE_MARKETING_RESOURCE_START';
export const CREATE_MARKETING_RESOURCE_SUCCESS =
  'CREATE_MARKETING_RESOURCE_SUCCESS';
export const CREATE_MARKETING_RESOURCE_ERROR =
  'CREATE_MARKETING_RESOURCE_ERROR';

export const DELETE_MARKETING_RESOURCE_START =
  'DELETE_MARKETING_RESOURCE_START';
export const DELETE_MARKETING_RESOURCE_SUCCESS =
  'DELETE_MARKETING_RESOURCE_SUCCESS';
export const DELETE_MARKETING_RESOURCE_ERROR =
  'DELETE_MARKETING_RESOURCE_ERROR';

export const UPDATE_MARKETING_RESOURCE_START =
  'UPDATE_MARKETING_RESOURCE_START';
export const UPDATE_MARKETING_RESOURCE_SUCCESS =
  'UPDATE_MARKETING_RESOURCE_SUCCESS';
export const UPDATE_MARKETING_RESOURCE_ERROR =
  'UPDATE_MARKETING_RESOURCE_ERROR';

export const FETCH_DOWNLOAD_URL_START = 'FETCH_DOWNLOAD_URL_START';
export const FETCH_DOWNLOAD_URL_SUCCESS = 'FETCH_DOWNLOAD_URL_SUCCESS';
export const FETCH_DOWNLOAD_URL_ERROR = 'FETCH_DOWNLOAD_URL_ERROR';

export const REGENERATE_MARKETING_RESOURCE_THUMBNAIL_START =
  'REGENERATE_MARKETING_RESOURCE_THUMBNAIL_START';
export const REGENERATE_MARKETING_RESOURCE_THUMBNAIL_SUCCESS =
  'REGENERATE_MARKETING_RESOURCE_THUMBNAIL_SUCCESS';
export const REGENERATE_MARKETING_RESOURCE_THUMBNAIL_ERROR =
  'REGENERATE_MARKETING_RESOURCE_THUMBNAIL_ERROR';

export default {
  FETCH_MARKETING_RESOURCES_START,
  FETCH_MARKETING_RESOURCES_SUCCESS,
  FETCH_MARKETING_RESOURCES_ERROR,
  FETCH_SUPER_MARKETING_RESOURCES_START,
  FETCH_SUPER_MARKETING_RESOURCES_SUCCESS,
  FETCH_SUPER_MARKETING_RESOURCES_ERROR,
  CREATE_MARKETING_RESOURCE_START,
  CREATE_MARKETING_RESOURCE_SUCCESS,
  CREATE_MARKETING_RESOURCE_ERROR,
  DELETE_MARKETING_RESOURCE_START,
  DELETE_MARKETING_RESOURCE_SUCCESS,
  DELETE_MARKETING_RESOURCE_ERROR,
  UPDATE_MARKETING_RESOURCE_START,
  UPDATE_MARKETING_RESOURCE_SUCCESS,
  UPDATE_MARKETING_RESOURCE_ERROR,
  FETCH_DOWNLOAD_URL_START,
  FETCH_DOWNLOAD_URL_SUCCESS,
  FETCH_DOWNLOAD_URL_ERROR,
  REGENERATE_MARKETING_RESOURCE_THUMBNAIL_START,
  REGENERATE_MARKETING_RESOURCE_THUMBNAIL_SUCCESS,
  REGENERATE_MARKETING_RESOURCE_THUMBNAIL_ERROR,
};
