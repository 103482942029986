/* eslint-disable default-case */
import produce from 'immer';
import {
  FETCH_TEMPLATES_START,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_ERROR,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_ERROR,
  FETCH_TEMPLATE_START,
  UPDATE_TEMPLATE_SUCCESS,
  RESTORE_TEMPLATE_SUCCESS,
  FETCH_DELETED_TEMPLATES_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
} from '../constants/templates.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export type TemplateStatus = 'active' | 'inactive' | 'draft';

export interface ConditionsJson {
  $dispute_round$: number;
}

export interface CurrentTemplateVersion {
  id: number;
  template_id: number;
  version_number: number;
  description: string;
  body: string;
  created_at: string;
  updated_at: string;
  templateId: number;
}

export interface Template {
  id: number;
  name: string;
  description: string;
  round: number;
  status: TemplateStatus;
  current_template_version_id: number;
  conditions_json: ConditionsJson;
  conditions_sql: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  currentTemplateVersion: CurrentTemplateVersion;
}

export interface TemplateUpdateValues {
  name: string;
  description: string;
  body: string;
  round: number;
  status: TemplateStatus;
}

export interface TemplatesState extends CallAPIAsyncState<Template> {
  total: number;
  deleted: { items: Template[]; total: number };
}

const initialState: TemplatesState = {
  ...INITIAL_ASYNC_STATE,
  total: 0,
  deleted: { items: [], total: 0 },
};

const templatesReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case FETCH_DELETED_TEMPLATES_SUCCESS:
        draft.deleted.items = action.payload.templates;
        draft.deleted.total = action.payload.meta.count;
        return;
      case FETCH_TEMPLATES_SUCCESS:
        draft.items = action.payload.templates;
        draft.total = action.payload.meta.count;
        draft.isFetched = true;
        return;
      case FETCH_TEMPLATE_SUCCESS:
        draft.item = action.payload.template;
        return;
      case UPDATE_TEMPLATE_SUCCESS:
        draft.items[
          draft.items.findIndex(item => item.id === action.payload.template.id)
        ] = action.payload.template;
        draft.item = action.payload.template;
        return;
      case DELETE_TEMPLATE_SUCCESS:
        const [removedTemplate] = draft.items.splice(
          draft.items.findIndex(element => element.id === action.id),
          1,
        );
        draft.items.push(removedTemplate);
        draft.deleted.total = draft.deleted.total + 1;
        draft.total -= 1;
        return;

      case RESTORE_TEMPLATE_SUCCESS:
        const [restoredTemplate] = draft.deleted.items.splice(
          draft.items.findIndex(element => element.id === action.id),
          1,
        );
        draft.items.push(restoredTemplate);
        draft.total += 1;
        draft.deleted.total -= 1;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<TemplatesState>({
  isLoadingActions: [FETCH_TEMPLATES_START, FETCH_TEMPLATE_START],
  successActions: [FETCH_TEMPLATES_SUCCESS, FETCH_TEMPLATE_SUCCESS],
  errorActions: [FETCH_TEMPLATES_ERROR, FETCH_TEMPLATE_ERROR],
})(templatesReducer);
