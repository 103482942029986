/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounceCallback } from '@react-hook/debounce';
import { selectSubscriptionsLoading } from '../../store/selectors';
import { Col, Row, Spin } from 'antd';
import { fetchPaymentPreview } from '../../store/actions/subscriptions.actions';
import { convertDispatchProps } from '../../store/middleware/api';
import { SubscriptionPreview } from '../../store/reducers/subscriptions.reducer';
import { selectCurrentOrganization } from '../../store/reducers/organizationSlice';

const componentStyle = css`
  small {
    font-size: 50%;
  }
  span,
  small {
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const SubscriptionPreviewWidget: React.FC<{
  term: number;
  productHandle: string;
  couponCode?: string;
  onResult?: (subscriptionPreviewData: any) => any;
  visible: boolean;
}> = ({ term, productHandle, couponCode, onResult, visible = true }) => {
  const loading = useSelector(selectSubscriptionsLoading);
  const { subdomain } = useSelector(selectCurrentOrganization);

  const dispatch = useDispatch();

  const apiActions = convertDispatchProps({
    fetchPaymentPreview,
  });

  const [data, setData] = useState<null | SubscriptionPreview>();

  const debouncedFetchPreview = useDebounceCallback(
    () =>
      dispatch(
        apiActions.fetchPaymentPreview(subdomain, {
          product_handle: productHandle,
          coupon_code: couponCode,
        }),
      ).then(result => {
        setData(result.payload.subscription_preview);
        onResult && onResult(result.payload.subscription_preview);
      }),
    400,
  );

  useEffect(() => {
    if (productHandle) {
      debouncedFetchPreview();
    } else {
      setData(null);
    }
  }, [productHandle, couponCode]);

  if (!data) {
    return null;
  }
  const subtotal = data?.current_billing_manifest.subtotal_in_cents / 100;
  const subtotalStr =
    subtotal % 1 === 0 ? subtotal.toString() : subtotal.toFixed(2);

  const discount = data?.current_billing_manifest.total_discount_in_cents / 100;
  const discountStr =
    discount % 1 === 0 ? discount.toString() : discount.toFixed(2);

  const total = data?.current_billing_manifest.total_in_cents / 100;
  const totalStr = total % 1 === 0 ? total.toString() : total.toFixed(2);
  return (
    <Spin spinning={loading}>
      <Row css={componentStyle} style={{ display: visible ? 'block' : 'none' }}>
        <Col xs={24} sm={8}>
          <span>Subtotal</span>
          <h1>
            {`$${subtotalStr}`}
            {!!total && <small>/{term > 1 ? ` ${term}` : ''} mo</small>}
          </h1>
        </Col>
        <Col xs={24} sm={8}>
          <span>Discount</span>
          <h1>{`$${discountStr}`}</h1>
        </Col>
        <Col xs={24} sm={8}>
          <span>Total</span>
          <h1>
            {`$${totalStr}`}
            {!!total && <small>/{term > 1 ? ` ${term}` : ''} mo</small>}
          </h1>
        </Col>
      </Row>
    </Spin>
  );
};
