import { createSelector } from 'reselect';
import { RootState } from '..';

export const getMessages = (state: RootState) => state.messages;
export const getMessagesLoading = (state: RootState) =>
  state.messages.isLoading;
export const getMessagesSuccessMessage = (state: RootState) =>
  state.messages.successMessage;
export const getMessagesErrorMessage = (state: RootState) =>
  state.messages.errorMessage;

export const getMessagesSMS = (state: RootState) => state.messages.sms.items;
export const getMessagesSMSLoading = (state: RootState) =>
  state.messages.sms.isLoading;
export const getMessagesSMSFetched = (state: RootState) =>
  state.messages.sms.isFetched;

export const getMessagesSMSErrorMessage = (state: RootState) =>
  state.messages.sms.errorMessage;
export const getMessagesSMSSuccessMessage = (state: RootState) =>
  state.messages.sms.successMessage;

export const getMessagesEmails = (state: RootState) =>
  state.messages.email.items;
export const getMessagesEmailLoading = (state: RootState) =>
  state.messages.email.isLoading;
export const getMessagesEmailFetched = (state: RootState) =>
  state.messages.email.isFetched;
export const getMessagesEmailErrorMessage = (state: RootState) =>
  state.messages.email.errorMessage;
export const getMessagesEmailSuccessMessage = (state: RootState) =>
  state.messages.email.successMessage;

export const selectMessages = createSelector(getMessages, messages => messages);
export const selectMessagesLoading = createSelector(
  getMessagesLoading,
  loading => loading,
);
export const selectMessagesErrorMessage = createSelector(
  getMessagesErrorMessage,
  message => message,
);
export const selectMessagesSuccessMessage = createSelector(
  getMessagesSuccessMessage,
  message => message,
);

export const selectMessagesSMS = createSelector(
  getMessagesSMS,
  messages => messages,
);
export const selectMessagesSMSLoading = createSelector(
  getMessagesSMSLoading,
  loading => loading,
);
export const selectMessagesSMSFetched = createSelector(
  getMessagesSMSFetched,
  fetched => fetched,
);
export const selectMessagesSMSErrorMessage = createSelector(
  getMessagesSMSErrorMessage,
  message => message,
);
export const selectMessagesSMSSuccessMessage = createSelector(
  getMessagesSMSSuccessMessage,
  message => message,
);

export const selectMessagesEmail = createSelector(
  getMessagesEmails,
  messages => messages,
);
export const selectMessagesEmailLoading = createSelector(
  getMessagesEmailLoading,
  loading => loading,
);
export const selectMessagesEmailFetched = createSelector(
  getMessagesEmailFetched,
  fetched => fetched,
);
export const selectMessagesEmailErrorMessage = createSelector(
  getMessagesEmailErrorMessage,
  message => message,
);
export const selectMessagesEmailSuccessMessage = createSelector(
  getMessagesEmailSuccessMessage,
  message => message,
);

export default {
  selectMessages,
  selectMessagesLoading,
  selectMessagesErrorMessage,
  selectMessagesSuccessMessage,
  selectMessagesEmail,
  selectMessagesEmailLoading,
  selectMessagesEmailFetched,
  selectMessagesEmailErrorMessage,
  selectMessagesEmailSuccessMessage,
  selectMessagesSMS,
  selectMessagesSMSLoading,
  selectMessagesSMSFetched,
  selectMessagesSMSErrorMessage,
  selectMessagesSMSSuccessMessage,
};
