import { configureStore, Middleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';

import CallAPIMiddleware from './middleware/api';
import rootReducer from './reducers';
import history from './history';
import wsMiddleware from './middleware/ws';
import { api } from './api';


const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      CallAPIMiddleware,
      routerMiddleware(history),
      wsMiddleware,
      api.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store['getState']>;
export default store;
