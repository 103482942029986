import React, { JSXElementConstructor } from 'react';
import { FolderOpenOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  DatePicker,
  Input,
  TimePicker,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Button,
} from 'antd';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useFormikContext, useField, Field, FieldProps, getIn } from 'formik';
import { FormItemProps } from 'antd/lib/form';
import { SearchProps } from 'antd/lib/input';
import { SwitchProps } from 'antd/lib/switch';

const FormItem = Form.Item;
const { Option } = Select;

type SelectOption = string | { label: string; value: string };

const getSelectOption = (opt: SelectOption) => {
  let key: string, label: string, value: string;

  if (typeof opt === 'string') {
    key = label = value = opt;
  } else {
    key = value = opt.value;
    label = opt.label;
  }

  return (
    <Option key={key} value={value}>
      {label}
    </Option>
  );
};

export const CreateAntField = (Component: any, isNumberFormat = false) => ({
  field,
  form,
  hasFeedback,
  label,
  selectOptions,
  type,
  required,
  defaultValue,
  labelAlign,
  labelCol,
  wrapperCol,
  formItemStyles,
  onChange: customOnChange,
  onBlur: customOnBlur,
  preserveFormatting = false,
  ...props
}: any) => {
  const touched = getIn(form.touched, field.name) || form.touched[field.name];
  const submitted = form.submitCount > 0;
  const hasError = getIn(form.errors, field.name) || form.errors[field.name]; // support field array errors as well as setErrors entries
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  const onInputChange = customOnChange
    ? customOnChange
    : ({ target: { value } }: any) => form.setFieldValue(field.name, value);
  const onChange = customOnChange
    ? customOnChange
    : (value: string) => form.setFieldValue(field.name, value);
  const onBlur = customOnBlur
    ? customOnBlur
    : () => form.setFieldTouched(field.name, true);

  return (
    <div className="field-container">
      <FormItem
        label={label}
        hasFeedback={
          (hasFeedback && submitted) || (hasFeedback && touched)
            ? // || (hasError && !touched)
              true
            : false
        }
        help={
          submittedError || touchedError
            ? // || (hasError && !touched)
              hasError
            : false
        }
        validateStatus={
          submittedError || touchedError
            ? // || hasError ?
              'error'
            : 'success'
        }
        required={required}
        labelAlign={labelAlign}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        style={formItemStyles}
      >
        <Component
          {...field}
          {...props}
          onBlur={onBlur}
          onChange={
            isNumberFormat && !preserveFormatting
              ? undefined
              : type
              ? onInputChange
              : onChange
          }
          {...(isNumberFormat &&
            !preserveFormatting && {
              onValueChange: ({ value }: any) => {
                form.setFieldValue(field.name, value);
                onChange && onChange(value);
              },
            })}
          {...(defaultValue && { defaultValue: defaultValue })}
        >
          {selectOptions &&
            Array.isArray(selectOptions) &&
            selectOptions.map(getSelectOption)}
        </Component>
      </FormItem>
    </div>
  );
};

// NOTE: AntMasked passes FORMATTED string to onChange handler
export const AntMasked = CreateAntField(NumberFormat, true);
export const AntSelect = CreateAntField(Select);
export const AntTreeSelect = CreateAntField(TreeSelect);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntInputTextArea = CreateAntField(Input.TextArea);
export const AntPasswordInput = CreateAntField(Input.Password);

export const AntTimePicker = CreateAntField(TimePicker);

interface WithName {
  name: string;
}

type FormGroupProps = Pick<
  FormItemProps,
  'label' | 'hasFeedback' | 'required' | 'help'
>;

/**
 * Take component, and build component constructor that wraps component in antd
 * `Form.Item`. Use formik context to set `Form.Item` props
 *
 * @param Component
 */
const withFormGroup = <P extends {}>(Component: JSXElementConstructor<P>) => (
  props: P & FormGroupProps & WithName,
) => {
  const form = useFormikContext<any>();

  const touched = form.touched[props.name];
  const error = form.errors[props.name];
  const submitted = form.submitCount > 0;
  const submittedError = error && submitted;
  const touchedError = error && touched;

  return (
    <div className="field-container">
      <FormItem
        label={props.label}
        hasFeedback={
          (props.hasFeedback && submitted) || (props.hasFeedback && touched)
            ? true
            : false
        }
        help={submittedError || touchedError ? error : props.help}
        validateStatus={submittedError || touchedError ? 'error' : 'success'}
        required={props.required}
      >
        <Component {...props} />
      </FormItem>
    </div>
  );
};

type AntNumberFormatProps = RequiredOnly<NumberFormatProps>;

// Don't want to 'fix' original AntMasked and potentially break in other forms
export const AntNumberFormat = withFormGroup(
  ({ ...props }: AntNumberFormatProps & WithName) => {
    const [field] = useField(props.name);
    const form = useFormikContext<any>();

    return (
      <NumberFormat
        {...props}
        // don't try to map field.value to value prop, or you'll get caught in
        // an infinity update loop when you try to autoComplete a form, from
        // the autocomplete value to empty string and back again :(
        defaultValue={field.value}
        onValueChange={({ value }) => form.setFieldValue(props.name, value)}
        onBlur={field.onBlur}
      />
    );
  },
);

export const AntSearchField = withFormGroup((props: SearchProps & WithName) => {
  const [field] = useField(props.name);
  const form = useFormikContext<any>();

  return (
    <Input.Search {...props} {...field} onSearch={() => form.submitForm()} />
  );
});

export interface FormikFieldProps {
  name: string;
  validate?: (value: any) => undefined | string | Promise<any>;
}
export const AntSwitchField = ({
  name,
  validate,
  onChange,
  ...restProps
}: SwitchProps & FormikFieldProps) => (
  <Field name={name} validate={validate}>
    {({
      field: { value },
      form: { setFieldValue, setFieldTouched },
    }: FieldProps) => (
      <Switch
        checked={value}
        onChange={(checked, event) => {
          setFieldValue(name, checked);
          setFieldTouched(name, true, false);
          onChange && onChange(checked, event);
        }}
        {...restProps}
      />
    )}
  </Field>
);

const wrapWithFormItem = <P extends {}>(
  Component: JSXElementConstructor<P>,
) => (props: P & FieldProps & FormItemProps) => {
  const { form, field, label, hasFeedback, required } = props;
  const touched = form.touched[field.name];
  const error = form.errors[field.name];
  const submitted = form.submitCount > 0;
  const submittedError = error && submitted;
  const touchedError = error && touched;

  return (
    <div className="field-container">
      <FormItem
        {...props}
        label={label}
        hasFeedback={
          (hasFeedback && submitted) || (hasFeedback && touched) ? true : false
        }
        help={submittedError || touchedError ? error : false}
        validateStatus={submittedError || touchedError ? 'error' : 'success'}
        required={required}
      >
        <Component {...props} />
      </FormItem>
    </div>
  );
};

const buildAntUpload = ({
  form,
  field,
  ...props
}: FieldProps & { [key: string]: any }) => {
  return (
    <Upload
      {...props}
      beforeUpload={file => {
        form.setFieldValue(field.name, file);
        return false;
      }}
      onRemove={() => form.setFieldValue(field.name, null)}
      onChange={({ fileList }) => fileList.splice(0, fileList.length - 1)}
    >
      <Button icon={<FolderOpenOutlined />} type="default">
        Select a file
      </Button>
    </Upload>
  );
};

const buildAntSwitch = ({
  form,
  field,
}: FieldProps & { [key: string]: any }) => (
  <Switch
    checked={form.values[field.name]}
    onChange={checked => {
      form.setFieldValue(field.name, checked);
      if (!form.touched[field.name]) {
        form.setFieldTouched(field.name);
      }
    }}
  />
);

export const AntUpload = wrapWithFormItem(buildAntUpload);
export const AntSwitch = wrapWithFormItem(buildAntSwitch);
