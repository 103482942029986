import path from 'path';
import moment from 'moment';
import { createSelector } from 'reselect';
import { RootState } from '..';
import {
  OrganizationType,
  labelByOrganizationType,
  selectAllOrganizations,
} from '../reducers/organizationSlice';
import {
  MarketingResource,
  SuperMarketingResource,
} from '../reducers/marketing.reducer';

export const getMarketingResources = (state: RootState) =>
  state.marketing.items;
export const getMarketingResourcesFetched = (state: RootState) =>
  state.marketing.isFetched;
export const getDownloadUrl = (state: RootState) => state.marketing.downloadUrl;
export const getMarketingResourcesLoading = (state: RootState) =>
  state.marketing.isLoading;
export const getMarketingResourcesSuccessMessage = (state: RootState) =>
  state.marketing.successMessage;
export const getMarketingResourcesErrorMessage = (state: RootState) =>
  state.marketing.errorMessage;
export const getMarketingResourcesCount = (state: RootState) =>
  state.marketing.meta.count;

export const selectMarketingResources = createSelector(
  getMarketingResources,
  marketingResources => marketingResources,
);
export const selectMarketingResourcesFetched = createSelector(
  getMarketingResourcesFetched,
  isFetched => isFetched,
);
export const selectDownloadUrl = createSelector(getDownloadUrl, url => url);
export const selectMarketingResourcesLoading = createSelector(
  getMarketingResourcesLoading,
  loading => loading,
);
export const selectMarketingResourcesErrorMessage = createSelector(
  getMarketingResourcesErrorMessage,
  message => message,
);
export const selectMarketingResourcesSuccessMessage = createSelector(
  getMarketingResourcesSuccessMessage,
  message => message,
);
export const selectMarketingResourcesCount = createSelector(
  getMarketingResourcesCount,
  count => count,
);

export const selectMarketingResourcesSortedByCategory = createSelector(
  getMarketingResources,
  marketingResources => {
    const initialSort: { [key: string]: MarketingResource[] } = { all: [] };

    return marketingResources.reduce((acc, resource) => {
      if (resource.available) {
        acc.all.push(resource);
        if (resource.category) {
          if (acc[resource.category]) {
            acc[resource.category].push(resource);
          } else {
            acc[resource.category] = [resource];
          }
        }
      }
      return acc;
    }, initialSort);
  },
);

export const selectFormattedSuperMarketingResources = createSelector(
  getMarketingResources as (state: RootState) => SuperMarketingResource[],
  state => selectAllOrganizations(state),
  (marketingResources, availableOrganizations) => {
    const availableOrganizationsById: { [key: string]: string | null } = {
      default: null,
    };
    for (const organization of availableOrganizations) {
      availableOrganizationsById[organization.id] = organization.name;
    }
    return marketingResources.map(resource => {
      const filetype = path.extname(resource.s3_key);
      const filename = path.basename(resource.s3_key, filetype);
      const scope: {
        label: string | null;
        by: 'global' | 'organization-id' | 'organization-type';
        value: number | OrganizationType | null;
      } = { label: 'Global', by: 'global', value: null };
      if (resource.organization_type) {
        scope.label = labelByOrganizationType[resource.organization_type];
        scope.by = 'organization-type';
        scope.value = resource.organization_type;
      } else if (resource.organization_id) {
        scope.label =
          availableOrganizationsById[resource.organization_id] || 'local';
        scope.by = 'organization-id';
        scope.value = resource.organization_id;
      }
      return {
        key: resource.id,
        id: resource.id,
        filename,
        filetype: filetype.replace('.', '').toUpperCase(),
        category: resource.category,
        scope,
        'date-updated': moment(resource.updated_at),
        available: resource.available,
        thumbnail_url: resource.thumbnail_url,
        url: resource.s3_url,
      };
    });
  },
);

export default {
  selectMarketingResources,
  selectMarketingResourcesFetched,
  selectDownloadUrl,
  selectMarketingResourcesLoading,
  selectMarketingResourcesErrorMessage,
  selectMarketingResourcesSuccessMessage,
  selectMarketingResourcesCount,
  selectMarketingResourcesSortedByCategory,
  selectFormattedSuperMarketingResources,
};
