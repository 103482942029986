export const CHECK_SESSION_START = 'CHECK_SESSION_START';
export const CHECK_SESSION_SUCCESS = 'CHECK_SESSION_SUCCESS';
export const CHECK_SESSION_ERROR = 'CHECK_SESSION_ERROR';

export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';

export const SIGN_OUT_START = 'SIGN_OUT_START';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const UPDATE_CURRENT_ADMIN_START = 'UPDATE_CURRENT_ADMIN_START';
export const UPDATE_CURRENT_ADMIN_SUCCESS = 'UPDATE_CURRENT_ADMIN_SUCCESS';
export const UPDATE_CURRENT_ADMIN_ERROR = 'UPDATE_CURRENT_ADMIN_ERROR';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const UPDATE_PHONE_START = 'UPDATE_PHONE_START';
export const UPDATE_PHONE_SUCCESS = 'UPDATE_PHONE_SUCCESS';
export const UPDATE_PHONE_ERROR = 'UPDATE_PHONE_ERROR';

export const UPDATE_EMAIL_START = 'UPDATE_EMAIL_START';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';

export const SET_ENROLLMENT_PASSWORD_START = 'SET_ENROLLMENT_PASSWORD_START';
export const SET_ENROLLMENT_PASSWORD_SUCCESS =
  'SET_ENROLLMENT_PASSWORD_SUCCESS';
export const SET_ENROLLMENT_PASSWORD_ERROR = 'SET_ENROLLMENT_PASSWORD_ERROR';

export const FETCH_ADMIN_FROM_TOKEN_START = 'FETCH_ADMIN_FROM_TOKEN_START';
export const FETCH_ADMIN_FROM_TOKEN_SUCCESS = 'FETCH_ADMIN_FROM_TOKEN_SUCCESS';
export const FETCH_ADMIN_FROM_TOKEN_ERROR = 'FETCH_ADMIN_FROM_TOKEN_ERROR';

export const STORE_AUTH_TOKEN = 'STORE_AUTH_TOKEN';

export const REGISTER_USER = 'REGISTER_USER';

export default {
  CHECK_SESSION_START,
  CHECK_SESSION_SUCCESS,
  CHECK_SESSION_ERROR,

  SIGNIN_START,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,

  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,

  STORE_AUTH_TOKEN,

  UPDATE_CURRENT_ADMIN_START,
  UPDATE_CURRENT_ADMIN_SUCCESS,
  UPDATE_CURRENT_ADMIN_ERROR,

  UPDATE_PHONE_START,
  UPDATE_PHONE_SUCCESS,
  UPDATE_PHONE_ERROR,
  UPDATE_EMAIL_START,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,

  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,

  SET_ENROLLMENT_PASSWORD_START,
  SET_ENROLLMENT_PASSWORD_SUCCESS,
  SET_ENROLLMENT_PASSWORD_ERROR,

  FETCH_ADMIN_FROM_TOKEN_START,
  FETCH_ADMIN_FROM_TOKEN_SUCCESS,
  FETCH_ADMIN_FROM_TOKEN_ERROR,
};
