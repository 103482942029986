export const FETCH_SYSTEM_EVENT_TYPES_START = 'FETCH_SYSTEM_EVENT_TYPES_START';
export const FETCH_SYSTEM_EVENT_TYPES_SUCCESS =
  'FETCH_SYSTEM_EVENT_TYPES_SUCCESS';
export const FETCH_SYSTEM_EVENT_TYPES_ERROR = 'FETCH_SYSTEM_EVENT_TYPES_ERROR';

export default {
  FETCH_SYSTEM_EVENT_TYPES_START,
  FETCH_SYSTEM_EVENT_TYPES_SUCCESS,
  FETCH_SYSTEM_EVENT_TYPES_ERROR,
};
