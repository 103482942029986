import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { apiRequest } from '../../store/utils';

const adapter = createEntityAdapter<VerificationJob>();
const initialState = adapter.getInitialState();

export const subscribeToVerifications = createAsyncThunk(
  'verifications/fetchAndSubscribe',
  async () => {
    const {
      data: { jobs },
    } = await apiRequest.get<VerificationJobsResponse>(
      `admin/super/manual_review`,
    );
    return jobs;
  },
);
export const updateVerificationStatus = createAsyncThunk(
  'verifications/updateStatus',
  async ({ id, allow }: { id: number; allow: boolean }) => {
    await apiRequest.put(`admin/super/manual_review/${id}`, {
      payload: { allow },
    });
    return id;
  },
);

export const unsubscribeToVerifications = createAsyncThunk(
  'verifications/unsubscribe',
  async () => {
    return true;
  },
);

const slice = createSlice({
  name: 'verifications',
  initialState,
  reducers: {
    addPendingVerification: adapter.addOne,
    removePendingVerification: adapter.removeOne,
  },
  extraReducers: builder => {
    builder
      .addCase(subscribeToVerifications.fulfilled, adapter.setAll)
      .addCase(unsubscribeToVerifications.fulfilled, adapter.removeAll)
      .addCase(updateVerificationStatus.fulfilled, adapter.removeOne);
  },
});

export default slice.reducer;
export const {
  addPendingVerification,
  removePendingVerification,
} = slice.actions;

export const { selectAll: selectAllVerifications } = adapter.getSelectors(
  (state: RootState) => state.verifications,
);

export interface Header {
  name: string;
  value: string;
}

export interface Data {
  userId: number;
  ip: string;
  meta: IpMeta;
  userAgent: string;
  headers: Header[];
  payload: string;
}

export interface VerificationJob {
  id: number;
  type: string;
  admin_id?: any;
  organization_id?: any;
  user_id: number;
  state: string;
  data: Data;
  result?: any;
  processed_at?: any;
  delay: number;
  priority: number;
  created_at: Date;
  updated_at: Date;
}

export interface Meta {
  id: string;
  api: string;
  env: string;
}

export interface IpMeta {
  ip: string;
  ip_asn: number;
  ip_city: string;
  ip_isp: string;
  ip_latitude: number;
  ip_longitude: number;
  ip_state: string;
  ip_zip: string;
}

export interface VerificationJobsResponse {
  jobs: VerificationJob[];
  meta: Meta;
}
