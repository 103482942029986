import axios from 'axios';
import startsWith from 'lodash/startsWith';

import { API_URI } from '../config';

import { signOutUser } from '../store/actions/auth.actions';

export default {
  setup: store => {
    axios.interceptors.response.use(
      response => response,
      error => {
        const isScoreshuttleAPI = startsWith(
          error.response.config.url,
          API_URI,
        );
        // this will throw us in an endless loop if we don't check this. there is also no reason to dispatch again as it would've already been fired
        const was401onSignOut =
          error.response.config.method === 'delete' &&
          error.response.config.url.includes('admin/auth');
        if (
          error.response.status === 401 &&
          error.response.config.withCredentials &&
          !was401onSignOut &&
          isScoreshuttleAPI
        ) {
          store.dispatch(signOutUser());
        }
        return Promise.reject(error);
      },
    );
  },
};
