import bugsnag, { Bugsnag } from '@bugsnag/js';
import { BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE } from '../config';

let client: Bugsnag.Client;
if (BUGSNAG_API_KEY) {
  client = bugsnag({
    apiKey: BUGSNAG_API_KEY,
    beforeSend: (report: any) => {
      report.app.releaseStage = String(BUGSNAG_RELEASE_STAGE);
    },
  });
} else {
  client = bugsnag({
    apiKey: 'none',
    beforeSend(err) {
      return false;
    },
  });
}

export default client;
