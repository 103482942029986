import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_COMMISSIONS_START,
  FETCH_COMMISSIONS_SUCCESS,
  FETCH_COMMISSIONS_ERROR,
} from '../constants/commission.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface Surge__Commission__c {
  Id: string;
  IsDeleted: boolean;
  Name: string;
  CreatedDate: Date;
  CreatedById: string;
  LastModifiedDate: Date;
  LastModifiedById: string;
  SystemModstamp: Date;
  LastViewedDate: Date;
  LastReferencedDate: Date;
  Surge__Amount__c: number;
  Surge__Affiliate_Contract__c?: string;
  Surge__Affiliate__c?: string;
  Surge__Agent_Contract__c?: string;
  Surge__Agent__c?: string;
  Surge__Batch__c?: string;
  Transaction_Voided__c: boolean;
  Transaction__c: string;
  Surge__User_Contract__c?: string;
  Surge__User__c?: string;
}

export interface CommissionsById {
  [key: string]: Surge__Commission__c;
}

export interface CommissionsState extends CallAPIAsyncState {
  readonly byId: CommissionsById;
}

const initialState: CommissionsState = {
  byId: {},
  ...INITIAL_ASYNC_STATE,
};

const commissionsReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_COMMISSIONS_SUCCESS:
        draft.items = action.payload.Surge__Commission__c;
        draft.byId = action.payload.Surge__Commission__c.reduce(
          (
            commissionsById: CommissionsById,
            commission: Surge__Commission__c,
          ) => ({
            ...commissionsById,
            [commission.Id]: commission,
          }),
          {},
        );
        draft.isFetched = true;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<CommissionsState>({
  isLoadingActions: [FETCH_COMMISSIONS_START],
  successActions: [FETCH_COMMISSIONS_SUCCESS],
  errorActions: [FETCH_COMMISSIONS_ERROR],
})(commissionsReducer);
