export const FETCH_EMAIL_MESSAGES_START = 'FETCH_EMAIL_MESSAGES_START';
export const FETCH_EMAIL_MESSAGES_SUCCESS = 'FETCH_EMAIL_MESSAGES_SUCCESS';
export const FETCH_EMAIL_MESSAGES_ERROR = 'FETCH_EMAIL_MESSAGES_ERROR';

export const FETCH_SMS_MESSAGES_START = 'FETCH_SMS_MESSAGES_START';
export const FETCH_SMS_MESSAGES_SUCCESS = 'FETCH_SMS_MESSAGES_SUCCESS';
export const FETCH_SMS_MESSAGES_ERROR = 'FETCH_SMS_MESSAGES_ERROR';

export default {
  FETCH_EMAIL_MESSAGES_START,
  FETCH_EMAIL_MESSAGES_SUCCESS,
  FETCH_EMAIL_MESSAGES_ERROR,
  FETCH_SMS_MESSAGES_START,
  FETCH_SMS_MESSAGES_SUCCESS,
  FETCH_SMS_MESSAGES_ERROR,
};
