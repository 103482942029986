import React, { useRef } from 'react';
import { Select } from 'antd';
import { EditorState, Modifier } from 'draft-js';

const TemplateVariablesToolbarOption: React.FC<any> = ({
  editorState,
  onChange,
  editorRef,
}) => {
  const selectRef = useRef<any>();
  // the types are incomplete for this lib. A toolbar is passed editorState, onChange, modalHandler, translations
  const addTemplateVariable = (variable: string) => {
    const newContentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      variable,
      editorState.getCurrentInlineStyle(),
    );
    const newState = EditorState.createWithContent(newContentState);
    const nextOffSet =
      editorState.getSelection().getFocusOffset() + variable.length;

    const newSelection = editorState.getSelection().merge({
      focusOffset: nextOffSet,
      anchorOffset: nextOffSet,
    });
    // force select to the end of the inserted variable
    onChange(EditorState.forceSelection(newState, newSelection));
  };

  const variableOptions = [
    { key: 'todaysdate', value: '[TodaysDate]', text: `Today's Date` },
    { key: 'targetname', value: '[RecipientName]', text: 'Bureau Name' },
    {
      key: 'targetaddress1',
      value: '[RecipientAddressLine1]',
      text: 'Bureau Street Address',
    },
    {
      key: 'targetaddress2',
      value: '[RecipientAddressLine2]',
      text: 'Bureau Address 2',
    },
    {
      key: 'targetzip',
      value: '[RecipientCityStatePostalCode]',
      text: 'Bureau City/State/Zip',
    },
    { key: 'firstname', value: '[CustomerFirstName]', text: 'First Name' },
    { key: 'lastname', value: '[CustomerLastName]', text: 'Last Name' },
    {
      key: 'accountitems',
      value: '[AccountItemList]',
      text: 'Disputed Accounts List',
    },
    { key: 'ssn', value: '[CustomerSSN]', text: 'SSN' },
    { key: 'dob', value: '[CustomerDOB]', text: 'DOB' },
    {
      key: 'customeraddress1',
      value: '[CustomerAddressLine1]',
      text: 'Street Address',
    },
    {
      key: 'customeraddress2',
      value: '[CustomerAddressLine2]',
      text: 'Address Line 2',
    },
    {
      key: 'customercitystatezip',
      value: '[CustomerCityStatePostalCode]',
      text: 'Subscriber City/State/Zip',
    },
    {
      key: 'lastcreditreportdate',
      value: '[LastCreditReportDate]',
      text: 'Last Credit Report Date',
    },
  ];

  return (
    <Select
      showSearch
      placeholder="Please select"
      defaultValue="Template Variables"
      ref={selectRef}
      onSelect={(value: string) => {
        // remove focus from Select, return it to the Editor, then force select in handler
        selectRef.current.blur();
        editorRef.current.editor.focus();
        addTemplateVariable(value);
      }}
    >
      {variableOptions.map(variable => (
        <Select.Option key={variable.key} value={variable.value}>
          {variable.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TemplateVariablesToolbarOption;
