/* eslint-disable default-case */
import produce, { Draft } from 'immer';
import {
  FETCH_EMAIL_MESSAGES_START,
  FETCH_EMAIL_MESSAGES_SUCCESS,
  FETCH_EMAIL_MESSAGES_ERROR,
  FETCH_SMS_MESSAGES_START,
  FETCH_SMS_MESSAGES_SUCCESS,
  FETCH_SMS_MESSAGES_ERROR,
} from '../constants/message.constants';
import { FETCH_ADMIN_START } from '../constants/admin.constants';
import { FETCH_USER_START } from '../constants/user.constants';
import { CallAPIAsyncState, CallAPIAction } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';
import emailReducer, { EmailMessagesState } from './message.email.reducer';
import smsReducer, { SMSMessagesState } from './message.sms.reducer';

export interface MessagesState extends CallAPIAsyncState {
  [key: string]: any;
  email: EmailMessagesState;
  sms: SMSMessagesState;
}

const initialState: MessagesState = {
  email: { ...INITIAL_ASYNC_STATE },
  sms: { ...INITIAL_ASYNC_STATE },
  ...INITIAL_ASYNC_STATE,
};

const getReducer = (action: CallAPIAction) => {
  switch (action.reducerKey) {
    case 'email':
      return emailReducer;
    case 'sms':
      return smsReducer;
    default:
  }
};

const messageReducer = produce(
  (draft: Draft<MessagesState>, action) => {
    switch (action.type) {
      case FETCH_EMAIL_MESSAGES_START:
      case FETCH_EMAIL_MESSAGES_SUCCESS:
      case FETCH_EMAIL_MESSAGES_ERROR:
      case FETCH_SMS_MESSAGES_START:
      case FETCH_SMS_MESSAGES_SUCCESS:
      case FETCH_SMS_MESSAGES_ERROR:
        return Object.assign({}, draft, {
          [action.reducerKey]: getReducer(action)!(
            draft[action.reducerKey],
            action,
          ),
        });
      case FETCH_ADMIN_START:
      case FETCH_USER_START:
        draft.email = { ...INITIAL_ASYNC_STATE };
        draft.sms = { ...INITIAL_ASYNC_STATE };
        return;
    }
  },
  { ...initialState },
);

export default withLoading<MessagesState>({
  isLoadingActions: [],
  successActions: [],
  errorActions: [],
})(messageReducer);
