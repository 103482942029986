import * as React from 'react';
import { Helmet } from 'react-helmet';
import GridContent from './GridContent';
import { PageHeader, Row, Col } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';

const styles = {
  content: 'wide',
};

type Props = PageHeaderProps & {
  children?: any;
  contentWidth?: string;
  wrapperClassName?: string;
  top?: number;
  // itemRender: any;
  footer?: any;
  action?: any;
  content?: any;
  extraContent?: any;
  htmlTitle?: string;
};

class PageHeaderWrapper extends React.PureComponent<Props> {
  render() {
    const {
      children,
      wrapperClassName,
      top,
      title,
      htmlTitle,
      content,
      action,
      ...rest
    } = this.props;

    const buildHtmlHead = () =>
      title ? (
        <Helmet>
          <title>{htmlTitle}</title>
        </Helmet>
      ) : null;

    return (
      <>
        {buildHtmlHead()}
        <Col span={24}>
          {top}
          <PageHeader key="pageheader" title={title} {...rest}>
            <Row>
              <Col lg={16}>{content}</Col>
              <Col xs={21} lg={8} style={{ textAlign: 'right' }}>
                {action}
              </Col>
            </Row>
          </PageHeader>
          {children ? <GridContent>{children}</GridContent> : null}
        </Col>
      </>
    );
  }
}

export default PageHeaderWrapper;
