import * as React from 'react';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import { DropDownProps } from 'antd/lib/dropdown';

interface Props {
  overlayClassName?: string;
  style?: React.CSSProperties;
}
export default class HeaderDropdown extends React.PureComponent<
  Props & DropDownProps
> {
  render() {
    const { overlayClassName, ...props } = this.props;
    return (
      <Dropdown
        overlayClassName={classNames('container', overlayClassName)}
        {...props}
      />
    );
  }
}
