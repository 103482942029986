import { createSelector } from 'reselect';
import { RootState } from '..';
import { getLoadingSelectors } from '../utils';

export const selectSubscription = createSelector(
  (state: RootState) => state.subscriptions.item,
  subscription => subscription,
);

export const selectSubscriptionMeta = createSelector(
  (state: RootState) => state.subscriptions.meta,
  meta => meta,
);

export const selectSubscriptionFetched = createSelector(
  (state: RootState) => state.subscriptions.isFetched,
  isFetched => isFetched,
);

export const selectSubscriptionsSuccessMessage = createSelector(
  (state: RootState) => state.subscriptions.successMessage,
  successMessage => successMessage,
);

export const selectSubscriptionTransactions = createSelector(
  (state: RootState) => state.subscriptions.transactions,
  transactions => transactions,
);

export const [
  selectSubscriptionsLoading,
  selectSubscriptionErrors,
  selectSubscriptionsErrorMessage,
] = getLoadingSelectors(state => state.subscriptions);

export default {
  selectSubscription,
  selectSubscriptionMeta,
  selectSubscriptionsLoading,
  selectSubscriptionErrors,
  selectSubscriptionsErrorMessage,
  selectSubscriptionsSuccessMessage,
};
