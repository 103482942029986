import { createSelector } from 'reselect';
import { RootState } from '..';
import { format } from 'date-fns';
import { Transaction__c } from '../reducers/transaction.reducer';
import countBy from 'lodash/countBy';
import sortBy from 'lodash/sortBy';

export interface ChartDataPoint {
  x: string;
  y: number;
}

// common checks
const isChargeType = (txn: Transaction__c) => txn.Type__c === 'Charge';
const isApprovedCharge = (txn: Transaction__c) =>
  txn.Response_Status_Approved__c;

export const getTransactions = (state: RootState) => state.transactions.items;
export const getTransactionsLoading = (state: RootState) =>
  state.transactions.isLoading;

export const selectTransactions = createSelector(
  getTransactions,
  transactions => transactions,
);
export const selectTransactionsLoading = createSelector(
  getTransactionsLoading,
  loading => loading,
);

export const selectPaymentsFromTransactionsByDay = createSelector(
  getTransactions,
  (transactions: Transaction__c[]) => {
    const grouped = countBy(transactions, txn =>
      format(txn.CreatedDate, 'YYYY-MM-DD'),
    );

    const dates = sortBy(Object.keys(grouped), (key: string) => {
      return new Date(key);
    });
    const result = dates.map((date: string) => {
      return {
        x: date,
        y: grouped[date],
      };
    });

    return result;
  },
);

export const selectTotalSalesByDay = createSelector(
  getTransactions,
  transactions => {
    const summedByDate: ChartDataPoint[] = [];

    transactions.reduce(
      (res: { [key: string]: ChartDataPoint }, txn: Transaction__c) => {
        if (isChargeType(txn) && isApprovedCharge(txn)) {
          const date = format(txn.CreatedDate, 'YYYY-MM-DD');
          if (!res[date]) {
            res[date] = { x: format(txn.CreatedDate, 'YYYY-MM-DD'), y: 0 };
            summedByDate.push(res[date]);
          }
          res[date].y += txn.Amount__c;
        }
        return res;
      },
      {},
    );

    const sorted = sortBy(summedByDate, entry => {
      return entry.x;
    });

    return sorted;
  },
);

export const selectTotalSales = createSelector(
  getTransactions,
  transactions => {
    if (transactions.length === 0) {
      return 0;
    }

    return transactions.reduce((total: number, txn: Transaction__c) => {
      if (isChargeType(txn) && isApprovedCharge(txn)) {
        return total + txn.Amount__c;
      }
      return 0;
    });
  },
);

export const selectDailySales = createSelector(
  getTransactions,
  (transactions: Transaction__c[]) => {
    const date = format(new Date(), 'YYYY-MM-DD');
    return transactions
      .filter(txn => format(txn.CreatedDate, 'YYYY-MM-DD') === date)
      .reduce((total: number, txn) => total + txn.Amount__c, 0);
  },
);

export const selectPaymentSuccessRate = createSelector(
  getTransactions,
  (transactions: Transaction__c[]) => {
    const grouped = countBy(transactions, txn => {
      if (isChargeType(txn)) {
        return txn.Response_Status_Approved__c;
      }
    });

    return grouped.true / transactions.length;
  },
);

export default {
  selectTransactions,
  selectTransactionsLoading,
  selectPaymentsFromTransactionsByDay,
  selectPaymentSuccessRate,
  selectTotalSalesByDay,
  selectTotalSales,
  selectDailySales,
};
