/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useEffect, useCallback, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PageHeaderWrapper from '../shared/PageHeaderWrapper';
import { NotificationTemplate } from '../../store/reducers/notification_templates.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { convertDispatchProps } from '../../store/middleware/api';
import { useParams, useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import {
  Card,
  Button,
  notification,
  Form as Antform,
  Col,
  Row,
} from 'antd';

import {
  selectNotificationTemplatesById,
  selectSendgridTemplates,
} from '../../store/selectors';

import {
  fetchNotificationTemplateById,
  fetchSendgridTemplates,
  updateNotificationTemplate,
  createNotificationTemplate,
  fetchNotificationTemplatesByType,
} from '../../store/actions/notification_templates.actions';

interface TemplateEditorInputField {
  itemName: string;
  itemLabel: string;
}

const NotificationTemplateEditorInputField = ({ itemLabel, itemName }: TemplateEditorInputField) => {
  const Item = Antform.Item
  return (
    <Row>
      <Col xs={4}>
        <Item>{itemLabel}</Item>
      </Col>
      <Col xs={20}>
        <Field name={itemName} component="textarea" rows="1" style={{ width: "100%", borderColor: "#D9D9D9", outline: "none" }} />
      </Col>
    </Row>
  )
};

const NotificationTemplateEditor = () => {
  const [isCreateOrUpdateEditor, setIsCreateOrUpdateEditor] = useState('Create');
  const history = useHistory();
  const dispatch = useDispatch();
  const { templateId, deliveryMethod, notificationTypeId } = useParams<{ templateId: string, deliveryMethod: string, notificationTypeId: string }>();

  //if admin is on update page, the current template version will be loaded to be used as initial form input
  const template = templateId ? useSelector(selectNotificationTemplatesById) : {} as NotificationTemplate;
  const sendgridTemplates = useSelector(selectSendgridTemplates);
  const { Item } = Antform;

  const apiAction = convertDispatchProps({
    fetchNotificationTemplateById,
    fetchSendgridTemplates,
    updateNotificationTemplate,
    createNotificationTemplate,
    fetchNotificationTemplatesByType,
  });

  useEffect(() => {
    handleLoadTemplate();
  }, [templateId]);

  const handleLoadTemplate = useCallback(async () => {
    try {
      await dispatch(apiAction.fetchSendgridTemplates());
      if (templateId) {
        setIsCreateOrUpdateEditor('Update');
        await dispatch(fetchNotificationTemplateById(Number(templateId)));
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Page could not be loaded.',
      });
      history.push(`/notificationtemplates`);
    }
  }, [dispatch, fetchNotificationTemplateById]);

  return (
    <PageHeaderWrapper
      key="pageheader"
      title="Manage Notification Templates"
      htmlTitle="Manage Notification Templates"
      onBack={() => {
        //populate previous page by dispatching notification type ID from template if on update page or from parameter if on create page 
        dispatch(fetchNotificationTemplatesByType(template.notification_type_id || Number(notificationTypeId)));
        history.push(`/notificationtemplates`);
      }}
    >
      <Card title={`${isCreateOrUpdateEditor} template`} >
        <Formik
          enableReinitialize
          initialValues={
            //load input field with template data if on update page otherwise initialize all fields to empty string
            {
              templateName: template.notification_template_name || '',
              subject: template.subject || '',
              body: template.body || '',
              sendgridTemplateName: template.sendgrid_template_name || '',
            }
          }
          onSubmit={async (inputValues) => {
            const updateTemplatePayload = {
              notification_template_name: inputValues.templateName,
              subject: inputValues.subject,
              body: inputValues.body,
              ...(inputValues.sendgridTemplateName ? { sendgrid_template_name: inputValues.sendgridTemplateName } : {})
            }

            try {
              if (templateId) {
                await dispatch(apiAction.updateNotificationTemplate(Number(templateId), updateTemplatePayload));
              } else {
                //create payload overlaps with update payload but also requires type ID and delivery method
                const createTemplatePayload = {
                  notification_type_id: Number(notificationTypeId),
                  delivery_method: deliveryMethod,
                  ...updateTemplatePayload,
                }

                await dispatch(apiAction.createNotificationTemplate(createTemplatePayload));
                await dispatch(fetchNotificationTemplatesByType(template.notification_type_id || Number(notificationTypeId)));
                history.push(`/notificationtemplates`);
              }

              notification.success({
                message: 'Success',
                description: `Your template was ${isCreateOrUpdateEditor + 'd'}.`,
              });
            } catch (err) {
              notification.error({
                message: 'Error',
                description: `Template could not be ${isCreateOrUpdateEditor + 'd'}.`
              });
            }
          }}
        >
          <Form>
            <NotificationTemplateEditorInputField itemLabel="Template Name:" itemName="templateName" />
            <NotificationTemplateEditorInputField itemLabel="Subject:" itemName="subject" />
            <NotificationTemplateEditorInputField itemLabel="Body:" itemName="body" />
            {(deliveryMethod === 'email' || template.delivery_method === 'email') &&
              <Row>
                <Col xs={4}>
                  <Item>Sendgrid Template Name:</Item>
                </Col>
                <Col xs={20}>
                  <Field name={"sendgridTemplateName"} component="select" style={{ width: "100%", height: "30px", borderColor: "#D9D9D9", outline: "none" }}>
                    <option value="">None</option>
                    {sendgridTemplates.map((template) => {
                      return (
                        <option key={template.id} value={template.name}>
                          {template.name}
                        </option>
                      )
                    })}
                  </Field>
                </Col>
              </Row>
            }
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        </Formik>
      </Card>
    </PageHeaderWrapper >
  )
};

export default NotificationTemplateEditor;
