import { createSelector } from 'reselect';
import { RootState } from '..';

export const getCreditReports = (state: RootState) => state.creditReports.items;
export const getCreditReportsByUserId = (state: RootState) =>
  state.creditReports.byUserId;

export const getCreditReportsLoading = (state: RootState) =>
  state.creditReports.isLoading;
export const getCreditReportsSuccessMessage = (state: RootState) =>
  state.creditReports.successMessage;
export const getCreditReportsErrorMessage = (state: RootState) =>
  state.creditReports.errorMessage;

export const selectCreditReports = createSelector(
  getCreditReports,
  CreditReports => {
    return CreditReports;
  },
);
export const selectCreditReportsLoading = createSelector(
  getCreditReportsLoading,
  loading => loading,
);
export const selectCreditReportsErrorMessage = createSelector(
  getCreditReportsErrorMessage,
  message => message,
);
export const selectCreditReportsSuccessMessage = createSelector(
  getCreditReportsSuccessMessage,
  message => message,
);
export const selectCreditReportsByUserId = createSelector(
  getCreditReportsByUserId,
  CreditReports => {
    return CreditReports;
  },
);

export default {
  selectCreditReports,
  selectCreditReportsLoading,
  selectCreditReportsErrorMessage,
  selectCreditReportsSuccessMessage,

  selectCreditReportsByUserId,
};
