import React, { PureComponent } from 'react';
import { Modal, Select } from 'antd';
import { ContextVariable } from '../../../store/reducers/config.reducer';
import Paragraph from 'antd/lib/typography/Paragraph';
// import VariableEditor from '../templating/variable-editor';
// import TagEditor from '../templating/tag-editor';

interface Props {
  uniqueKey?: string;
  contextVariables: ContextVariable[];
}
interface State {
  defaultTemplate: string;
  modalVisible: boolean;
}
class NunjucksModal extends PureComponent<Props, State> {
  _currentTemplate: any;
  _onDone: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      defaultTemplate: '',
      modalVisible: false,
    };

    this._onDone = null;
    this._currentTemplate = null;
  }

  _handleTemplateChange = (template: string) => {
    this._currentTemplate = template;
  };

  _handleSubmit = (e: any) => {
    e.preventDefault();
    this._handleConfirmUpdate();
  };

  _handleConfirmUpdate = () => {
    if (this._onDone) {
      this._onDone(this._currentTemplate);
      this.setState({ defaultTemplate: '' });
    }
    this.setState({ modalVisible: false });
  };

  _handleCancelUpdate = () => {
    if (this._onDone) {
      this.setState({ defaultTemplate: '', modalVisible: false });
    }
  };

  show = ({ template, onDone }: { template: string; onDone: any }) => {
    // this gets passed in by nunjucks-tags an allows hooking up to the editor
    this._onDone = onDone;
    this._currentTemplate = template;
    this.setState({ modalVisible: true, defaultTemplate: template });
  };
  hide = () => {
    this.setState({ modalVisible: false });
  };
  render() {
    const { defaultTemplate, modalVisible } = this.state;
    const { contextVariables } = this.props;

    let editor = null;
    let title = '';
    if (defaultTemplate.indexOf('{{') === 0) {
      title = 'Variable';
      //   editor = (
      //     <VariableEditor
      //       onChange={this._handleTemplateChange}
      //       defaultValue={defaultTemplate}
      //     />
      //   );
      editor = (
        <div>
          <Paragraph type="secondary">
            Replace this variable with another
          </Paragraph>
          <Select
            showSearch
            onChange={(value: string) => this._handleTemplateChange(value)}
            defaultValue={this._currentTemplate}
          >
            {contextVariables.map((entry, index) => (
              <Select.Option value={entry.text} key={index}>
                {entry.displayText}
              </Select.Option>
            ))}
          </Select>
        </div>
      );
    } else if (defaultTemplate.indexOf('{%') === 0) {
      title = 'Tag';
      //   editor = (
      //     <TagEditor
      //       onChange={this._handleTemplateChange}
      //       defaultValue={defaultTemplate}
      //     />
      //   );
      editor = <div>tageditor</div>;
    }

    return (
      <Modal
        onCancel={this._handleCancelUpdate}
        onOk={this._handleConfirmUpdate}
        key={`vasdfasfwewe`}
        title={`Edit ${title}`}
        visible={modalVisible}
      >
        <form onSubmit={this._handleSubmit}>{editor}</form>
      </Modal>
    );
  }
}

export default NunjucksModal;
