export interface JoiErrors {
  message: string;
  path: string[];
}

const convertJoiToFieldErrors = (errors: JoiErrors[]) =>
  errors.reduce((errors: { [key: string]: string }, curr) => {
    errors[curr.path[0]] = curr.message;
    return errors;
  }, {});

export default convertJoiToFieldErrors;
