import React, { useState } from 'react';
import { connect } from 'react-redux';
import { LogoutOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons';
import {
  Menu,
  Avatar,
  Button,
  Badge,
  Spin,
  List,
  Skeleton,
  Divider,
} from 'antd';
import HeaderDropdown from './HeaderDropdown';

import { RootState } from '../../store';

import { signOutUser, checkSession } from '../../store/actions/auth.actions';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import scorbit from '../../assets/scorbit.png';
import {
  selectUsersLoading,
  selectUsers,
  selectIsSuper,
  selectCurrentAdmin,
} from '../../store/selectors';
import { convertDispatchProps } from '../../store/middleware/api';
import { createUser, fetchUsers } from '../../store/actions/user.actions';
import { logoutAsAdmin } from '../../store/actions/admin.actions';
import { User } from '../../store/reducers/user.reducer';
import AddSubscriberModalButton from '../../modules/shared/AddSubscriberModalButton';
import {
  selectAllOrganizations,
  selectCurrentOrganization,
} from '../../store/reducers/organizationSlice';

const mapStateToProps = (state: RootState) => ({
  organizations: selectAllOrganizations(state),
  usersLoading: selectUsersLoading(state),
  users: selectUsers(state),
  currentOrganization: selectCurrentOrganization(state),
  isSuper: selectIsSuper(state),
  currentAdmin: selectCurrentAdmin(state),
});

const dispatchProps = {
  signOutUser,
  push,
  createUser,
  fetchUsers,
  checkSession,
  logoutAsAdmin,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof dispatchProps;

interface OwnProps {
  isLoggedInAsAdmin: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

const RightMenu: React.FC<Props> = props => {
  const { isSuper = false, currentAdmin, isLoggedInAsAdmin = false, currentOrganization } = props;

  const [notificationsVisible, setNotificationsVisible] = useState(false);

  const [notifications, setNotifications] = useState([] as User[]);

  const redirect = (path: string) => props.push(path);
  const apiActions = convertDispatchProps(props);

  // // TODO: this is just for demo, remove it when ready
  // useEffect(() => {
  //   if (!users.length) {
  //     apiActions.fetchUsers('scoreshuttle');
  //   }
  //   setNotifications(users.slice(0, 5));
  // }, [users]);

  const handleVisibleChange = (flag: boolean) => {
    setNotificationsVisible(flag);
  };

  const handleDismissNotification = (id: string | number) => {
    setNotifications([...notifications.filter(item => item.id !== id)]);
  };

  const handleAcceptNotification = (user: User) => {
    setNotifications([...notifications.filter(item => item.id !== user.id)]);
    setNotificationsVisible(false);
    return redirect(`/scoreshuttle/subscribers/${user.id}/details`);
  };

  return (
    <div className="header-nav">
      {currentOrganization.id > 0 && <AddSubscriberModalButton />}
      <HeaderDropdown
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={notificationsVisible}
        overlay={
          <div
            style={{
              width: 336,
              position: 'relative',
              background: '#fff',
              border: '1px solid #eee',
              borderRadius: 5,
              padding: 10,
            }}
          >
            <Spin spinning={false}>
              <List
                itemLayout="horizontal"
                dataSource={notifications}
                renderItem={(item: User) => (
                  <List.Item
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <Skeleton avatar title={false} loading={false} active>
                      <List.Item.Meta
                        title={'Goal score reached!'}
                        description={`${item.first_name} reached their goal`}
                      />
                      <div style={{ fontSize: 11 }}>
                        <Button
                          type="link"
                          onClick={() => handleAcceptNotification(item)}
                        >
                          Accept
                        </Button>
                        <Divider type="vertical" />
                        <Button
                          type="link"
                          onClick={() => handleDismissNotification(item.id)}
                        >
                          Dismiss
                        </Button>
                      </div>
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Spin>
          </div>
        }
      >
        <span
          className={`notification icon`}
          style={{
            display: 'inline-block',
            cursor: 'pointer',
            transition: 'all 0.3s',
            padding: '0 10px',
          }}
        >
          <Badge count={notifications.length} style={{ boxShadow: 'none' }}>
            {/* TODO <BellOutlined style={{ padding: 4, verticalAlign: 'middle' }} /> */}
          </Badge>
        </span>
      </HeaderDropdown>
      <HeaderDropdown
        overlay={
          <Menu className="user-menu-dropdown" selectedKeys={[]}>
            <Menu.Item
              key="userCenter"
              onClick={() => redirect('/settings/base')}
            >
              <UserOutlined />
              My Account
            </Menu.Item>
            {isSuper && !isLoggedInAsAdmin && (
              <Menu.Item
                key="switchOrganization"
                onClick={() => redirect('/organizations')}
              >
                <SwapOutlined />
                Switch / Manage Organizations
              </Menu.Item>
            )}
            <Menu.Divider />
            {isLoggedInAsAdmin ? (
              <Menu.Item
                key="logoutAsAdmin"
                onClick={() => {
                  props.logoutAsAdmin();
                  apiActions.checkSession();
                }}
              >
                <LogoutOutlined />
                Logout of {get(currentAdmin, 'first_name')}
                {"'"}s account
              </Menu.Item>
            ) : (
              <Menu.Item key="logout" onClick={() => props.signOutUser()}>
                <LogoutOutlined />
                Logout
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <span className={`action account`}>
          <Avatar size="large" className="avatar" alt="avatar" src={scorbit} />
        </span>
      </HeaderDropdown>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(RightMenu);
