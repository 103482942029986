/* eslint-disable default-case */
import produce from 'immer';
import {
  FETCH_SENDGRID_CAMPAIGNS_START,
  FETCH_SENDGRID_CAMPAIGNS_SUCCESS,
  FETCH_SENDGRID_CAMPAIGNS_ERROR,
  UPDATE_SENDGRID_CAMPAIGN_SUCCESS,
  DELETE_SENDGRID_CAMPAIGN_SUCCESS,
  DELETE_SENDGRID_CAMPAIGN_START,
  DELETE_SENDGRID_CAMPAIGN_ERROR,
  IMPORT_SENDGRID_CAMPAIGNS_START,
  IMPORT_SENDGRID_CAMPAIGNS_ERROR,
  IMPORT_SENDGRID_CAMPAIGNS_SUCCESS,
} from '../constants/sendgridcampaign.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface SendgridCampaignAttributes {
  id: number;
  title: string;
  ip_pool: string;
  categories: string[];
  plain_content: string;
  html_content: string;
  subject: string;
  sender_id: number;
  list_ids: number[];
  segment_ids: number[];
  suppression_group_id: number;
  custom_unsubscribe_url: string;
  status: string;
  editor: string;
}

export interface SendgridCampaign {
  id: number;
  sendgrid_id: string;
  name: string;
  details: SendgridCampaignAttributes;
  send_at_hour: number;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export type SendgridCampaignUpdateValues = Partial<
  Pick<SendgridCampaign, 'name' | 'details' | 'send_at_hour' | 'enabled'>
>;

export interface SendgridCampaignsState
  extends CallAPIAsyncState<SendgridCampaign> {
  total: number;
}

const initialState: SendgridCampaignsState = {
  ...INITIAL_ASYNC_STATE,
  total: 0,
};

const sendgridCampaignsReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case FETCH_SENDGRID_CAMPAIGNS_SUCCESS:
        draft.items = action.payload.sendgrid_campaigns;
        draft.total = action.payload.meta.count;
        draft.isFetched = true;
        return;
      case IMPORT_SENDGRID_CAMPAIGNS_SUCCESS:
        action.payload.new.forEach((campaign: SendgridCampaign) => {
          draft.items.push(campaign);
        });
        action.payload.updated.forEach((campaign: SendgridCampaign) => {
          draft.items[
            draft.items.findIndex(item => item.id === campaign.id)
          ] = campaign;
        });

        return;
      case UPDATE_SENDGRID_CAMPAIGN_SUCCESS:
        draft.items[
          draft.items.findIndex(
            item => item.id === action.payload.sendgrid_campaign.id,
          )
        ] = action.payload.sendgrid_campaign;
        draft.item = action.payload.sendgrid_campaign;
        return;
      case DELETE_SENDGRID_CAMPAIGN_SUCCESS:
        draft.items.splice(
          draft.items.findIndex(element => element.id === action.id),
          1,
        );
        draft.total -= 1;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<SendgridCampaignsState>({
  isLoadingActions: [
    FETCH_SENDGRID_CAMPAIGNS_START,
    DELETE_SENDGRID_CAMPAIGN_START,
    IMPORT_SENDGRID_CAMPAIGNS_START,
  ],
  successActions: [
    FETCH_SENDGRID_CAMPAIGNS_SUCCESS,
    DELETE_SENDGRID_CAMPAIGN_SUCCESS,
    IMPORT_SENDGRID_CAMPAIGNS_SUCCESS,
  ],
  errorActions: [
    FETCH_SENDGRID_CAMPAIGNS_ERROR,
    DELETE_SENDGRID_CAMPAIGN_ERROR,
    IMPORT_SENDGRID_CAMPAIGNS_ERROR,
  ],
})(sendgridCampaignsReducer);
