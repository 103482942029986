import subscriptionConstants from '../constants/subscriptions.constants';
import { SearchQuery } from '../middleware/api';

interface PaymentMethod {
  number: string;
  exp_year: string;
  exp_month: string;
  cvv: string;
  zip: string;
}

interface UpdateSubscription {
  payment_method_id?: string;
  promo_code?: string | null;
  next_billing_at?: Date;
}

interface FetchPaymentPreviewPayload {
  product_handle: string;
  coupon_code?: string | null;
}
export const fetchPaymentPreview = (
  organization: string,
  payload: FetchPaymentPreviewPayload,
) => ({
  types: [
    subscriptionConstants.FETCH_PAYMENT_PREVIEW_START,
    subscriptionConstants.FETCH_PAYMENT_PREVIEW_SUCCESS,
    subscriptionConstants.FETCH_PAYMENT_PREVIEW_ERROR,
  ],
  method: 'post',
  path: `admin/organizations/${organization}/subscription_preview`,
  payload,
});

export const fetchSubscription = (organization: string, userId: string) => ({
  types: [
    subscriptionConstants.FETCH_SUBSCRIPTION_START,
    subscriptionConstants.FETCH_SUBSCRIPTION_SUCCESS,
    subscriptionConstants.FETCH_SUBSCRIPTION_ERROR,
  ],
  method: 'get',
  path: `admin/organizations/${organization}/users/${userId}/subscription`,
});

export const updateSubscription = (
  organization: string,
  userId: string,
  payload: UpdateSubscription,
  successMessage?: string,
) => ({
  types: [
    subscriptionConstants.UPDATE_SUBSCRIPTION_START,
    subscriptionConstants.UPDATE_SUBSCRIPTION_SUCCESS,
    subscriptionConstants.UPDATE_SUBSCRIPTION_ERROR,
  ],
  method: 'put',
  path: `admin/organizations/${organization}/users/${userId}/subscription`,
  payload,
  successMessage,
});

export const cancelSubscription = (
  organization: string,
  userId: string,
  successMessage?: string,
) => ({
  types: [
    subscriptionConstants.CANCEL_SUBSCRIPTION_START,
    subscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS,
    subscriptionConstants.CANCEL_SUBSCRIPTION_ERROR,
  ],
  method: 'delete',
  path: `admin/organizations/${organization}/users/${userId}/subscription`,
  successMessage,
});

export const holdSubscription = (
  organization: string,
  userId: string,
  values: {
    automatically_resume_at: Date;
  },
  successMessage?: string,
) => ({
  types: [
    subscriptionConstants.HOLD_SUBSCRIPTION_START,
    subscriptionConstants.HOLD_SUBSCRIPTION_SUCCESS,
    subscriptionConstants.HOLD_SUBSCRIPTION_ERROR,
  ],
  method: 'post',
  path: `admin/organizations/${organization}/users/${userId}/subscription/hold`,
  payload: values,
  successMessage,
});

export const removeHold = (
  organization: string,
  userId: string,
  successMessage?: string,
) => ({
  types: [
    subscriptionConstants.REMOVE_HOLD_START,
    subscriptionConstants.REMOVE_HOLD_SUCCESS,
    subscriptionConstants.REMOVE_HOLD_ERROR,
  ],
  method: 'delete',
  path: `admin/organizations/${organization}/users/${userId}/subscription/hold`,
  successMessage,
});

export const reactivateSubscription = (
  organization: string,
  userId: string,
  successMessage?: string,
) => ({
  types: [
    subscriptionConstants.REACTIVATE_SUBSCRIPTION_START,
    subscriptionConstants.REACTIVATE_SUBSCRIPTION_SUCCESS,
    subscriptionConstants.REACTIVATE_SUBSCRIPTION_ERROR,
  ],
  method: 'post',
  path: `admin/organizations/${organization}/users/${userId}/subscription/reactivate`,
  successMessage,
});

export const updatePaymentMethod = (
  organization: string,
  userId: string,
  paymentMethod: PaymentMethod,
  current: boolean,
  successMessage?: string,
) => {
  return {
    types: [
      subscriptionConstants.UPDATE_PAYMENT_METHOD_START,
      subscriptionConstants.UPDATE_PAYMENT_METHOD_SUCCESS,
      subscriptionConstants.UPDATE_PAYMENT_METHOD_ERROR,
    ],
    method: 'post',
    path: `admin/organizations/${organization}/users/${userId}/subscription/payment_methods`,
    payload: {
      ...paymentMethod,
      current,
    },
    successMessage,
  };
};

export const fetchTransactions = (
  organization: string,
  userId: string,
  search?: SearchQuery,
) => {
  return {
    types: [
      subscriptionConstants.FETCH_SUBSCRIPTION_PAYMENTS_START,
      subscriptionConstants.FETCH_SUBSCRIPTION_PAYMENTS_SUCCESS,
      subscriptionConstants.FETCH_SUBSCRIPTION_PAYMENTS_ERROR,
    ],
    method: 'get',
    path: `admin/organizations/${organization}/users/${userId}/subscription/transactions`,
    search,
  };
};

export default {
  fetchSubscription,
  fetchPaymentPreview,
  updatePaymentMethod,
};
