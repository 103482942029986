/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet';

import ScorbitSpace from '../../assets/Scorbit_Space.png';

type MatchParams = { organizationId: string };
type Props = RouteComponentProps<MatchParams>;

const pageStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  overflow: auto;
  background: #f0f2f5;
  font-size: 16px;
  @media (min-width: 768px) {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
  .org {
    text-transform: uppercase;
  }
  h1 {
    font-size: 40px;
  }
`;

const OrganizationInvitation: React.FC<Props> = ({
  match: {
    params: { organizationId },
  },
  // may want to pass and parse organization.name to render, instead of using subdomain
  location: { search },
}) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Welcome to ScoreShuttle!</title>
      </Helmet>
      <div css={pageStyle}>
        <img src={ScorbitSpace} alt="" height={200} />
        <h1>Congratulations!</h1>
        <p>
          You{`'`}ve been added to the organization{' '}
          <strong className="org">{organizationId}</strong>.
        </p>
        <p>
          <a href={`/organizations`}>Go to your dashboard now.</a>
        </p>
      </div>
    </React.Fragment>
  );
};

export default OrganizationInvitation;
