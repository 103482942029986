import produce from 'immer';

import {
  FETCH_SYSTEM_EVENT_TYPES_START,
  FETCH_SYSTEM_EVENT_TYPES_SUCCESS,
  FETCH_SYSTEM_EVENT_TYPES_ERROR,
} from '../constants/systemeventtypes.constants';
import { withLoading } from '../utils';
import { CallAPIAsyncState } from '../middleware/api';

export const operators = {
  eq: 'Equal to',
  neq: 'Not equal to',
  gt: 'Greater than',
  gte: 'Greater than or Equal to',
  lt: 'Less than',
  lte: 'Less than or Equal to',
  in: 'In',
  nin: 'Not In',
  null: 'Null',
  nnull: 'Not Null',
} as const;
export type Operator = keyof typeof operators;

export const systemEventActions = [
  'check_goals',
  'console_log',
  'email',
  'webhook',
  'sms',
] as const;
export type SystemEventAction = typeof systemEventActions[number];

// rough interface based on what seems to currently be returned from the api
export interface Attribute {
  type: 'string' | 'number' | 'array' | 'enum' | 'object';
  description?: string;
  format?: string;
  pattern?: string;
  patterns?: string[];
  items?: Attribute;
  enum?: string[];
  properties?: { [key: string]: Attribute };
  additionalProperties?: boolean;
}

export interface SystemEventTypes {
  [name: string]: {
    description: string;
    target?: string;
    attributes: { [key: string]: Attribute };
  };
}

interface SystemEventTargetModels {
  [name: string]: {
    type: string;
    additionalProperties: boolean;
    required: string[];
    properties: {
      [name: string]: {
        type: string;
      };
    };
  };
}

interface SystemEventTypeState
  extends Omit<CallAPIAsyncState, 'items' | 'item'> {
  readonly types: SystemEventTypes;
  readonly models: SystemEventTargetModels;
}

const initialState: SystemEventTypeState = {
  types: {},
  models: {},
  isFetched: false,
  isLoading: false,
  errors: {},
  errorMessage: '',
  successMessage: '',
};

const systemEventTypesReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case FETCH_SYSTEM_EVENT_TYPES_SUCCESS:
        draft.types = action.payload.types;
        draft.models = action.payload.models;
        draft.isFetched = true;
    }
  },
  { ...initialState },
);

export default withLoading<SystemEventTypeState>({
  isLoadingActions: [FETCH_SYSTEM_EVENT_TYPES_START],
  successActions: [FETCH_SYSTEM_EVENT_TYPES_SUCCESS],
  errorActions: [FETCH_SYSTEM_EVENT_TYPES_ERROR],
})(systemEventTypesReducer);
