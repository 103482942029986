/* eslint-disable @typescript-eslint/no-empty-interface */
import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_PROMO_CODES_START,
  FETCH_PROMO_CODES_SUCCESS,
  FETCH_PROMO_CODES_ERROR,
} from '../constants/promo_code.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface PromoCode {
  id: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  code: string;
  description: string;
  amount_in_cents: number;
  product_family_id: number;
  start_date: string;
  end_date: string | null;
  percentage: string;
  recurring: boolean; // If true, the discount will be applied at signup and every renewal (for the number of renewals given by duration_period_count). Otherwise, discount will be applied only at signup.
  duration_period_count: number | null;
  duration_interval: number | null;
  duration_interval_unit: number | null;
  allow_negative_balance: boolean; // If true, credits will carry forward to next billing. Otherwise discount may not exceed total of the charges.
  archived_at: null | string; // Timestamp indicating when this coupon was archived
  conversion_limit: null | number;
  stackable: boolean; // If true coupon can be combined with other stackable coupons to produce a larger discount. If false coupon cannot be combined with other coupons.
  compounding_strategy: 'compound' | 'full-price';
  coupon_restrictions: any[];
}
export interface PromoCodesResponse {
  coupons: { coupon: PromoCode }[];
}
export interface PromoCodesState extends CallAPIAsyncState<PromoCode> {}

const initialState: PromoCodesState = {
  ...INITIAL_ASYNC_STATE,
};

const promoCodeReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_PROMO_CODES_SUCCESS:
        draft.items = (action.payload as PromoCodesResponse).coupons.map(
          ({ coupon }) => coupon,
        );
        draft.isFetched = true;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<PromoCodesState>({
  isLoadingActions: [FETCH_PROMO_CODES_START],
  successActions: [FETCH_PROMO_CODES_SUCCESS],
  errorActions: [FETCH_PROMO_CODES_ERROR],
})(promoCodeReducer);
