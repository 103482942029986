import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { apiRequest } from '../../store/utils';
import { Product } from '../../store/reducers/subscriptions.reducer';
import { RootState } from '../../store';

import { selectCurrentOrganization } from '../../store/reducers/organizationSlice';
import { selectIsSuper, selectCurrentAdmin } from '../../store/selectors';

export const fetchProducts = createAsyncThunk(
  'products/fetch',
  async (subdomain: string) => {
    const {
      data: { products },
    } = await apiRequest.get<{
      products: Product[];
    }>(`admin/organizations/${subdomain}/available_products`);

    return products;
  },
);

const adapter = createEntityAdapter<Product>({
  selectId: product => product.handle,
});

const slice = createSlice({
  name: 'products',
  initialState: adapter.getInitialState({ loading: false }),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProducts.pending, state => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, { payload }) => {
        adapter.setAll(state, payload);
        state.loading = false;
      })
      .addCase(fetchProducts.rejected, state => {
        state.loading = false;
      });
  },
});

export default slice.reducer;

export const {
  selectAll: selectAllProducts,
  selectEntities: selectProductEntities,
} = adapter.getSelectors((state: RootState) => state.products);

export const selectDefaultProductsConfigForCurrentOrg = createSelector(
  selectCurrentOrganization,
  organization => {
    return {
      monthly: {
        product_handle:
          organization.default_monthly_product_handle || 'challenger_1_129',
        term: 1,
      },
      prepay_6_months: {
        product_handle:
          organization.default_prepay_6_months_product_handle ||
          'challenger_6_636',
        term: 6,
      },
      // prepay_12_months: {
      //   product_handle:
      //     organization.default_prepay_12_months_product_handle ||
      //     'challenger_12_1272',
      //   term: 12,
      // },
    };
  },
);

export const selectProductHandleSelectOptions = (term: 1 | 6 | 12) =>
  createSelector(selectAllProducts, products => {
    const options =
      products
        ?.filter(product => product.handle.includes(`_${term}_`)) // schema is tier_term_price
        .map(product => ({
          label: `${product.name} ${
            product.name.includes('$')
              ? ''
              : `- ${Number(product.price_in_cents / 100).toLocaleString(
                  'en-us',
                  {
                    style: 'currency',
                    currency: 'USD',
                  },
                )}`
          }`,
          value: product.handle,
        })) || [];

    return [
      // { label: 'Free', value: null }, // per jb, remove free for now
      ...options,
    ];
  });

export const selectProductsAvailableToOrgByHandle = createSelector(
  [selectCurrentOrganization, selectDefaultProductsConfigForCurrentOrg],
  (organization, defaults) => {
    // need to include default values, then merge in available
    if (!organization.id) return [];

    return (organization.allowed_product_handles || []).filter(
      handle =>
        ![
          defaults.monthly.product_handle,
          defaults.prepay_6_months.product_handle,
        ].includes(handle),
    );
  },
);

export const selectProductsAvailableToOrgByAsSelectOptions = createSelector(
  [
    selectProductEntities,
    selectCurrentOrganization,
    selectDefaultProductsConfigForCurrentOrg,
    selectIsSuper,
    selectCurrentAdmin,
  ],
  (products, organization, defaults, isSuper, currentAdmin) => {
    if (!organization.id) return [];
    if (!products) return;

    const organizationHandles = [
      defaults.monthly.product_handle,
      defaults.prepay_6_months.product_handle,
      ...(organization?.allowed_product_handles || []),
    ].filter(Boolean);
    const uniqueHandles = new Set<string>();
    organizationHandles.forEach(uniqueHandles.add, uniqueHandles);

    const options = Array.from(uniqueHandles)
      .map(handle => {
        const product = products[handle];
        if (product) {
          return {
            label: `${product.name} ${
              product.name.includes('$')
                ? ''
                : `- ${Number(product.price_in_cents / 100).toLocaleString(
                    'en-us',
                    {
                      style: 'currency',
                      currency: 'USD',
                    },
                  )}`
            }`,
            value: product.handle,
          };
        }
      })
      .filter(Boolean)
      .sort((a, b) => a!.label.localeCompare(b!.label));
    return isSuper || currentAdmin.email === 'manuel0327@yahoo.com'
      ? [{ label: 'Launchpad', value: 'launchpad' }, ...options]
      : options;
  },
);

export const selectAllProductsAsSelectOptions = (
  includeDefaultProducts = false,
) =>
  createSelector(
    [selectAllProducts, selectCurrentOrganization],
    (products, organization) => {
      if (!organization.id || !products.length) return [];
      const options =
        products
          .filter(product =>
            includeDefaultProducts
              ? true
              : ![
                  organization.default_prepay_6_months_product_handle,
                  organization.default_monthly_product_handle,
                ].includes(product.handle),
          )
          .map(product => ({
            label: `${product.name} ${
              product.name.includes('$')
                ? ''
                : `- ${Number(product.price_in_cents / 100).toLocaleString(
                    'en-us',
                    {
                      style: 'currency',
                      currency: 'USD',
                    },
                  )}`
            }`,
            value: product.handle,
          })) || [];

      return [
        // { label: 'Free', value: null }, // per jb, remove free for now
        ...options,
      ];
    },
  );
