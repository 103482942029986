import produce from 'immer';
import { LOCATION_CHANGE } from 'connected-react-router';
import { compareDesc } from 'date-fns';
import {
  FETCH_CREDIT_REPORTS_START,
  FETCH_CREDIT_REPORTS_SUCCESS,
  FETCH_CREDIT_REPORTS_ERROR,
  FETCH_CREDIT_REPORT_MONITORING_URL_START,
  FETCH_CREDIT_REPORT_MONITORING_URL_SUCCESS,
  FETCH_CREDIT_REPORT_MONITORING_URL_ERROR,
  FETCH_IDCS_REPORT_URL_START,
  FETCH_IDCS_REPORT_URL_SUCCESS,
  FETCH_IDCS_REPORT_URL_ERROR,
  ORDER_EXPERIAN_REPORT_START,
  ORDER_EXPERIAN_REPORT_SUCCESS,
  ORDER_EXPERIAN_REPORT_ERROR,
} from '../constants/creditreports.constants';
import { CallAPIAsyncState } from '../middleware/api';
import { withLoading, INITIAL_ASYNC_STATE } from '../utils';

export interface CreditReport {
  id: number;
  user_id: number;
  report_id: string;
  bureaus: string;
  created_at: Date;
  updated_at: Date;
}

export type CreditReportsState = CallAPIAsyncState & {
  byUserId: {
    [key: string]: CreditReport[];
  };
};

const initialState: CreditReportsState = {
  ...INITIAL_ASYNC_STATE,
  byUserId: {},
};

const creditReportsReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        draft.errorMessage = '';
        draft.errors = {};
        draft.successMessage = '';
        return;
      case FETCH_CREDIT_REPORTS_SUCCESS:
        const {
          credit_reports: creditReports,
        }: { credit_reports: CreditReport[] } = action.payload;
        draft.byUserId[action.userId] = creditReports.sort((a, b) =>
          compareDesc(a.created_at, b.created_at),
        );
        draft.isFetched = true;
        return;
    }
  },
  { ...initialState },
);

export default withLoading<CreditReportsState>({
  isLoadingActions: [
    FETCH_CREDIT_REPORTS_START,
    FETCH_CREDIT_REPORT_MONITORING_URL_START,
    FETCH_IDCS_REPORT_URL_START,
    ORDER_EXPERIAN_REPORT_START,
  ],
  successActions: [
    FETCH_CREDIT_REPORTS_SUCCESS,
    FETCH_CREDIT_REPORT_MONITORING_URL_SUCCESS,
    FETCH_IDCS_REPORT_URL_SUCCESS,
    ORDER_EXPERIAN_REPORT_SUCCESS,
  ],
  errorActions: [
    FETCH_CREDIT_REPORTS_ERROR,
    FETCH_IDCS_REPORT_URL_ERROR,
    ORDER_EXPERIAN_REPORT_ERROR,
    FETCH_CREDIT_REPORT_MONITORING_URL_ERROR,
  ],
})(creditReportsReducer);
